<template>
  <div class="userStatus" :class="{ active : userData.active === '1' }">
    {{ userData.active === '1' ? 'Активен' : 'Не активен' }}
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'UserStatus',
  setup() {
    const store = useStore()

    return {
      userData: computed(() => store.getters.userInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.userStatus {
    color: red;
      background: #fff;
      border-radius: 12px;
      text-align: center;
      width: calc(100% - 60px);
      margin: 0 auto 10px auto;
      padding: 5px;
      font-size: 14px;

      &.active {
        color: #34c759;
      }
}
</style>
