import { ref, onMounted, computed } from "vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { base_URL, REQUIRED_TEXT } from "../../constants";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { TheMask } from "vue-the-mask";
import phone from "/src/main.js";
import {mask} from 'vue-the-mask'
import SelectCity from './../../components/SelectCity/SelectCity'

export default {
    name: "FormReg",
    directives: {
        phone: {
            mounted(el) {
                el.oninput = function(e) {
                    if (!e.isTrusted) {
                        return;
                    }
                    var position = this.selectionEnd;
                    let pos = position;
                    let len = this.value.length;

                    let x = this.value
                        .replace(/\D/g, "")
                        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                    this.value = !x[2]
                        ? x[1]
                        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
                    el.dispatchEvent(new Event("input"));
                    if (pos < len) {
                        this.setSelectionRange(pos, pos);
                    }
                };
            },
        },
        directives: {mask}
    },
    components: {
        Field,
        Form,
        TheMask,
        SelectCity
    },
    watch: {
        phone: function(val) {
            if (val != null) {
                if (val.indexOf("+7") > -1) {
                    this.phone = val.replace(/^.{2}/, "");
                    return false;
                }
                if (val.substr(0, 1).indexOf("8") > -1) {
                    this.phone = val.replace(/^.{1}/, "");
                    return false;
                }
            }
        },
    },

    setup() {
        const cityArray = ref([]);
        let regionArray = ref([]);
        const step = ref(1);
        const stepData1 = ref({});
        const stepData2 = ref({});
        const stepData3 = ref({});
        const city = ref("");
        const region = ref("");
        const activeCityList = ref(false);
        const activeRegionList = ref(false);
        const error = ref("");
        const pattern = /^(\+7\(\d{3}\)\d{3}-\d{2}-\d{2})$/

        // Фильтруем массив от одинаковых значений
        const unique = (arr) => {
            let result = [];
            for (let str of arr) {
                if (!result.includes(str)) {
                    result.push(str);
                }
            }
            return result;
        };

        // Получаем данные городов из json
        const getCityLocalData = async () => {
            const result = await require("./../../assets/cities.json");
            cityArray.value = result;

            let regionTemp = [];
            result.forEach((i) => {
                regionTemp.push(i.region);
            });
            regionArray = unique(regionTemp);
        };

        // Активируем выпадашку с городами
        const activeCityListToggle = () => {
            activeCityList.value = true;
            activeRegionList.value = false;
        };

        // Активируем выпадашку с регионами
        const activeRegionListToggle = () => {
            activeRegionList.value = true;
            activeCityList.value = false;
        };

        // Валидация полей
        const schema = Yup.object().shape({
            email: Yup.string()
                .required(REQUIRED_TEXT)
                .email("Не корректный E-mail"),
            password: Yup.string()
                .min(6, "Минимум 6 символов")
                .required(REQUIRED_TEXT),
            name: Yup.string()
                .trim()
                .min(2, "Минимум 2 символов")
                .required(REQUIRED_TEXT),
            last_name: Yup.string()
                .trim()
                .min(2, "Минимум 2 символов")
                .required(REQUIRED_TEXT),
            phone: Yup.string().matches(pattern, {message: 'Некорректный номер телефона'}).required(REQUIRED_TEXT),
            city: Yup.string().required(REQUIRED_TEXT),
            region: Yup.string().required(REQUIRED_TEXT),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Проли не совпадают")
                .required(REQUIRED_TEXT),
        });

        const store = useStore();
        //редирект на главную
        const router = useRouter();
        const redirect = () => {
            router.push({
                name: "Home",
            });
        };
        // переключение шагов и отправка формы
        const onSubmit = (values) => {
            switch (step.value) {
                case 1:
                    stepData1.value = values;
                    axios
                        .post(
                            `${base_URL}/users/checkEmailReg/`,
                            stepData1.value
                        )
                        .then((response) => {
                            if (response["data"] == "N") {
                                step.value++;
                                error.value = "";
                            } else {
                                error.value = "Такой емейл уже существует";
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                        });
                    break;
                case 2:
                    stepData2.value = values;
                    step.value++;
                    break;
                case 3:
                    stepData3.value = values;
                    const resultData = {
                        ...stepData1.value,
                        ...stepData2.value,
                        ...stepData3.value,
                    };
                    if (!resultData.name.length
                      || !resultData.last_name.length
                      || !pattern.test(resultData.phone)
                      || !resultData.city.length
                      || !resultData.region.length
                      ) {
                        error.value = "Ошибка регистрации, повторите позже";
                        break;
                      }
                    const login = resultData.name + Date.now().toString();
                    resultData.login = login;

                    let queryStringId = JSON.stringify(resultData);
                    console.log('queryStringId', queryStringId);

                    axios
                        .post(`${base_URL}/users/createuser/`, queryStringId)
                        .then((response) => {
                            if (response["status"] == 200) {
                                //если регистрация прошла, авторизуем и пишем в локал сторадже
                                let data = {
                                    email: resultData.email,
                                    password: resultData.password,
                                };
                                const authCheck = () =>
                                    store.dispatch("AUTH_CHECK", data);
                                authCheck();
                                redirect();
                                error.value = "";
                            } else {
                                error.value =
                                    "Ошибка регистрации, повторите позже";
                            }
                        })
                        .catch((err) => {
                            console.warn(err);
                            error.value = "Ошибка регистрации, повторите позже";
                        });
                default:
            }
        };

        const back = (val) => {
            if (val < step.value) {
                step.value = val;
            }
        };

        // проверяем совпвдение введенных данных города
        const filterCity = computed(() => {
            var word = city.value.toLowerCase();
            return cityArray.value.filter(function(elem) {
                if (word === "") return true;
                else
                    return (
                        elem.city.toLowerCase().indexOf(word.toLowerCase()) > -1
                    );
            });
        });

        // проверяем совпвдение введенных данных региона
        const filterRegion = computed(() => {
            var word = region.value.toLowerCase();
            return regionArray.filter(function(elem) {
                if (word === "") return true;
                else return elem.toLowerCase().indexOf(word.toLowerCase()) > -1;
            });
        });

        // Закрываем выпадашку с городлом и регионом
        const hideListCities = (e) => {
            if (e.target.className == "form") {
                activeCityList.value = false;
                activeRegionList.value = false;
            }
        };

        // Сохраняем выбранный город
        // const selectCity = (val) => {
        //     activeCityList.value = false;
        //     city.value = val;
        // };
        const selectCity = (data) => {
            if (data.el === 'city') {
                city.value = data.val
            } else {
                region.value = data.val
            }
        };

        // Сохраняем выбранный регион
        const selectRegion = (val) => {
            activeRegionList.value = false;
            region.value = val;
        };

        onMounted(() => {
            getCityLocalData();
        });

        return {
            stepData1,
            stepData2,
            step,
            schema,
            onSubmit,
            city,
            filterCity,
            activeCityList,
            activeCityListToggle,
            hideListCities,
            selectCity,
            region,
            activeRegionList,
            activeRegionListToggle,
            filterRegion,
            unique,
            regionArray,
            selectRegion,
            back,
            error,
        };
    },
};
