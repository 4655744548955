<template>
  <div class="admin page">
        <Aside></Aside>

        <div class="container" v-if="allUsers.length">
            <Header></Header>
            <div class="content content-vertical">

              <div class="admin-filter">
                <div class="admin-filter__row">
                  <div class="admin-filter__col">
                    <div class="admin-filter__label">Поиск по телефону</div>
                    <input type="text" class="admin-filter__input" v-model="searchByPhone">
                  </div>
                  <div class="admin-filter__col">
                    <div class="admin-filter__label">Поиск по городу</div>
                    <input type="text" class="admin-filter__input" v-model="searchByCity">
                  </div>
                  <div class="admin-filter__col" v-if="userData.group === 'main'">
                    <div class="admin-filter__label">Поиск по имени</div>
                    <input type="text" class="admin-filter__input" v-model="searchByName">
                  </div>

                  <div class="admin-filter__col">
                    <div class="admin-filter__label">Статус</div>
                    <CustomSelect
                      :data="statusData"
                      :selectedData="status"
                      defaultData="Все"
                      @select="statusSelect"
                    />
                  </div>
                </div>

                <div class="admin-filter__row">
                  <div class="admin-filter__col">
                    <div class="admin-filter__label">Поиск по email</div>
                    <input type="text" class="admin-filter__input" v-model="searchByEmail">
                  </div>
                  <div class="admin-filter__col">
                    <div class="admin-filter__label">Поиск по региону</div>
                    <input type="text" class="admin-filter__input" v-model="searchByRegion">
                  </div>
                  <div class="admin-filter__col" v-if="userData.group === 'main'">
                    <div class="admin-filter__label">Поиск по фамилии</div>
                    <input type="text" class="admin-filter__input" v-model="searchByLastName">
                  </div>
                  <!-- <div class="admin-filter__col">
                    <div class="admin-filter__label">Инструктор</div>
                    <CustomSelect
                      :data="instructorData"
                      :selectedData="instructorStatus"
                      defaultData="Все"
                      @select="instructorStatusSelect"
                    />
                  </div> -->
                </div>

              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Регистрация</th>
                    <th>Город/Область</th>
                    <th>Контакты</th>
                    <th v-if="userData.group === 'main'">Имя/Фамилия</th>
                    <th>Инструктор</th>
                    <th>Статус</th>
                    <th>Прогресс</th>
                    <th>Открыть все уроки</th>
                    <!-- <th>Комментарий</th> -->
                    <th>удаление</th>
                  </tr>
                </thead>
                <tbody>

                <tr v-show="!filteredUsers.length">
                  <td colspan="8" class="empty">Пользователи не найдены!</td>
                </tr>

                <template v-for="user in filteredUsers" :key="user.id">
                  <template v-if="userData.email === 'mgn@mail.ru' || userData.city === user.PERSONAL_CITY">
                    <tr v-show="!status
                    || status.value === 'all'
                    || user.UF_ACTIVE === '1' && status.value === 'active'
                    || !user.UF_ACTIVE && status.value === 'inactive'
                    || user.UF_ACTIVE === '0' && status.value === 'inactive'"
                      :class="{ danger: user.UF_ACTIVE !== '1' && user.UF_INSTRUCTOR }"
                    >
                      <td><b>{{ user.DATE_REGISTER.split(' ')[0] }}</b> <br /> {{ user.DATE_REGISTER.split(' ')[1] }}</td>
                      <td><b>{{ user.PERSONAL_CITY }}</b> <br /> {{ user.PERSONAL_STATE }} <br /></td>
                      <td><b>{{ user.PERSONAL_PHONE }}</b> <br /> {{ user.EMAIL }} </td>
                      <td v-if="userData.group === 'main'"><b>{{ user.LAST_NAME }} </b> <br /> {{ user.NAME }}</td>
                      <td :class="['userStatus', { active: user.UF_INSTRUCTOR }]">{{ user.UF_INSTRUCTOR ? 'Выбран' : 'Не выбран' }}</td>
                      <td :class="['userStatus', { active : user.UF_ACTIVE === '1' }]">
                        <span v-if="user.UF_ACTIVE === '1'" class="active">Активен</span>
                        <div v-else @click="activeUser({ id: user.ID, active: user.UF_ACTIVE === '0' ? '1' : '0' })" class="table__btn blue">Активировать</div>
                      </td>
                      <td><b>Теория:</b> {{ user.UF_PROGRESS ? Math.ceil((100/45*parseInt(user.UF_PROGRESS))) : 0 }}% <br /> <b>Практика:</b> {{ user.UF_PROGRESS_DRIVER ? Math.ceil((100 / 13 * parseInt(user.UF_PROGRESS_DRIVER.length))) : 0 }}%</td>
                      <td>
                        <div v-if="user.UF_FREE_ACCESS && user.UF_FREE_ACCESS === '1'" :style="{ color: '#34c759' }">Открыто</div>
                        <div
                          v-else
                          class="table__btn blue"
                          :class="{ disabled: loadingButton === user.ID }"
                          :style="{ maxWidth: '96px' }"
                          @click="openLessons(user.ID, true)"
                        >
                          {{ loadingButton === user.ID ? 'загрузка' : 'Открыть' }}
                        </div>
                      </td>
                      <!-- <td>Номер недоступен</td> -->
                      <td><div class="table__btn" @click="toggleModal(user)">Удалить</div></td>
                    </tr>
                  </template>
                </template>
                </tbody>
              </table>
            </div>
        </div>
        <div class="page-preloader" v-else>Загрузка пользователей...</div>

        <div class="admin-modal" v-if="modal">
          <div class="admin-modal__overlay" @click="toggleModal"></div>
          <div class="admin-modal__content">
            <div class="admin-modal__title">
              Удаление пользователя
              <div class="admin-modal__close" @click="toggleModal"></div>
            </div>
            <div class="admin-modal__description">
              Вы действительно хотите удалить
              <b>{{ deleteUserData.value.LAST_NAME }} {{ deleteUserData.value.NAME }}</b>?
            </div>
            <div class="admin-modal__buttons">
              <div class="admin-modal__btn red" @click="deleteUser">Удалить</div>
              <div class="admin-modal__btn" @click="toggleModal">Отмена</div>
            </div>
          </div>
        </div>
  </div>
        <DownloadPage></DownloadPage>

</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import DownloadPage from "../components/DownloadPage/DownloadPage.vue";
import Aside from "../components/Aside/Aside.vue";
import Header from "../components/Header/Header.vue";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { Field } from "vee-validate";
import CustomSelect from './../components/CustomSelect.vue'
export default {
  name: 'Admin',
  components: {
    Aside,
    Header,
    Field,
    DownloadPage,
    CustomSelect
  },

  setup () {
    const store = useStore()
    const router = useRouter()
    const modal = ref(false)
    const statusData = reactive([
      { id: 1, value: 'all', label: 'Все' },
      { id: 2, value: 'active', label: 'Активные' },
      { id: 3, value: 'inactive', label: 'Не активные' }
    ])
    const instructorData = reactive([
      { id: 1, value: 'all', label: 'Все' },
      { id: 2, value: 'active', label: 'Выбранные ' },
      { id: 3, value: 'inactive', label: 'Не выбранные' }
    ])
    const status = ref(null)
    const instructorStatus = ref(null)

    const searchByPhone = ref('')
    const searchByCity = ref('')
    const searchByName = ref('')
    const searchByEmail = ref('')
    const searchByRegion = ref('')
    const searchByLastName = ref('')

    const loadingButton = ref('')

    const deleteUserData = reactive({})

    const activeUser = async (data) => {
      await store.dispatch('ACTIVE_USER', {
        id: data.id,
        active: data.active
      })
    }

    const toggleModal = (user) => {
      if (user) {
        deleteUserData.value = user
      }
      modal.value = !modal.value
    }

    const redirect = () => {
      router.push({
        name: "Home",
      });
      setTimeout(() => {
        location.reload();
      }, 500);
    };

    const statusSelect = (val) => {
      status.value = val
    }

    const instructorStatusSelect = (val) => {
      instructorStatus.value = val
    }


    const allUsers = computed(() => store.getters.GET_ALL_USERS)
    const userData = computed(() => store.getters.userInfo)

    const filteredUsers = computed(() => {
      const filteredDataPhone = allUsers.value.filter(e => {
        return e.PERSONAL_PHONE.toLowerCase().startsWith(searchByPhone.value.toLowerCase())
      })

      const filteredDataCity = filteredDataPhone.filter(e => {
        return e.PERSONAL_CITY.toLowerCase().startsWith(searchByCity.value.toLowerCase())
      })

      const filteredDataName = filteredDataCity.filter(e => {
        return e.NAME.toLowerCase().startsWith(searchByName.value.toLowerCase())
      })

      const filteredDataEmail = filteredDataName.filter(e => {
        return e.EMAIL.toLowerCase().startsWith(searchByEmail.value.toLowerCase())
      })

      const filteredDataRegion = filteredDataEmail.filter(e => {
        return e.PERSONAL_STATE.toLowerCase().startsWith(searchByRegion.value.toLowerCase())
      })

      const filteredDataLastName = filteredDataRegion.filter(e => {
        return e.LAST_NAME.toLowerCase().startsWith(searchByLastName.value.toLowerCase())
      })

      return filteredDataLastName
    })

    const fetchAllUsers = async () =>
      await store.dispatch('FETCH_ALL_USERS', { user_id: userData.value.id })

    const deleteUser = async () => {
      await store.dispatch('DELETE_USER', deleteUserData.value.ID)
      modal.value = !modal.value
    }

    const openLessons = async (userId, flag) => {
      if (loadingButton.value === userId) {
        return
      }
      loadingButton.value = userId
      const instructorId = await localStorage.getItem('userData')
      await store.dispatch('OPEN_ALL_LESSONS', {
        userId,
        groupId: instructorId,
        flag
      })
      store.dispatch('UPDATE_USERS_DATA', { userId, flag })
      setTimeout(() => {
        loadingButton.value = ""
      }, 500);
    }


    onMounted(async () => {
      if (userData.value.group === 'main' || userData.value.group === 'main_reg') {
        await fetchAllUsers()
      } else {
        redirect()
      }
    })

    return {
      modal,
      toggleModal,
      allUsers,
      statusData,
      activeUser,
      instructorData,
      status,
      instructorStatus,
      statusSelect,
      instructorStatusSelect,
      filteredUsers,
      searchByPhone,
      searchByCity,
      searchByName,
      searchByEmail,
      searchByRegion,
      searchByLastName,
      openLessons,
      loadingButton,
      deleteUserData,
      deleteUser,
      userData
    }
  }
}
</script>

<style lang="scss" scoped>
.page-preloader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table {
  width: 100%;
  border: 1px solid #000;
  border-spacing: 0;
  text-align: center;
  &__btn {
    background: #f44336;
    border-radius: 5px;
    height: 30px;
    width: 100%;
    border-color: #f44336;
    color: #FBFBFD;
    font-size: 14px;
    cursor: pointer;
    transition: all .3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.blue {
      background: #3C6BE4;
      border-color: #3C6BE4;
      &.disabled {
        opacity: .6;
      }
    }
    &:hover {
      opacity: .8;
    }
  }
  // tbody tr:hover {
  //   background: rgba(60, 107, 228, .2);
  //   cursor: pointer;
  // }
  b {
    font-weight: 700;
  }
  thead tr {
    background: #3C6BE4 !important;
    height: 43px;
  }
  tr {
    &:nth-child(odd) {
      background: #ebeff2;
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #000;
    }
  }
  td {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid #000;
    &:not(:last-child) {
      border-right: 1px solid #000;
    }
  }
  th {
    font-weight: 700;
    padding: 5px;
    border-bottom: 1px solid #000;
    font-size: 14px;
    color: #fff;
    &:not(:last-child) {
      border-right: 1px solid #000;
    }
  }
}
.content-vertical {
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}
.admin-filter {
  background: #3C6BE4;
  width: 100%;
  margin-bottom: 30px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  &__col {
    flex: auto;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #fff;
  }
  &__input {
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.14);
    padding: 0 10px;
    width: 100%;
    height: 30px;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
  }
}

.admin-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, .6);
  }
  &__content {
    position: relative;
    background: #fff;
    z-index: 2;
    border-radius: 8px;
    padding: 20px;
  }
  &__title {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  &__description {
    margin-bottom: 10px;
    b {
      font-weight: 700;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    & * {
      margin: 0 5px;
    }
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 100%;
      height: 2px;
      background: #f44336;
    }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 100%;
      height: 2px;
      background: #f44336;
    }
  }
  &__btn {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fff;
    background: #3C6BE4;
    width: 100%;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      opacity: .8;
    }
    &.red {
      background: #f44336;
    }
  }
}
.userStatus {
  color: #f44336;
  &.active {
    color: #34c759;
  }
}
.empty {
  height: 200px;
  font-size: 20px;
  color: #f44336;
  font-weight: 600;
}

tbody tr:hover,
tbody tr:nth-child(odd):hover {
  background: rgba(60, 107, 228, .2);
}

.danger {
  background: rgba(153, 51, 51, .2) !important;
}

.active {
  color: #34c759;
}
</style>
