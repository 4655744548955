import axios from "axios";
import { base_URL } from "./../../constants";

export default {
    state: {
        favorites: [],
    },
    getters: {
        FAVORITES: (state) => {
            return state.favorites;
        },
    },
    mutations: {
        SET_FAVORITES: (state, payload) => {
            state.favorites = payload;
        },
    },
    actions: {
        GET_FAVORITES: async ({ commit }, payload) => {
            const value = {
                id: payload,
            };
            const queryString = JSON.stringify(value);
            await axios
                .post(`${base_URL}/users/getfavoriteslist/`, queryString)
                .then((res) => {
                    commit("SET_FAVORITES", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        DEL_FAVORITES: async ({ commit, state }, payload) => {
            const value = {
                id: payload.user_id,
                questions: [payload.question_id],
            };
            const queryString = JSON.stringify(value);
            const filterState = state.favorites.filter(
                (i) => i.id !== payload.question_id
            );

            await axios
                .post(`${base_URL}/users/dropfavoriteslist/`, queryString)
                .then((res) => {
                    commit("SET_FAVORITES", filterState);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        GET_ADD_FAVOR: async ({ commit, state }, value) => {
            let valueItem = JSON.parse(value);
            let value1 = { id: valueItem.id, questions: valueItem.questions};
            let item = valueItem.item
            delete item.error
            delete item.favorite
            let queryString = JSON.stringify(value1);
            await axios
                .post(`${base_URL}/users/addfavoriteslist/`, queryString)
                .then(response => {
                    let newFavorites = state.favorites
                    newFavorites.push(item)
                    commit("SET_FAVORITES", newFavorites);
                })
                .catch(err => {
                    console.warn(err)
                })
        },
    },
};
