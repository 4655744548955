<template>
	<div class="timer">
		<div class="timer__time">{{setNormalTime}}</div>
	</div>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>