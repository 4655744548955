import { createApp } from 'vue'
import { dragscrollNext } from 'vue-dragscroll'
import VueTheMask from 'vue-the-mask'
import styles from './app.scss'
import router from './router'
import store from './store'
import App from './views/App/App.vue'

import OldLayout from '@/views/Layouts/OldLayout.vue'

const app = createApp(App)

// Components
app.component('OldLayout', OldLayout)


app.use(store)
app.use(router)
app.use(VueTheMask)
app.directive('dragscroll', dragscrollNext)
app.mount('#app')

app.directive('phone', {
    beforeMount(el, binding, vnode) {
        el.oninput = function(e) {
            if (!e.isTrusted) {
                return
            }
            var position = this.selectionEnd
            let pos = position
            let len = this.value.length
            let x = this.value
                .replace(/\D/g, '')
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.value = !x[2]
                ? x[1]
                : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
            el.dispatchEvent(new Event('input'))
            if (pos < len) {
                this.setSelectionRange(pos, pos)
            }
        }
    },
})
