<template>
	<div class="comments">
		<span class="comments-popup__dop">Комментарии ГИБДД</span>
		<span v-html="description1"></span>
		<span class="comments-popup__dop">Дополнение преподавателя</span>
		<span v-html="description2"></span>
		<div class="comments-popup" v-if="modal">
			<div class="comments-popup__title">
				{{titleModal}}
				<div class="comments-popup__close" @click="closeModal">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5 15L15 5M5 5L15 15" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round"/>
					</svg>
				</div>
			</div>
			<img :src="imgModal" v-if="imgModal.length">
			<div class="comments-popup__description" v-html="descModal"></div>
		</div>
	</div>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>