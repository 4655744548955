import { ref, onMounted, watch, computed, reactive } from "vue";
import Aside from "../../components/Aside/Aside.vue";
import axios from "axios";
import Header from "../../components/Header/Header.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import { useStore } from "vuex";
import { base_URL } from "../../constants";
import router from "../../router";

export default {
    name: "Driving",
    components: {
        Aside,
        Header,
        DownloadPage,
    },
    setup() {
        const activeContacts = ref("");
        const store = useStore();
        const user = reactive({
            id: "0",
            selectInstructor: 0,
            region: "",
            city: "",
        });
        const userData = computed(() => store.getters.userInfo)
        const currentUserDate = computed(() => store.getters.userInfo);
        const instructors = ref([]);
        onMounted(() => {
            user.id = currentUserDate.value.id;
            user.selectInstructor = currentUserDate.value.selected_instructor;
            user.city = currentUserDate.value.city;
            user.region = currentUserDate.value.region;

            if (typeof user.id != "undefined") {
                getInstructors();
            };

            !userData.value && router.push({ name: "SignIn" });
        });

        const activeContactsHandler = (id) => {
            activeContacts.value == id
                ? (activeContacts.value = "")
                : (activeContacts.value = id);
        };

        watch(
            () => currentUserDate.value,
            () => {
                user.id = currentUserDate.value.id;
                user.selectInstructor =
                    currentUserDate.value.selected_instructor;
                user.city = currentUserDate.value.city;
                user.region = currentUserDate.value.region;
                if (typeof user.id != "undefined") {
                    getInstructors();
                }
            }
        );
        const setInstructors = (value) => {
            let requestArray = {
                id: user.id,
                idInstructor: value,
                student: true,
            };
            axios
                .post(
                    `${base_URL}/users/setinstructor/`,
                    JSON.stringify(requestArray)
                )
                .then((response) => {
                    if (response["data"] == true) {
                        store.dispatch("SET_USER_INSTRUCTOR", true);
                        router.push({
                            name: "Wait",
                        });
                    }
                })
                .catch((error) => {});
        };
        const getInstructors = () => {
            let requestArray = {
                city: user.city,
                state: user.region,
            };
            axios
                .post(
                    `${base_URL}/instructor/getinstructorlist/`,
                    JSON.stringify(requestArray)
                )
                .then((response) => {
                    instructors.value = response["data"];
                })
                .catch((error) => {});
        };
        return {
            userData,
            activeContacts,
            activeContactsHandler,
            currentUserDate,
            instructors,
            setInstructors,
        };
    },
};
