<template>
    <div class="progress-block">
        <progress
            ref="progressbar"
            id="progressbar"
            class="progressbar"
            max="100"
            :value="progressbarVal"
        ></progress>
        <input
            type="range"
            id="seekbar"
            :value="seekbar"
            class="seekbar"
            ref="seekbarEl"
            @change="changeprogress"
        />
    </div>
    <div style="color: yellow">{{ seekbar }}</div>
</template>

<script>
import { ref, computed } from "vue";

export default {
    name: "VideoProgress",
    emits: ["onHandler"],
    props: {
        seekbar: {
            type: Number,
        },
        progressbar: {
            type: Number,
        },
    },
    setup({ seekbar, progressbar }, { emit }) {
        const seekbarEl = ref(null);
        const progressbarVal = computed(() => progressbar);
        const changeprogress = () => {
            emit("onHandler", seekbarEl.value.value);
        };
        return {
            seekbar,
            progressbar,
            seekbarEl,
            changeprogress,
            progressbarVal,
        };
    },
};
</script>

<style lang="scss">
.progress-block {
    flex: 1;
    position: relative;
    margin: 0 10px;
}
</style>
