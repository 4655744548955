import axios from 'axios'
import { base_URL } from './../../constants'

export default {
    state: {
        videos: [],
    },
    getters: {
        VIDEOS: state => {
            return state.videos
        },
    },
    mutations: {
        SET_VIDEOS: (state, payload) => {
            state.videos = payload
        },
    },
    actions: {
        GET_VIDEOS: async ({ commit }, payload) => {
            console.log(' GET_VIDEOS', payload)
            const value = {
                id: payload,
            }
            const queryString = JSON.stringify(value)
            await axios
                .post(`${base_URL}/users/getwatchvideo/`, queryString)
                .then(res => {
                    console.log('GET_VIDEOS', res.data)
                    commit('SET_VIDEOS', res.data.watch_video)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        UPDATE_VIDEOS: async ({ commit, state }, payload) => {
            const value = {
                id: payload.idUser,
                id_video: payload.idVIdeo,
            }
            const queryString = JSON.stringify(value)
            await axios
                .post(`${base_URL}/users/setwatchvideo/`, queryString)
                .then(res => {
                    let arr = state.videos
                    arr.push({ flag: true, id: payload.idVIdeo })
                    commit('SET_VIDEOS', arr)
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
}
