import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
	name: 'DeleteProfileModal',
	setup(props, {attrs}) {
    const store = useStore()
    // показать модалку удаления профиля
    const toggleDeleteProfileModal = () => {
      attrs.toggleDeleteProfileModal()
    }

    // удалить профиль
    const deleteProfile = (id) => {
      store.dispatch('DELETE_PROFILE', id)
    }
		return {
      toggleDeleteProfileModal,
      userData: computed(() => store.getters.userInfo),
      deleteProfile
		}
	}
  }
