import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import { base_URL } from "../../constants";

export default {
    name: "FormAuth",
    components: {
        Field,
        Form,
    },
    setup() {
        const router = useRouter();

        // Сохраняем в стор таймер массив
        const getTimerTheory = (userId) => store.dispatch("GET_TIMER", userId);

        const redirect = () => {
            router.push({
                name: "Home",
            });
            setTimeout(() => {
                location.reload();
            }, 500);
        };
        const error = ref("");
        const authCheck = (value) => {
            let queryString = JSON.stringify(value);
            axios
                .post(`${base_URL}/users/authorizeemail/`, queryString)
                .then((response) => {
                    if (response["status"] == 200) {
                        //получаем данные пользователя
                        let serialObj = response.data["id"];
                        localStorage.setItem("userData", serialObj);
                        const getProfileId = () =>
                            store.dispatch(
                                "GET_PROFILE_ID",
                                response.data["id"]
                            );
                        getTimerTheory(response.data["id"]);
                        getProfileId();
                        redirect();
                    }
                })
                .catch((err) => {
                    error.value = "Неправильный логин или пароль";
                });
        };

        const schema = Yup.object().shape({
            email: Yup.string()
                .required("Поле не может быть пустым")
                .email("Не корректный E-mail"),
            password: Yup.string()
                .min(6, "Минимум 6 символов")
                .required("Поле не может быть пустым"),
        });

        const store = useStore();
        const FormAuth = computed(() => store.getters.userInfo);

        const onSubmit = (values) => {
            authCheck(values);
            // setTimeout(() => {
            // 	console.log('FormAuth', FormAuth.value);
            // }, 1000)
        };
        return {
            schema,
            FormAuth,
            onSubmit,
            error,
        };
    },
};
