import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'UserInfo',

	setup () {
		const store = useStore()

		return {
			userData: computed(() => store.getters.userInfo)
		}
	}
}
