<template>
	<div class="tickets-widget">
		<div class="tickets-widget__title">
			Билеты
		</div>
		<div class="tickets-widget__questions">
			сдано билетов
			<div class="tickets-widget__num">
				{{statistic.currentTicket}}/{{statistic.ticketAll}}
			</div>
		</div>
		<router-link class="tickets-widget__btn" :to="{name: 'Tickets'}">Решать билеты</router-link>
	</div>
</template>


<style lang="scss">
	@import './styles.scss';
</style>
<script src="./index.js"></script>