<template>
    <div class="favorites page">
        <Aside></Aside>
        <div class="container">
            <Header></Header>
            <div class="content">

                <div v-if="userData.active == 1">
                    <ul class="favorites__list" v-if="!showCard">
                        <li v-if="!errors.length">
                            Нет вопросов с ошибками
                        </li>
                        <li
                            v-else
                            class="favorites__item"
                            v-for="item in errors"
                            :key="item.id"
                            @click="select"
                        >
                            <img
                                :src="item.picture"
                                class="favorites__img"
                                v-if="item.picture"
                            />
                            <div class="favorites__question">
                                {{ item.quest }}
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <ul class="favorites__list">
                        <li>
                            Раздел для учеников автошколы, для разбора ошибок с преподавателем
                        </li>
                    </ul>
                </div>


                    <ExamBlock
                            :item="errors"
                            v-if="showCard"
                            pageTitle="Ошибки"
                            :showHelpers="true"
                            errors="9999"
                            :errorPage="true"
                            :showCard="showCard"
                            @toggle-card="toggleCard"
                    ></ExamBlock>


            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script src="./index.js"></script>
