<template>
	<div class="modal">
		<div class="modal__content">
			<div class="modal__header">
				<div class="modal__title">Ваше время закончилось</div>
<!--				<div class="modal__close" @click="closeModal"></div>-->
			</div>
<!--			<div class="modal__body">-->
<!--				<p>Если нужно то описание тут</p>-->
<!--			</div>-->
			<div class="modal__footer">
				<div class="modal__btn weight" @click="closeModal">Вернуться на главную</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>