import { ref, onMounted, computed } from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";


export default {
	name: 'ModalExamTest',
	setup () {
		const store = useStore();
		const router = useRouter();
		const deActiveModal = () => store.dispatch('ACTIVE_MODAL_EXAM', false);
		const redirect = () => {
			router.push({
				name: 'Home',
			})
		}
		const closeModal =  () => {
			deActiveModal();
			redirect();
		}
		return {
			closeModal,
			redirect
		}


	}
}