<template>
    <div class="theoryList">
        <LastVideoWidget @onHandler="selectTheory"></LastVideoWidget>
        <TheoryListWidget @onHandler="selectTheory"></TheoryListWidget>
    </div>
</template>

<script>
import { useStore } from "vuex";
import Preloader from "./Preloader/Preloader.vue";
import LastVideoWidget from "./LastVideoWidget.vue";
import TheoryListWidget from "./TheoryListWidget.vue";
import router from "../router/index";

export default {
    name: "TheoryList",
    components: {
        Preloader,
        LastVideoWidget,
        TheoryListWidget,
    },

    setup() {
        const store = useStore();

        const selectTheory = (val) => {
            store.dispatch("UPDATE_LAST_THEORY", val);
            router.push({
                name: "Theory",
            });
        };

        return {
            selectTheory,
        };
    },
};
</script>

<style lang="scss">
.theoryList {
    width: 605px;
    padding: 13px 13px 13px;
    background: #fff;
    box-shadow: 32px 32px 62px rgba(228, 216, 216, 0.6);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    min-height: 720px;
    @media (max-width: 1600px) {
        width: 500px;
    }
    @media (max-width: 1500px) {
        width: 400px;
        min-height: auto;
        height: 100%;
    }
}
</style>
