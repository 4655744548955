import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import Aside from "../../components/Aside/Aside.vue";
import Header from "../../components/Header/Header.vue";
import UserCard from "../../components/UserCard/UserCard.vue";
import UserCardEdit from "../../components/UserCardEdit/UserCardEdit.vue";
import PhotoModal from "../../components/PhotoModal/PhotoModal.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import DeleteProfileModal from '../../components/DeleteProfileModal/DeleteProfileModal.vue'

export default {
    name: "Settings",
    components: {
        Aside,
        Header,
        UserCard,
        UserCardEdit,
        PhotoModal,
        DownloadPage,
        DeleteProfileModal
    },
    setup() {
        // Стор
        const store = useStore();
        let imgSrc = ref("");
        // Переменные
        const edit = ref(false);
        const editPhoto = ref(false);
        const deleteProfileModal = ref(false)

        // Активировать режим редактирования
        const toggleEdit = () => {
            edit.value = !edit.value;
        };
        const toggleEditPhoto = (value) => {
            imgSrc.value = value;
            editPhoto.value = !editPhoto.value;
        };
        const applyPhoto = (value) => {
            if (value != null) {
            }
            editPhoto.value = !editPhoto.value;
        };

        // показать/скрыть модалку удаления профиля
        const toggleDeleteProfileModal = () => {
          deleteProfileModal.value = !deleteProfileModal.value
        }

        return {
            applyPhoto,
            imgSrc,
            editPhoto,
            edit,
            userData: computed(() => store.getters.userInfo),
            toggleEdit,
            toggleEditPhoto,
            deleteProfileModal,
            toggleDeleteProfileModal
        };
    },
};
