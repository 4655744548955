<template>
    <div class="alert-modal">
        <div class="alert-modal__overlay"></div>
        <div class="alert-modal__content alert-modal__content--green">
            <div class="alert-modal__title">Заголовок</div>
            <div class="alert-modal__description">
                Бла бла бла какое то описание
            </div>
            <div class="alert-modal__btn">
                Ок
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script src="./index.js"></script>
