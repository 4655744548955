import { ref, onMounted } from "vue";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { base_URL } from "../../constants";
import axios from "axios";
import { useStore } from "vuex";

export default {
    name: "PhotoModal",
    components: {
        Cropper,
        CircleStencil,
    },
    props: {
        imgSrc: Object,
    },
    setup(props, { attrs, emit }) {
        const store = useStore();
        const imgCrop = ref("");
        const resultImg = ref("");
        const id = ref("");
        onMounted(() => {
            id.value = store.getters.userInfo.id;
            imgCrop.value = props.imgSrc.target.result;
        });

        const crop = () => {
            let requestArray = {
                id: id.value,
                img: resultImg.value,
                base64: true,
            };
            axios
                .post(
                    `${base_URL}/users/setphoto/`,
                    JSON.stringify(requestArray)
                )
                .then((response) => {
                    if (response["data"]["result"] == true) {
                        store.dispatch(
                            "SET_USER_PHOTO",
                            response["data"]["img"]
                        );
                        emit("applyPhoto", null);
                    }
                })
                .catch((error) => {});
        };
        const cancel = () => {
            emit("applyPhoto", null);
        };
        const onChange = ({ coordinates, canvas }) => {
            resultImg.value = canvas.toDataURL();
        };

        return {
            cancel,
            id,
            imgCrop,
            resultImg,
            onChange,
            crop,
        };
    },
};
