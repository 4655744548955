<template>
    <div class="theme-tab">
        <div class="theme-tab__top">
            Здесь вопросы разбиты по темам ПДД.
            Решайте темы <br /> целиком или частями по 20 вопросв.
            <img src="./../../assets/img/alert.svg" alt="picture">
        </div>
        <div class="theme-tab__wrap">
            <Preloader v-if="!listTheme.length"></Preloader>
            <div class="theme-tab__left">
                <ul class="theme-tab__list">
                    <li
                        class="theme-tab__item"
                        v-for="(item, index) in listTheme"
                        :key="index"
                        v-show="!(index % 2)"
                        @click="startTicket(item.id, item.name)"
                    >
                        {{index + 1. }} {{ item.name }}
                        <ul class="theme-tab__list-inner">
                            <li
                                class="theme-tab__item-inner"

                            >
                                {{ item.cnt }} вопрос(а,ов)
                            </li>
                        </ul>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </li>
                </ul>
            </div>
            <div class="theme-tab__right">
                <ul class="theme-tab__list">
                    <li
                        class="theme-tab__item"
                        v-for="(item, index) in listTheme"
                        :key="index"
                        v-show="index % 2"
                        @click="startTicket(item.id, item.name)"
                    >
                        {{index + 1. }} {{ item.name }}
                        <ul class="theme-tab__list-inner">
                            <li
                                class="theme-tab__item-inner"
                            >
                                {{ item.cnt }} вопрос(а,ов)
                            </li>
                        </ul>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
