<template>
    <div class="driving page">
        <Aside></Aside>
        <div class="container">
            <HeaderExam></HeaderExam>
            <div class="content">
                <div class="examgibddlist">
                    <div class="examgibddlist__top">
                        <div class="examgibddlist__left">
                            <div class="examgibddlist__title">Экзамен</div>
                            <div class="examgibddlist__description">
                                20 вопросов по 4 блока <br />
                                в одном блоке макс. 1 ошибка +5 вопросов за
                                ошибку
                            </div>
                        </div>
                        <div class="examgibddlist__timer">
                            Осталось времени
                            <div class="examgibddlist__time">00:19:52</div>
                        </div>
                    </div>
                    <ul class="examgibddlist__list">
                        <li
                            class="examgibddlist__item"
                            v-for="(item, index) in 4"
                            :key="index"
                        >
                            <ul class="examgibddlist__list-inner">
                                <li
                                    class="examgibddlist__item-inner"
                                    v-for="(item, index) in 5"
                                    :key="index"
                                >
                                    <img
                                        src="./../../assets/img/exam.jpg"
                                        class="examgibddlist__img"
                                    />
                                    <div class="examgibddlist__question">
                                        При движении в условиях недостаточной
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script src="./index.js"></script>
