<template>
	<header class="header">
    <div class="header__item header__item--allUssers" v-if="this.$route.path === '/admin' && userData.email === 'mgn@mail.ru'">
      Всего <b>{{ allUsers.length }}</b> пользователей
    </div>
		<div class="header__item">
			{{ userData.progress && userData.progress !== null ? userData.progress : '0'}}%
			<div class="header__progress">
				Прогресс <br>
				теории
			</div>
		</div>
		<div class="header__item">
			{{ userData.progress_driver ? Math.round(100/(13/userData.progress_driver.length)) : '0'}}%
			<div class="header__progress">
				Прогресс <br>
				вождения
			</div>
		</div>
		<div class="header__bell">
			<svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M11.1626 0.920006C10.1336 0.663371 9.00659 0.773538 7.97173 1.31947C6.96428 1.85089 6.1896 2.72754 5.78972 3.78841L4.61586 6.71453C3.35924 9.84694 3.32856 9.92377 3.32856 9.92377C3.21877 10.2166 3.00894 10.4643 2.73777 10.6209L1.43559 11.3727C0.86059 11.7046 0.449239 12.2407 0.277363 12.8821C-0.077409 14.2062 0.711134 15.572 2.03516 15.9267L4.05725 16.4685C3.95536 17.8296 4.79695 19.1601 6.16534 19.5701C7.55543 19.8992 8.94951 19.1678 9.5418 17.9381L11.5639 18.4799C12.8879 18.8347 14.2537 18.0462 14.6085 16.7221C14.7804 16.0807 14.6922 15.4108 14.3602 14.8358L13.6083 13.5336C13.4518 13.2624 13.3939 12.9431 13.4453 12.6345C13.4453 12.6345 13.4571 12.5524 13.9369 9.19824L14.3815 6.09057C14.5656 4.97189 14.3331 3.82535 13.7263 2.86141C13.1031 1.87118 12.1821 1.21228 11.1626 0.920006ZM5.18086 16.7697C5.21 17.5407 5.7072 18.2363 6.45839 18.4765C7.22902 18.6441 8.00741 18.2903 8.41817 17.6371L6.79952 17.2034L5.18086 16.7697ZM2.32801 14.8338L7.09237 16.1104L11.8567 17.387C12.5781 17.5803 13.3222 17.1507 13.5155 16.4293C13.6092 16.0798 13.5611 15.7148 13.3803 15.4015L12.6284 14.0994C12.3422 13.6035 12.2356 13.02 12.328 12.4555L12.9306 8.24247C13.2606 5.93578 13.2641 5.91207 13.2641 5.91207C13.5498 4.19188 12.5022 2.49688 10.8699 2.01258C9.2141 1.61584 7.45937 2.55997 6.84663 4.1925C6.84663 4.1925 6.83785 4.21477 5.97268 6.3714L4.38568 10.3274C4.18343 10.8624 3.79936 11.3145 3.30355 11.6008L2.00134 12.3525C1.68806 12.5334 1.46395 12.8255 1.3703 13.175C1.177 13.8964 1.60663 14.6405 2.32801 14.8338Z" fill="#373737"/>
			</svg>
		</div>
	</header>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>
