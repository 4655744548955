import { onMounted } from 'vue'

export default {
    name: 'Comments',
    props: {
        description1: {
            type: String,
            required: false,
        },
        description2: {
            type: String,
            required: false,
        },
        modal: Boolean,
        titleModal: String,
        imgModal: String,
        descModal: String,
    },
    setup({ modal, titleModal, imgModal, descModal }, { emit }) {
        const closeModal = () => {
            emit('onHandler')
        }
        onMounted(() => {})
        return { closeModal }
    },
}
