<template>
    <div class="driving page">
        <Aside></Aside>
        <div class="container">

            <div class="content">
                <ExamBlock :item="getTicketsTheme" :timerWidget="false" :saveProgress ="true"  :showHelpers="true" v-if="getTicketsTheme" timer="30000" :pageTitle="title && title" :infinitely="true"></ExamBlock>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>