<template>
	<div class="authPage page">
		<Aside></Aside>
		<div class="container">
			<Header></Header>
			<div class="content">
        <RegDescription />
				<FormReg></FormReg>
			</div>
		</div>
	</div>
	<DownloadPage></DownloadPage>
</template>

<style scoped>
	.form {
		padding-top: 100px;
	}

  .content {
    align-items: center;
    gap: 15px;
  }
</style>


<script src="./index.js"></script>
