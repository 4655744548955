import axios from "axios";
import { base_URL } from "./../../constants";

export default {
    state: {
        errors: [],
    },
    getters: {
        ERRORS: (state) => {
            return state.errors;
        },
    },
    mutations: {
        SET_ERRORS: (state, payload) => {
            state.errors = payload;
        },
    },
    actions: {
        GET_ERRORS: async ({ commit }, payload) => {
            const value = {
                id: payload,
            };
            const queryString = JSON.stringify(value);
            await axios
                .post(`${base_URL}/users/geterrorlist/`, queryString)
                .then((res) => {
                    commit("SET_ERRORS", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        SET_ERROR_LIST : async ({ commit, state }, payload) => {
            let valueItem = JSON.parse(payload);
            let item = valueItem.item
            delete item.error
            delete item.favorite
            let value1 = { id: valueItem.id, wrong_questions: valueItem.wrong_questions};
            const queryString = JSON.stringify(value1);

            await axios
                .post(`${base_URL}/users/seterrorlist/`, queryString)
                .then((res) => {
                    let newError = state.errors
                    const filterState = newError.filter(
                        (i) => i.id == valueItem.wrong_questions
                    );
                    if (filterState.length == 0) {
                        newError.push(item)
                        commit("SET_ERRORS", newError);
                    }

                })
                .catch((err) => {
                    console.log(err);
                });
        },
        DEL_ERROR_LIST : async ({ commit, state }, payload) => {
            let valueItem = JSON.parse(payload);

            let value1 = { id: valueItem.id, right_answers: valueItem.right_answers};
            const queryString = JSON.stringify(value1);
            const filterState = state.errors.filter(
                (i) => i.id !== valueItem.right_answers[0]
            );
            await axios
                .post(`${base_URL}/users/seterrorlist/`, queryString)
                .then((res) => {
                    commit("SET_ERRORS", filterState);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
