<template>
	<div class="notFound page">
	<Aside></Aside>
	<div class="container">
		<Header></Header>
		<div class="content">
			<div class="page-404">
				<div class="page-404__title">404</div>
				<div class="page-404__subtitle">Страница не найдена</div>
				<router-link class="page-404__back" to="/">Вернуться на главную</router-link>
			</div>
		</div>
	</div>
  </div>
  <DownloadPage></DownloadPage>
</template>


<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>