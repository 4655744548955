<template>
	<Form class="form" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"  @click="hideListCities">
		<div class="form__header">
			<h1 class="form__title">Регистрация</h1>
			<div class="form__subtitle">Зарегистрируйтесь, чтобы начать обучение</div>
			<div class="form__step">Шаг {{step}}/3</div>
			<ul class="form__step-counter">
				<li class="form__step-counter-item active"
					@click="back(1)"
				>1</li>
				<li class="form__step-counter-item"
					:class="{ 'active': step > 1 }"
					@click="back(2)"
				>2</li>
				<li class="form__step-counter-item"
					:class="{ 'active': step === 3 }"
					@click="back(3)"
				>3</li>
			</ul>
		</div>

		<!-- step 1 -->
		<div class="form__body" v-if="step === 1">
			<div class="form__group">
				<label class="form__label">Имя</label>
				<Field name="name" type="text" class="form__input" :class="{ 'form__err': errors.name }" v-model="stepData1.name" />
				<div class="form__err-text">{{errors.name}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Фамилия </label>
				<Field name="last_name" type="text" class="form__input" :class="{ 'form__err': errors.last_name }" v-model="stepData1.last_name"/>
				<div class="form__err-text">{{errors.last_name}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">E-mail </label>
				<Field name="email" type="email" class="form__input" :class="{ 'form__err': errors.email }" v-model="stepData1.email"/>
				<div class="form__err-text">{{errors.email}}</div>
			</div>
			{{error}}
		</div>

		<!-- step 2 -->
		<div class="form__body" v-if="step === 2">
			<div class="form__group">
				<label class="form__label">Телефон</label>
				<Field name="phone" type="phone" class="form__input" :class="{ 'form__err': errors.phone }"  v-model="stepData2.phone"   v-mask="'+7(###)###-##-##'" placeholder="+7(___)___-__-__"/>
				<div class="form__err-text">{{errors.phone}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Город</label>
				<Field name="city" type="text" class="hide" v-model="city"/>
				<SelectCity placeholder="Выберите город" variant="city" @selectCity="selectCity" />
				<div class="form__err-text">{{errors.city}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Область</label>
				<Field name="region" type="text" class="hide" v-model="region"/>
				<SelectCity placeholder="Выберите область" variant="region" @selectCity="selectCity" />
				<div class="form__err-text">{{errors.region}}</div>
			</div>
		</div>

		<!-- step 3 -->
		<div class="form__body" v-if="step === 3">
			<div class="form__group">
				<label class="form__label">Пароль</label>
				<Field name="password" type="password" class="form__input" :class="{ 'form__err': errors.password }" />
				<div class="form__err-text">{{errors.password}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Подтвердить пароль</label>
				<Field name="confirmPassword" type="password" class="form__input" :class="{ 'form__err': errors.confirmPassword }" />
				<div class="form__err-text">{{errors.confirmPassword}}</div>
			</div>
			{{error}}
		</div>
		<div class="form__footer">
			<button class="form__button" type="submit">Далее</button>
			<router-link class="form__link" to="/signin">Уже есть логин?</router-link>
			<div class='form__polic' v-if="step === 3">
				Отправляя данные вы соглашаетесь <br>
				политикой <a href="#">конфенденциальности</a>
			</div>
		</div>
	</Form>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>