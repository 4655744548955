<template>
    <div class="driving page">
        <Aside></Aside>
        <div class="container">
            <div class="content">
                <ExamBlock :item="Tickets" :timerWidget="false" :saveProgress ="true" :numberTicket="item" v-if="Tickets"  :pageTitle="'Билет №' + getTicketsNumber" :showHelpers="true" :infinitely="true"></ExamBlock>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>