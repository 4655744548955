<template>
    <button
        id="mute"
        :class="'icon-volume' + volIco"
        @click="muteHandler"
    >
  <svg v-show="volIco.length == 2" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M3 9v6h4l5 5V4L7 9H3zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z"/></svg>
  <svg v-show="volIco.length != 2" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M4.34 2.93L2.93 4.34 7.29 8.7 7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06c1.34-.3 2.57-.92 3.61-1.75l2.05 2.05 1.41-1.41L4.34 2.93zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41v3.76zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zm-7-8l-1.88 1.88L12 7.76zm4.5 8c0-1.77-1.02-3.29-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z"/></svg>
  </button>
    <input
        ref="volumebar"
        type="range"
        class="volumebar"
        value="1"
        min="0"
        max="1"
        step="0.1"
        @change="volumeHandler"
    />
</template>

<script>
import { ref, getCurrentInstance } from "vue";

export default {
    name: "VideoVolume",
    setup() {
        const instance = getCurrentInstance();
        const volumebar = ref(null);
        const volIco = ref("-2");

        // Mute
        const muteHandler = () => {
            if (volIco.value == "-2") {
                volIco.value = "";
                instance.parent.refs.player.muted = true;
                instance.parent.refs.player.volume = 0;
                volumebar.value.value = 0;
            } else {
                volIco.value = "-2";
                instance.parent.refs.player.muted = false;
                if (volumebar.value.value == 0) {
                    instance.parent.refs.player.volume = 0.1;
                    volumebar.value.value = 0.1;
                } else {
                    instance.parent.refs.player.volume = volumebar.value.value;
                }
            }
        };

        // Volume
        const volumeHandler = () => {
            instance.parent.refs.player.volume = volumebar.value.value;
            if (volumebar.value.value == 0) {
                instance.parent.refs.player.muted = true;
                volIco.value = "";
            } else if (volumebar.value.value != 0) {
                instance.parent.refs.player.muted = false;
                volIco.value = "-2";
            }
        };
        return {
            muteHandler,
            volumeHandler,
            volumebar,
            volIco,
        };
    },
};
</script>

<style lang="scss">
#mute {
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: auto;
}
</style>
