<template>
	<div class="downloadPage">

		<div class="downloadPage__bg"></div>
    <div class="downloadPage__content">
      <h1 class="downloadPage__title">
        Автошкола <br>
        Категории В
      </h1>

      <div class="downloadPage__links-wrap">
        <div class="downloadPage__col">
          <div class="downloadPage__link-title">
            Скачать <br> приложение
          </div>
          <a href="https://play.google.com/store/apps/details?id=com.pdd.categorb" class="downloadPage__btn" v-if="device == 'android'">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.2549 1.74519C29.1295 0.619812 27.6332 6.25e-05 26.0416 0.000125L5.95837 0C2.67287 0.000125 0 2.67306 0 5.95831V26.0416C0 29.3271 2.673 32 5.95837 32H26.0416C29.3271 32 32 29.3271 32 26.0416V5.95844C32 4.36687 31.3803 2.87056 30.2549 1.74519ZM10.3666 13.5934C10.7326 13.2273 11.3262 13.2273 11.6924 13.5934L15.0625 16.9635L15.0624 6.96244C15.0624 6.44469 15.4821 6.02494 15.9999 6.02494C16.5177 6.02494 16.9374 6.44469 16.9374 6.96244L16.9375 16.9632L20.3074 13.5933C20.6734 13.2272 21.2671 13.2272 21.6332 13.5933C21.9993 13.9593 21.9993 14.5529 21.6332 14.9191L16.6629 19.8896C16.487 20.0654 16.2486 20.1642 15.9999 20.1642C15.7514 20.1642 15.5129 20.0654 15.3371 19.8896L10.3667 14.9192C10.0004 14.5531 10.0004 13.9595 10.3666 13.5934ZM25.0376 25.975H6.9625C6.44481 25.975 6.025 25.5553 6.025 25.0375C6.025 24.5198 6.44481 24.1 6.9625 24.1H25.0376C25.5553 24.1 25.9751 24.5198 25.9751 25.0375C25.9751 25.5553 25.5553 25.975 25.0376 25.975Z" fill="#FBFCFE"/>
            </svg>
          </a>
          <a href="https://apps.apple.com/ru/app/автошкола-пддонлайн/id1589766574" class="downloadPage__btn" v-if="device == 'ios'">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.2549 1.74519C29.1295 0.619812 27.6332 6.25e-05 26.0416 0.000125L5.95837 0C2.67287 0.000125 0 2.67306 0 5.95831V26.0416C0 29.3271 2.673 32 5.95837 32H26.0416C29.3271 32 32 29.3271 32 26.0416V5.95844C32 4.36687 31.3803 2.87056 30.2549 1.74519ZM10.3666 13.5934C10.7326 13.2273 11.3262 13.2273 11.6924 13.5934L15.0625 16.9635L15.0624 6.96244C15.0624 6.44469 15.4821 6.02494 15.9999 6.02494C16.5177 6.02494 16.9374 6.44469 16.9374 6.96244L16.9375 16.9632L20.3074 13.5933C20.6734 13.2272 21.2671 13.2272 21.6332 13.5933C21.9993 13.9593 21.9993 14.5529 21.6332 14.9191L16.6629 19.8896C16.487 20.0654 16.2486 20.1642 15.9999 20.1642C15.7514 20.1642 15.5129 20.0654 15.3371 19.8896L10.3667 14.9192C10.0004 14.5531 10.0004 13.9595 10.3666 13.5934ZM25.0376 25.975H6.9625C6.44481 25.975 6.025 25.5553 6.025 25.0375C6.025 24.5198 6.44481 24.1 6.9625 24.1H25.0376C25.5553 24.1 25.9751 24.5198 25.9751 25.0375C25.9751 25.5553 25.5553 25.975 25.0376 25.975Z" fill="#FBFCFE"/>
            </svg>
          </a>
          <img src="./../../assets/img/google.svg" alt="markets" class="downloadPage__markets">
          <img src="./../../assets/img/appstore.svg" alt="markets" class="downloadPage__markets">
        </div>
        <div class="downloadPage__col">
          <div class="downloadPage__img"></div>
        </div>
      </div>

      <div class="downloadPage__description">
        Получи  ВОДИТЕЛЬСКИЕ ПРАВА <br>
        вместе с нашим приложением
      </div>
		</div>


		<img src="./../../assets/img/rule.png" alt="picture" class="downloadPage__rule">

	</div>
</template>

<style lang="scss">
	@import './styles.scss';
</style>
<script src="./index.js"></script>
