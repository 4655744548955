import { ref, onMounted, computed, watch, reactive } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import TheoryList from '../../components/TheoryList.vue'
import ExamWidget from '../../components/ExamWidget/ExamWidget.vue'
import TicketsWidget from '../../components/TicketsWidget/TicketsWidget.vue'
import DriveWidget from '../../components/DriveWidget/DriveWidget.vue'
import StatisticWidget from '../../components/StatisticWidget/StatisticWidget.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { base_URL } from '../../constants'

export default {
    name: 'Home',
    components: {
        Aside,
        Header,
        TheoryList,
        ExamWidget,
        TicketsWidget,
        DriveWidget,
        StatisticWidget,
        DownloadPage,
    },
    setup(props, { attrs }) {
        const store = useStore()
        const currentUserDate = computed(() => store.getters.userInfo)
        const user = reactive({ id: '0', active: false, selectInstructor: 0 })
        const statistic = reactive({
            progress: 1,
            questionsAll: 800,
            questionsAnswered: 0, //отвечено вопросов
            currentDriver: 0, //Текущее значение вождения
            driverAll: 100,
            theory: 0,
            question: 0,
            currentTicket: 0, //решено билетов
            ticketAll: 40, //Общее кол-во билетов
        })
        let drivingRecord = reactive({ schedule: '' })
        onMounted(() => {
            user.id = currentUserDate.value.id
            user.selectInstructor = currentUserDate.value.selected_instructor
            user.active = currentUserDate.value.active
            promise()
        })

        watch(
            () => currentUserDate.value,
            () => {
                user.selectInstructor =
                    currentUserDate.value.selected_instructor
                user.active = currentUserDate.value.active
                user.id = currentUserDate.value.id
                promise()
            }
        )

        const promise = () => {
            let prom1 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: user.id,
                }

                axios
                    .post(
                        `${base_URL}/instructor/getprogressdriver/`,
                        JSON.stringify(requestArray)
                    )
                    .then(response => {
                        resolve(response['data'])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            let prom2 = new Promise((resolve, reject) => {
                let requestArray = {
                    id_user: user.id,
                }

                axios
                    .post(
                        `${base_URL}/users/geterrorticket/`,
                        JSON.stringify(requestArray)
                    )
                    .then(response => {
                        resolve(response['data'])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            let prom3 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: user.id,
                }
                axios
                    .post(
                        `${base_URL}/users/getlessoncadet/`,
                        JSON.stringify(requestArray)
                    )
                    .then(response => {
                        resolve(response['data'])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            let prom4 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: user.id,
                }
                axios
                    .post(
                        `${base_URL}/users/getcountquestion/`,
                        JSON.stringify(requestArray)
                    )
                    .then(response => {
                        resolve(response['data'])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })

            if (typeof user.id != 'undefined') {
                const promAll = Promise.all([prom1, prom2, prom3, prom4]).then(
                    value => {
                        statistic.currentTicket = 0
                        value[1].forEach(item => {
                            if (item['flag' == true]) {
                                statistic.currentTicket++
                            }
                        })
                        statistic.driverAll = value[0]['count']
                        statistic.currentDriver =
                            value[0]['progressDriver'].length
                        drivingRecord.schedule = value[2]['time_table']
                        value[3].questions.forEach(item => {
                            if (
                                parseInt(item['number']) <=
                                parseInt(value[3].progress)
                            ) {
                                statistic.questionsAnswered += parseInt(
                                    item['count']
                                )
                            }
                        })
                    }
                )
            }

            if (typeof user.id != 'undefined') {
                const promAll = Promise.all([prom1, prom2, prom3, prom4]).then(
                    value => {
                        statistic.currentTicket = 0
                        value[1].forEach(item => {
                            if (item.flag == true && item.error < 3) {
                                statistic.currentTicket++
                            }
                        })
                        statistic.driverAll = value[0]['count']
                        statistic.currentDriver =
                            value[0]['progressDriver'].length
                        drivingRecord.schedule = value[2]['time_table']
                        value[3].questions.forEach(item => {
                            if (
                                parseInt(item['number']) <=
                                parseInt(value[3].progress)
                            ) {
                                statistic.questionsAnswered += parseInt(
                                    item['count']
                                )
                            }
                        })
                    }
                )
            }
        }

        return {
            drivingRecord,
            user,
            statistic,
            currentUserDate,
            userData: computed(() => store.getters.userInfo),
        }
    },
}
