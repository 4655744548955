import axios from "axios";
import { base_URL } from "./../../constants";

export default {
    state: {
        timer: {},
        currentTime: 0,
    },
    getters: {
        TIMER: (state) => {
            return state.timer;
        },
        CURRENT_TIME: (state) => {
            return state.currentTime;
        },
    },
    mutations: {
        SET_TIMER: (state, payload) => {
            state.timer = payload;
        },
        SET_CURRENT_TIME: (state, payload) => {
            state.timer = payload;
        },
    },
    actions: {
        GET_TIMER: async ({ commit }, payload) => {
            const value = {
                id: payload,
            };
            const queryString = JSON.stringify(value);
            await axios
                .post(`${base_URL}/users/getmarkvideo/`, queryString)
                .then((res) => {
                    commit("SET_TIMER", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        GET_CURRENT_TIME: async ({ commit }, payload) => {
            const value = {
                id: payload,
            };
            const queryString = JSON.stringify(value);
            await axios
                .post(`${base_URL}/users/getmarkvideo/`, queryString)
                .then((res) => {
                    commit("SET_CURRENT_TIME", res.data.current_time);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        UPDATE_TIMER: async ({ commit, state }, payload) => {
            const value = {
                id: payload.id,
                id_video: payload.id_video,
            };
            const queryString = JSON.stringify(value);
            await axios
                .post(`${base_URL}/users/setmarkvideo/`, queryString)
                .then((res) => {
                    console.log("res.data", res.data);

                    let var_state = state.timer;
                    var_state.current_time = res.data;
                    var_state.mark_video.push({
                        timestamp: res.data,
                        id: payload.id_video,
                    });

                    commit("SET_CURRENT_TIME", res.data);
                    commit("SET_TIMER", var_state);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
