<template>
	<Form class="form" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }" >
		<div class="form__header">
			<h1 class="form__title">Авторизация</h1>
			<div class="form__subtitle">Войдите, чтобы начать обучение</div>
		</div>

		<div class="form__body">
			<div class="form__group">
				<label class="form__label">E-mail</label>
				<Field name="email" type="email" class="form__input" :class="{ 'form__err': errors.email }" />
				<div class="form__err-text">{{errors.email}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Пароль</label>
				<Field name="password" type="password" class="form__input" :class="{ 'form__err': errors.password }" />
				<div class="form__err-text">{{errors.password}}</div>
			</div>
			<div class="form__error">{{error}}</div>
		</div>


		<div class="form__footer">
			<button type="submit" class="form__button">Войти</button>
			<router-link class="form__link" to="/signup">Зарегистрироваться</router-link>
		</div>
	</Form>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>