import { ref, onMounted, computed, refs } from "vue";
import { Field, Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { base_URL, REQUIRED_TEXT } from "../../constants";
import { useStore } from "vuex";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
    name: "UserCard",
    components: {
        Field,
        Form,
        Cropper,
    },

    setup(props, { attrs, emit }) {
        const store = useStore();
        const cityArray = ref([]);
        let regionArray = ref([]);
        const city = ref("");
        const region = ref("");
        const activeCityList = ref(false);
        const activeRegionList = ref(false);
        let nameUser = ref("");
        let lastNameUser = ref("");
        let email = ref("");
        let currentUserDate = ref({});
        let phone = ref("");
        const viber = ref(true);
        const whatsapp = ref(false);
        let photo = ref("");

        //

        onMounted(() => {
            getCityLocalData();
            currentUserDate = computed(() => store.getters.userInfo);
            nameUser.value = currentUserDate.value.name;
            lastNameUser.value = currentUserDate.value.last_name;
            photo.value = currentUserDate.value.photo;
            email.value = currentUserDate.value.email;
            viber.value = currentUserDate.value.viber != null ? true : false;
            whatsapp.value =
                currentUserDate.value.whatsapp != null ? true : false;
            let currentPhone = currentUserDate.value.phone;
            currentPhone =
                currentPhone.indexOf("+7") > -1
                    ? currentPhone.replace(/^.{2}/, "")
                    : currentPhone;
            currentPhone =
                currentPhone.substr(0, 1).indexOf("8") > -1
                    ? currentPhone.replace(/^.{1}/, "")
                    : currentPhone;
            let x = currentPhone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            phone.value = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
            city.value = currentUserDate.value.city;
            region.value = currentUserDate.value.region;
        });

        // Фильтруем массив от одинаковых значений
        const unique = (arr) => {
            let result = [];
            for (let str of arr) {
                if (!result.includes(str)) {
                    result.push(str);
                }
            }
            return result;
        };

        // Получаем данные городов из json
        const getCityLocalData = async () => {
            const result = await require("./../../assets/cities.json");
            cityArray.value = result;

            let regionTemp = [];
            result.forEach((i) => {
                regionTemp.push(i.region);
            });
            regionArray = unique(regionTemp);
        };

        // Активируем выпадашку с городами
        const activeCityListToggle = () => {
            activeCityList.value = true;
            activeRegionList.value = false;
        };

        // Активируем выпадашку с регионами
        const activeRegionListToggle = () => {
            activeRegionList.value = true;
            activeCityList.value = false;
        };

        // Валидация полей
        const schema = Yup.object().shape({
            email: Yup.string()
                .required(REQUIRED_TEXT)
                .email("Не корректный E-mail"),
            password: Yup.string()
                .min(6, "Минимум 6 символов")
                .required(REQUIRED_TEXT),
            name: Yup.string()
                .min(2, "Минимум 2 символов")
                .required(REQUIRED_TEXT),
            last_name: Yup.string()
                .min(2, "Минимум 2 символов")
                .required(REQUIRED_TEXT),
            phone: Yup.string().required(REQUIRED_TEXT),
            city: Yup.string().required(REQUIRED_TEXT),
            region: Yup.string().required(REQUIRED_TEXT),
        });

        // проверяем совпвдение введенных данных города
        const filterCity = computed(() => {
            var word = city.value.toLowerCase();
            return cityArray.value.filter((elem) => {
                if (word === "") return true;
                else
                    return (
                        elem.city.toLowerCase().indexOf(word.toLowerCase()) > -1
                    );
            });
        });

        // проверяем совпвдение введенных данных региона
        const filterRegion = computed(() => {
            var word = region.value.toLowerCase();
            return regionArray.filter((elem) => {
                if (word === "") return true;
                else return elem.toLowerCase().indexOf(word.toLowerCase()) > -1;
            });
        });

        // Закрываем выпадашку с городлом и регионом

        const hideListCities = (e) => {
            if (e.target.className == "user-card") {
                activeCityList.value = false;
                activeRegionList.value = false;
            }
        };

        // Сохраняем выбранный город
        const selectCity = (val) => {
            activeCityList.value = false;
            city.value = val;
        };

        // Сохраняем выбранный регион
        const selectRegion = (val) => {
            activeRegionList.value = false;
            region.value = val;
        };

        const clickPencil = (value) => {
            document.querySelector("#fileUploadPicture").value = "";
            document.querySelector("#fileUploadPicture").click();
        };

        //Событие по помощению файла в загрузку
        const activeModalEditPhoto = (value) => {
            var files = value.target.files || value.dataTransfer.files;

            if (!files.length) return;

            var reader = new FileReader();
            reader.onload = (value) => {
                emit("toggleEditPhoto", value);
                reader = null;
                files = null;
            };

            reader.readAsDataURL(files[0]);
        };

        const onSubmit = (values) => {
            let prom1 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: currentUserDate.value.id,
                    name: nameUser.value,
                    last_name: lastNameUser.value,
                    city: city.value,
                    email: email.value,
                    phone: "8" + phone.value.replace(/[^\+\d]/g, ""),
                    region: region.value,
                };
                requestArray.viber =
                    viber.value == true
                        ? "viber://add?number=" +
                          "8" +
                          phone.value.replace(/[^\+\d]/g, "")
                        : "";
                requestArray.whatsapp =
                    whatsapp.value == true
                        ? "https://wa.me/" +
                          "8" +
                          phone.value.replace(/[^\+\d]/g, "")
                        : "";

                axios
                    .post(
                        `${base_URL}/users/setprofileid/`,
                        JSON.stringify(requestArray)
                    )
                    .then((response) => {
                        resolve(response["data"]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            let prom2 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: currentUserDate.value.id,
                };
                axios
                    .post(
                        `${base_URL}/users/getprofileid/`,
                        JSON.stringify(requestArray)
                    )
                    .then((response) => {
                        resolve(response["data"]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });

            const promall = Promise.all([prom1, prom2]).then((value) => {
                const getProfileId = () =>
                    store.dispatch(
                        "GET_PROFILE_ID",
                        localStorage.getItem("userData")
                    );
                getProfileId();
                toggleEdit();
            });
        };

        const toggleEdit = () => {
            attrs.toggleEdit();
        };

        return {
            photo,
            viber,
            whatsapp,
            clickPencil,
            activeModalEditPhoto,
            toggleEdit,
            schema,
            onSubmit,
            city,
            filterCity,
            activeCityList,
            activeCityListToggle,
            hideListCities,
            selectCity,
            region,
            activeRegionList,
            activeRegionListToggle,
            filterRegion,
            regionArray,
            selectRegion,
            nameUser,
            currentUserDate,
            lastNameUser,
            email,
            phone,
            userData: computed(() => store.getters.userInfo),
        };
    },
};
