import { createRouter, createWebHistory } from 'vue-router'
import AdminPage from '../views/Admin.vue'
import Driving from '../views/Driving/Driving.vue'
import ErrorsQuestions from '../views/ErrorsQuestions/ErrorsQuestions.vue'
import ExamCustom from '../views/ExamCustom/ExamCustom.vue'
import ExamDifficult from '../views/ExamDifficult/ExamDifficult.vue'
import ExamGIBDD from '../views/ExamGIBDD/ExamGIBDD.vue'
import ExamGIBDDList from '../views/ExamGIBDDList/ExamGIBDDList.vue'
import ExamMaraphon from '../views/ExamMaraphon/ExamMaraphon.vue'
import ExamTest from '../views/ExamTest/ExamTest.vue'
import Favorites from '../views/Favorites/Favorites.vue'
import Home from '../views/Home/Home.vue'
import PageNotFound from '../views/PageNotFound/PageNotFound.vue'
import Settings from '../views/Settings/Settings.vue'
import Shedule from '../views/Shedule/Shedule.vue'
import SignIn from '../views/SignIn/SignIn.vue'
import SignUp from '../views/SignUp/SignUp.vue'
import Statistics from '../views/Statistics/Statistics.vue'
import Theory from '../views/Theory.vue'
import Tickets from '../views/Tickets/Tickets.vue'
import TicketsExam from '../views/TicketsExam/TicketsExam.vue'
import TicketsExamTheme from '../views/TicketsExamTheme/TicketsExamTheme.vue'
import Wait from '../views/Wait/Wait.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/admin',
        name: 'AdminPage',
        component: AdminPage,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/driving',
        name: 'Driving',
        component: Driving,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/theory',
        name: 'Theory',
        component: Theory,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/tickets',
        name: 'Tickets',
        component: Tickets,
        props: true,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: PageNotFound,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/examgibdd',
        name: 'ExamGIBDD',
        component: ExamGIBDD,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/examcustom',
        name: 'ExamCustom',
        component: ExamCustom,
        props: true,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/exammaraphon',
        name: 'ExamMaraphon',
        component: ExamMaraphon,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/examdifficult',
        name: 'ExamDifficult',
        component: ExamDifficult,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/examtest',
        name: 'ExamTest',
        component: ExamTest,
        props: true,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/ticketsexam',
        name: 'TicketsExam',
        component: TicketsExam,
        props: true,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/ticketsexamtheme',
        name: 'TicketsExamTheme',
        component: TicketsExamTheme,
        props: true,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/shedule',
        name: 'Shedule',
        component: Shedule,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/wait',
        name: 'Wait',
        component: Wait,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/examgibddlist',
        name: 'ExamGIBDDList',
        component: ExamGIBDDList,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: Favorites,
        meta: {
          layout: 'old-layout',
        },
    },
    {
        path: '/errorsquestions',
        name: 'ErrorsQuestions',
        component: ErrorsQuestions,
        meta: {
          layout: 'old-layout',
        },
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
