import { createStore } from 'vuex'
import admin from './modules/admin'
import aside from './modules/aside'
import errors from './modules/errors'
import exam from './modules/exam'
import favorites from './modules/favorites'
import pravila from './modules/pravila'
import theory from './modules/theory'
import timer from './modules/timer'
import user from './modules/user'
import watchedVideo from './modules/watchedVideo'
import znaki from './modules/znaki'

export default createStore({
    modules: {
        user,
        theory,
        aside,
        exam,
        favorites,
        errors,
        timer,
        znaki,
        pravila,
        watchedVideo,
        admin
    },
})
