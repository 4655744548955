import FormReg from '../../components/FormReg/FormReg.vue'
import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import RegDescription from '../../components/RegDescription.vue'

export default {
	name: 'SignUp',
	components: {
		FormReg,
		Aside,
		Header,
		DownloadPage,
    RegDescription
    },
  }
