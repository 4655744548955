import { ref, computed, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import Aside from "../../components/Aside/Aside.vue";
import Header from "../../components/Header/Header.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import { base_URL } from "../../constants";

export default {
    name: "Shedule",
    components: {
        Aside,
        Header,
        DownloadPage,
    },
    setup() {
        const contactsActive = ref(false);
        const store = useStore();
        const currentUserDate = computed(() => store.getters.userInfo);
        const user = reactive({
            id: "0",
            selectInstructor: 0,
            region: "",
            city: "",
        });
        const instructor = ref({});
        const schedule = ref([]);

        onMounted(() => {
            user.id = currentUserDate.value.id;
            user.selectInstructor = currentUserDate.value.selected_instructor;

            if (typeof user.id != "undefined" && user.id != "0") {
                getInfo();
            }
        });
        watch(
            () => currentUserDate.value,
            () => {
                user.id = currentUserDate.value.id;
                user.selectInstructor =
                    currentUserDate.value.selected_instructor;
                if (typeof user.id != "undefined" && user.id != "0") {
                    getInfo();
                }
            }
        );
        const contactsActiveHandler = () => {
            contactsActive.value = !contactsActive.value;
        };

        const getInfo = () => {
            let prom1 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: user.id,
                };

                axios
                    .post(
                        `${base_URL}/users/getselectinstructor/`,
                        JSON.stringify(requestArray)
                    )
                    .then((response) => {
                        resolve(response["data"]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            let prom2 = new Promise((resolve, reject) => {
                let requestArray = {
                    id: user.id,
                };
                axios
                    .post(
                        `${base_URL}/users/getlessoncadet/`,
                        JSON.stringify(requestArray)
                    )
                    .then((response) => {
                        resolve(response["data"]);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
            const promAll = Promise.all([prom1, prom2]).then((value) => {
                instructor.value = value[0];
                schedule.value = value[1]["time_table"];
            });
        };

        return {
            schedule,
            instructor,
            contactsActive,
            currentUserDate,
            user,
            contactsActiveHandler,
        };
    },
};
