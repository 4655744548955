import Aside from "../../components/Aside/Aside.vue";
import Header from "../../components/Header/Header.vue";
import ExamBlock from "../../components/ExamTest/ExamTest.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import {computed, onMounted, reactive, ref} from "vue";
import { useStore } from "vuex";

export default {
    name: "ErrorsQuestions",
    components: {
        Aside,
        Header,
        ExamBlock,
        DownloadPage,
    },
    setup() {
        const store = useStore();
        const showCard = ref(false);
        const userData = computed(() => store.getters.userInfo)

        const select = () => {
            showCard.value = true;
        }
        const toggleCard = () => {
            showCard.value = !showCard.value
        }

        return {
            errors: computed(() => store.getters.ERRORS),
            userData: computed(() => store.getters.userInfo),
            select,
            showCard,
            userData,
            toggleCard
        };
    },
};
