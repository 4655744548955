<template>
    <button
        id="fullscreen"
        :class="
            !isFullscreen ? 'icon-fullscreen-alt' : 'icon-fullscreen-exit-alt'
        "
        @click="fullscreenbuttonHandler"
    >
    <svg v-show="!isFullscreen" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg>
    <svg v-show="isFullscreen" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/></svg>
    </button>
</template>

<script>
export default {
    name: "VideoFullScreen",
    emits: ["onHandler"],
    props: {
        isFullscreen: {
            type: Boolean,
        },
    },
    setup(props, { emit }) {
        // FullScreen
        const fullscreenbuttonHandler = () => {
            emit("onHandler");
        };

        return {
            fullscreenbuttonHandler,
        };
    },
};
</script>

<style lanf="scss">
#fullscreen {
    color: #fff;
    background: transparent;
    border: none;
    align-content: flex-end;
    cursor: pointer;
}
</style>
