<template>
    <div class="tab">
        <div class="tab__header">
            <div
                class="tab__btn"
                @click="activeTabHandler('1')"
                :class="{ active: activeTab == '1' }"
            >
                Билеты
            </div>
            <div
                class="tab__btn"
                @click="activeTabHandler('2')"
                :class="{ active: activeTab == '2' }"
            >
                Темы
            </div>
            <div
                class="tab__btn"
                @click="activeTabHandler('3')"
                :class="{ active: activeTab == '3' }"
            >
                Экзамен
            </div>
        </div>
        <div class="tab__body" :class="{ active: activeTab == '1' }">
            <div class="tab__item" v-if="activeTab == '1'">
                <TicketsTab></TicketsTab>
            </div>
            <div class="tab__item" v-if="activeTab == '2'">
                <ThemeTab></ThemeTab>
            </div>
            <div class="tab__item" v-if="activeTab == '3'">
                <ExamTab></ExamTab>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
