<template>
    <div class="exam-widget">
        <div class="exam-widget__title">Экзамены</div>
        <div class="exam-widget__wrapper">
            <router-link
                class="exam-widget__link exam-widget__link--red"
                :to="{
                        name: 'Tickets',
                        params: {
                            exam : true
                        }
                    }"
            >
                <b class="exam-widget__subtitle">Экзамен ГИБДД</b>
                20 вопросов по 4 блока <br />
                в одном блоке макс. 1 ошибка <br />
                +5 вопросов за ошибку
            </router-link>

            <router-link class="exam-widget__link" :to="{
                        name: 'Tickets',
                        params: {
                            exam : true
                        }
                    }">
                <b class="exam-widget__subtitle">Свой экзамен</b>
                Настройки экзамена под себя
            </router-link>

            <div class="exam-widget__spacer"></div>

            <router-link class="exam-widget__link" to="/exammaraphon">
                <b class="exam-widget__subtitle">Марафон</b>
                800 вопросов подряд
            </router-link>
            <router-link class="exam-widget__link" to="/examdifficult">
				<Preloader v-if="preloaderActive"></Preloader>
                <b class="exam-widget__subtitle">Самые трудные</b>
                {{hard_question_count}} самых сложных вопросов
            </router-link>
        </div>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
