<template>
  <div class="statistics page">
	<Aside></Aside>
	<div class="container">
		<Header></Header>
	</div>
  </div>
<DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>

