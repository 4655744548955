import {computed, onBeforeUnmount, onMounted, ref, reactive} from 'vue'
import TimerWidget from '../../components/TimerWidget/TimerWidget.vue'
import Comments from '../../components/Comments/Comments.vue'
import ModalExamTest from '../../components/ModalExamTest/ModalExamTest.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import axios from 'axios'
import { base_URL } from '../../constants'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Preloader from './../Preloader/Preloader.vue'
import router from './../../router'

import ExamBlock from './ExamTest'
import exam from "../../store/modules/exam";

export default {
    name: 'ExamBlock',
    components: {
        TimerWidget,
        Comments,
        ModalExamTest,
        Preloader,
        HeaderExam,
    },
    props: {
        item: {
            type: Object,
            required: false,
        },
        nameSection: {
            type: String,
            required: false,
        },
        timerWidget: {
            type: Boolean,
            required: false,
        },
        saveProgress: {
            type: Boolean,
            required: false,
        },
        showHelpers: {
            type: Boolean,
            required: false,
        },
        numberTicket: {
            type: String,
            required: false,
        },
        errors: {
            type: String,
            required: false,
        },
        timer: {
            type: Number,
        },
        testExam : {
            type : Boolean
        },
        testTheory : {
            type : Boolean
        },
        idVideo : {
            type : String
        },
		pageTitle: {
			type: String
		},
        errorPage : {
            type : Boolean
        },
        showCard : {
            type : Boolean
        },
        infinitely : {
            type : Boolean
        },

    },
    setup({
        item,
        nameSection,
        timerWidget,
        saveProgress,
        numberTicket,
        showHelpers,
        errors,
        timer,
        testExam,
        testTheory,
        idVideo,
        errorPage,
        ShowCard,
        infinitely
    }, {emit}) {
        const store = useStore()
        const theory = ref([])
        const countTickets = ref('')
        const currentTicketNumber = ref(1)
        const currentAnswerNumber = ref('')
        const resultTest = ref('')
        const result = ref([])
        const unAnswerArr = ref([])
        const AnswerCount = ref(0)
        const showComments = ref(false)
        const nameSect = nameSection ? ref(JSON.parse(nameSection)) : ref('')
        const timerWid = timerWidget ? ref(timerWidget) : ref('')
        const stopTimerForWidget = ref(false);
        const startTimerForWidget = ref(false);
        const saveProgr = saveProgress ? ref(saveProgress) : ref('')
        const errorsCount = errors ? ref(errors) : ref(2)
        const showHelpersButton = ref(showHelpers)
        const NumberFromTicket = numberTicket
            ? ref(JSON.parse(numberTicket))
            : ref('')
        const scrollContainer = ref(null)

        const controls = ref(false)

        const modal = ref(false)
        const accessProgress = ref('');
        const timerTheory = reactive(computed(() => store.getters.TIMER));



        const goBack = () => (
            !testTheory && !testExam ? router.go(-1) : router.push({name: 'Home'})
        )
        const clickHandler = () => (
            emit('toggle-card')
        )

        const titleModal = ref('')
        const imgModal = ref('')
        const descModal = ref('')
        const firstArray = ref([]);
        onMounted(async () => {
            let widthList = document.querySelector('.exam__top').offsetWidth
            let widthElements = item.length * 52

            if (widthElements > widthList) {
                controls.value = true
            }

            theory.value = JSON.parse(JSON.stringify(item))
            firstArray.value  = JSON.parse(JSON.stringify(item))
            unAnswerArr.value = []
            theory.value.forEach(function(item, i, arr) {
                delete item.result
                delete item.currentAnsewer
                delete item.disabled
            })
            accessProgress.value = await timerTheory.value.mark_video.some(
                (i) => i.id == idVideo
            )

            console.table(theory.value)
        })
        onBeforeUnmount(async () => {
            theory.value = JSON.parse(JSON.stringify(firstArray.value));
            unAnswerArr.value = []
            currentTicketNumber.value = 1;
            currentAnswerNumber.value = '';
            AnswerCount.value = 0;
            unAnswerArr.value = [];
            resultTest.value = false;

            app.removeEventListener('click', getHrefLink)

        })
        const userData = computed(() => store.getters.userInfo)

        // const countTrueAnswer = computed(() => {
        //     let trueObject = theory.value.filter(item => item.result == 1);
        //     let falseObject = theory.value.filter(item => item.result == 0);
        //     AnswerTrueCount.value = trueObject.length
        //     AnswerFalseCount.value = falseObject.length
        // })

        const AnswerTrueCount = computed(() => {
            let trueObject = theory.value.filter(item => item.result == 1)
            return trueObject.length
        })

        const AnswerFalseCount = computed(() => {
            let falseObject = theory.value.filter(item => item.result == 0)
            return falseObject.length
        })

        const currentTicket = val => {
            currentTicketNumber.value = val
            currentAnswerNumber.value = ''
        }
        const showHideComment = () => {
            showComments.value = !showComments.value
        }

        const currentAnswer = (valTicket, val, asnw) => {
            if (theory.value[valTicket].disabled != 'Y') {
                //если не заблокирован ответ
                currentAnswerNumber.value = val
                theory.value[valTicket].currentAnsewer = val
                theory.value[valTicket].result = asnw.correct == 'Y' ? 1 : 0
                // if (asnw.correct == 'N') {
                //     saveError(valTicket);
                // }
            }
        }

        const saveError = async (valTicket) => {
            let value = {
                id: userData.value.id,
                wrong_questions: [theory.value[valTicket].id],
                item: theory.value[valTicket]
            }
            let queryString = JSON.stringify(value)
            const addError = () => store.dispatch('SET_ERROR_LIST', queryString)
            await addError()
        }

        const DelError = async (valTicket) => {
            let value = {
                id: userData.value.id,
                right_answers: [theory.value[valTicket].id]
            }
            let queryString = JSON.stringify(value)
            const DelError = () => store.dispatch('DEL_ERROR_LIST', queryString)
            await DelError()
        }

        const unAnswerMethod = () => {
            unAnswerArr.value = []
            theory.value.forEach(function(item, i, arr) {
                if (item.result != 0 && item.result != 1) {
                    unAnswerArr.value.push(i + 1)
                }
            })
        }
        const nextQuestion = index => {

                if (theory.value[index].result == 0 && !errorPage) {
                    saveError(index);
                }
                if (theory.value[index].result == 1 && errorPage) {
                    DelError(index);
                }


            let falseObject = theory.value.filter(item => item.result == 0)
            if (errorsCount && (falseObject.length > errorsCount.value) && falseObject.length != 0 && !infinitely) {
                resultTest.value = true;
                stopTimerForWidget.value = true;
                startTimerForWidget.value = false;
                if (saveProgr.value) {
                    saveProgressFunc(
                        NumberFromTicket.value.number.number,
                        AnswerFalseCount.value,
                        NumberFromTicket.value.id_user
                    )
                }

            }
            unAnswerMethod()
            AnswerCount.value = AnswerTrueCount.value + AnswerFalseCount.value
            if (unAnswerArr.value.length > 0) {
                if (index + 1 >= Object.keys(theory.value).length) {
                    currentTicketNumber.value = 0
                    currentTicketNumber.value = unAnswerArr.value[0]
                    scrollContainer.value.scroll(
                        (currentTicketNumber.value - 5) * 52,
                        0
                    )

                    //currentAnswerNumber.value = "";
                    if (
                        theory.value[index].result == 1 ||
                        theory.value[index].result == 0
                    ) {
                        theory.value[index].disabled = 'Y'
                    }
                } else {
                    if (
                        theory.value[index].result == 1 ||
                        theory.value[index].result == 0
                    ) {
                        currentTicketNumber.value = unAnswerArr.value[0]
                        scrollContainer.value.scroll(
                            (currentTicketNumber.value - 5) * 52,
                            0
                        )
                    } else {
                        currentTicketNumber.value += 1
                        scrollContainer.value.scroll(
                            (currentTicketNumber.value - 5) * 52,
                            0
                        )
                    }
                    currentAnswerNumber.value = ''
                    if (
                        theory.value[index].result == 1 ||
                        theory.value[index].result == 0
                    ) {
                        theory.value[index].disabled = 'Y'
                    }
                }
            } else {
                if (testTheory && userData) {
                    if (!accessProgress.value) {
                        store.dispatch("UPDATE_TIMER", { id: userData.value.id, id_video: idVideo});
                    }

                }
                resultTest.value = true;
                stopTimerForWidget.value = true;
                if (saveProgr.value) {
                    saveProgressFunc(
                        NumberFromTicket.value.number.number,
                        AnswerFalseCount.value,
                        NumberFromTicket.value.id_user
                    )
                }

                if (testExam) {
                    saveProgressTheory();
                }
            }
        }
        const skip = val => {
            let valTmp = val + 1
            if (valTmp === theory.value.length) {
                currentTicketNumber.value = unAnswerArr.value[0]
            } else {
                currentTicketNumber.value += 1
            }
        }
        const next = async () => {
            const res = await scrollContainer.value
            scrollContainer.value.scroll(res.scrollLeft + 52, 0)
        }
        const prev = async () => {
            const res = await scrollContainer.value
            scrollContainer.value.scroll(res.scrollLeft - 52, 0)
        }
        const repeatTest = async () => {
            theory.value = JSON.parse(JSON.stringify(firstArray.value))
            await unAnswerMethod();
            currentTicketNumber.value = 1;
            currentAnswerNumber.value = '';
            unAnswerArr.value = [];
            resultTest.value = false;
            startTimerForWidget.value = true;
            stopTimerForWidget.value = false;
        }
        const saveProgressFunc = async (number, falseAnswer, user) => {
            let value = {
                id_user: user,
                number: number,
                error: falseAnswer,
                flag: true,
            }
            let queryString = JSON.stringify(value)
            await axios
                .post(`${base_URL}/users/seterrorticket/`, queryString)
                .then(response => {})
                .catch(err => {
                    console.warn(err)
                })
        }

        const saveProgressTheory = async () => {
            let value = {
                id: userData.value.id,
                progress: nameSect.value.index + 1,
            }
            let queryString = JSON.stringify(value)
            await axios
                .post(`${base_URL}/users/setprofileid/`, queryString)
                .then(response => {
                    let arr = userData.value;
                    arr.progress = nameSect.value.index + 1;
                    store.dispatch("UPDATE_USER_DATA", arr);
                })
                .catch(err => {
                    console.warn(err)
                })
        }
        const addFavorite = async (val, item, index) => {
            // console.log('val', val)
            let number = [val]
            let value = { id: userData.value.id, questions: number, item: item }
            let queryString = JSON.stringify(value)
            const addFavor = () => store.dispatch('GET_ADD_FAVOR', queryString)
            addFavor()
            theory.value[index].favorite = true
        }
        const delFavorite = (question_id, index) => {
            let user_id = userData.value.id
            store.dispatch('DEL_FAVORITES', { user_id, question_id })
            theory.value[index].favorite = false
        }

        const getHrefLink = async e => {
            const commentsBlock = document.querySelector('.comments')
            if (commentsBlock !== null) {
                e.preventDefault()
                if (e.target.tagName == 'A') {
                    const hrefVal = e.target.href
                    const arr = hrefVal.split('/')
                    const val = arr[arr.length - 1]
                    if (val[0] == 'p') {
                        await store.dispatch('GET_PRAVILO', val.slice(1))
                        const res = await store.getters.PRAVILO
                        titleModal.value = res.name
                        imgModal.value = ''
                        descModal.value = res.text
                        modal.value = true
                    } else if (val[0] == 'z') {
                        await store.dispatch('GET_ZNAK', val.slice(1))
                        const res = await store.getters.ZNAK
                        titleModal.value = 'Знак ' + res.code_znak
                        imgModal.value = res.picture
                        descModal.value = res.text
                        modal.value = true
                    }
                }
            }
        }

        const app = document.querySelector('#app')
        app.addEventListener('click', getHrefLink)

        const closeModal = () => {
            modal.value = !modal.value
        }

        return {
            currentTicket,
            currentTicketNumber,
            currentAnswer,
            currentAnswerNumber,
            result,
            nextQuestion,
            repeatTest,
            theory,
            countTickets,
            unAnswerArr,
            AnswerTrueCount,
            AnswerFalseCount,
            resultTest,
            unAnswerMethod,
            AnswerCount,
            stopTimer: computed(() => store.getters.MODAL_EXAM),
            showHideComment,
            showComments,
            nameSect,
            timerWid,
            saveProgr,
            saveProgress,
            NumberFromTicket,
            addFavorite,
            scrollContainer,
            delFavorite,
            showHelpersButton,
            errorsCount,
            timer,
            next,
            prev,
            controls,
            modal,
            closeModal,
            titleModal,
            imgModal,
            descModal,
            skip,
            testExam,
            goBack,
            firstArray,
            userData,
            stopTimerForWidget,
            startTimerForWidget,
            ShowCard,
            clickHandler,
            infinitely
        }
    },
}
