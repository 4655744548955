<template>
    <div class="exam">
        <HeaderExam :name="{index: nameSect.index + 1 && nameSect.index + 1, name1 : !pageTitle ? nameSect.name1 : pageTitle, name2 : nameSect.name2, timer : timer, timerWidget : timerWidget, stopTimerForWidget: stopTimerForWidget, startTimerForWidget : startTimerForWidget}"></HeaderExam>
        <div class="exam__top">

            <div @click="prev()" class="exam__list-prev" v-if="controls">
                <svg style="transform: rotate(180deg);" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.4599 8.29002C8.2716 8.09641 8.01409 7.98554 7.74404 7.98179C7.47399 7.97804 7.21351 8.08172 7.0199 8.27002C6.82629 8.45833 6.71542 8.71583 6.71167 8.98588C6.70792 9.25593 6.8116 9.51641 6.9999 9.71002L9.3399 12L6.9999 14.29C6.90617 14.383 6.83178 14.4936 6.78101 14.6154C6.73024 14.7373 6.7041 14.868 6.7041 15C6.7041 15.132 6.73024 15.2627 6.78101 15.3846C6.83178 15.5065 6.90617 15.6171 6.9999 15.71C7.09286 15.8038 7.20346 15.8781 7.32532 15.9289C7.44718 15.9797 7.57789 16.0058 7.7099 16.0058C7.84191 16.0058 7.97262 15.9797 8.09448 15.9289C8.21634 15.8781 8.32694 15.8038 8.4199 15.71L11.4199 12.71C11.5136 12.6171 11.588 12.5065 11.6388 12.3846C11.6896 12.2627 11.7157 12.132 11.7157 12C11.7157 11.868 11.6896 11.7373 11.6388 11.6154C11.588 11.4936 11.5136 11.383 11.4199 11.29L8.4599 8.29002ZM16.9599 11.29L13.9599 8.29002C13.7716 8.10172 13.5162 7.99593 13.2499 7.99593C12.9836 7.99593 12.7282 8.10172 12.5399 8.29002C12.3516 8.47833 12.2458 8.73372 12.2458 9.00002C12.2458 9.26632 12.3516 9.52172 12.5399 9.71002L14.8399 12L12.5399 14.29C12.4462 14.383 12.3718 14.4936 12.321 14.6154C12.2702 14.7373 12.2441 14.868 12.2441 15C12.2441 15.132 12.2702 15.2627 12.321 15.3846C12.3718 15.5065 12.4462 15.6171 12.5399 15.71C12.6329 15.8038 12.7435 15.8781 12.8653 15.9289C12.9872 15.9797 13.1179 16.0058 13.2499 16.0058C13.3819 16.0058 13.5126 15.9797 13.6345 15.9289C13.7563 15.8781 13.8669 15.8038 13.9599 15.71L16.9599 12.71C17.0563 12.6197 17.1338 12.5112 17.1881 12.3908C17.2423 12.2704 17.2721 12.1404 17.2759 12.0084C17.2796 11.8763 17.2571 11.7449 17.2097 11.6216C17.1624 11.4983 17.0911 11.3856 16.9999 11.29H16.9599Z" fill="#3C6BE4"/>
                </svg>
            </div>

            <ul :class="['exam__list', {'controls': controls}]" ref="scrollContainer" v-dragscroll="true">
                <li
                    class="exam__item "
                    v-for="(item, index) in theory"
                    :key="index"
                    :class="[
                        { current: (currentTicketNumber === index + 1 && item.disabled != 'Y' ) || (currentTicketNumber === index + 1 && item.disabled == 'Y')},
                        { success: item.result == 1 && item.disabled == 'Y'},
                        { err: item.result == 0 && item.disabled == 'Y'},
                    ]"
                    @click="currentTicket(index + 1)"
                >
                    {{ index + 1 }}
                </li>
            </ul>

            <div @click="next()" class="exam__list-next" v-if="controls">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.4599 8.29002C8.2716 8.09641 8.01409 7.98554 7.74404 7.98179C7.47399 7.97804 7.21351 8.08172 7.0199 8.27002C6.82629 8.45833 6.71542 8.71583 6.71167 8.98588C6.70792 9.25593 6.8116 9.51641 6.9999 9.71002L9.3399 12L6.9999 14.29C6.90617 14.383 6.83178 14.4936 6.78101 14.6154C6.73024 14.7373 6.7041 14.868 6.7041 15C6.7041 15.132 6.73024 15.2627 6.78101 15.3846C6.83178 15.5065 6.90617 15.6171 6.9999 15.71C7.09286 15.8038 7.20346 15.8781 7.32532 15.9289C7.44718 15.9797 7.57789 16.0058 7.7099 16.0058C7.84191 16.0058 7.97262 15.9797 8.09448 15.9289C8.21634 15.8781 8.32694 15.8038 8.4199 15.71L11.4199 12.71C11.5136 12.6171 11.588 12.5065 11.6388 12.3846C11.6896 12.2627 11.7157 12.132 11.7157 12C11.7157 11.868 11.6896 11.7373 11.6388 11.6154C11.588 11.4936 11.5136 11.383 11.4199 11.29L8.4599 8.29002ZM16.9599 11.29L13.9599 8.29002C13.7716 8.10172 13.5162 7.99593 13.2499 7.99593C12.9836 7.99593 12.7282 8.10172 12.5399 8.29002C12.3516 8.47833 12.2458 8.73372 12.2458 9.00002C12.2458 9.26632 12.3516 9.52172 12.5399 9.71002L14.8399 12L12.5399 14.29C12.4462 14.383 12.3718 14.4936 12.321 14.6154C12.2702 14.7373 12.2441 14.868 12.2441 15C12.2441 15.132 12.2702 15.2627 12.321 15.3846C12.3718 15.5065 12.4462 15.6171 12.5399 15.71C12.6329 15.8038 12.7435 15.8781 12.8653 15.9289C12.9872 15.9797 13.1179 16.0058 13.2499 16.0058C13.3819 16.0058 13.5126 15.9797 13.6345 15.9289C13.7563 15.8781 13.8669 15.8038 13.9599 15.71L16.9599 12.71C17.0563 12.6197 17.1338 12.5112 17.1881 12.3908C17.2423 12.2704 17.2721 12.1404 17.2759 12.0084C17.2796 11.8763 17.2571 11.7449 17.2097 11.6216C17.1624 11.4983 17.0911 11.3856 16.9999 11.29H16.9599Z" fill="#3C6BE4"/>
                </svg>
            </div>
        </div>
        <div class="exam__body">
            <div
                class="exam__card"
                v-for="(item, index) in theory"
                v-show="index + 1 == currentTicketNumber"
                :key="index"
            >
                <img :src="item.picture" v-if="item.picture" />
                <div class="exam__answears-default-img" v-else>Вопрос без картинки</div>
                <div class="exam__question">{{ item.quest }}</div>
                <ul class="exam__answears-list">
                    <li
                        class="exam__answears-item"
                        v-for="(item2, index2) in item.answers"
                        :key="index2"
                        :class="[
                            { active: item.currentAnsewer === index2 },
                            {
                                success:
                                    item.disabled === 'Y' &&
                                    item2.correct == 'Y' &&
                                    item.currentAnsewer === index2,
                            },
                            {
                                err:
                                    item.disabled === 'Y' &&
                                    item2.correct == 'N' &&
                                    item.currentAnsewer === index2,
                            },
                        ]"
                        @click="
                            currentAnswer(index, index2, item.answers[index2])
                        "
                    >
                    <span class="exam__answears-item-check"></span>
                        {{ index2 + 1 }}. {{item2.answer}}
                        <span class="answear-success" v-if="item.disabled === 'Y' &&
                                    item2.correct == 'Y' &&
                                    item.currentAnsewer === index2">(Верный ответ)</span>
                        <span class="answear-err" v-if="item.disabled === 'Y' &&
                                    item2.correct == 'N' &&
                                    item.currentAnsewer === index2">(Вы ответили неверно)</span>
                    </li>
                </ul>

                <div class="exam__btn-wrap">
                     <span
                        class="exam__btn"
                        @click="goBack"
                        v-if="resultTest && AnswerFalseCount <= (errorsCount != '' ? errorsCount: 2) && !errorPage"
                    >
                        Продолжить
                    </span>

                    <span
                            class="exam__btn"
                            @click="clickHandler"
                            v-else-if="resultTest && errorPage"
                    >
                        Продолжить
                    </span>

                    <div
                        class="exam__btn"
                        @click="repeatTest(index)"
                        v-else-if="resultTest && AnswerFalseCount > (errorsCount != '' ? errorsCount: 2)"
                    >
                        Повторить тест
                    </div>
                    <div class="exam__btn" @click="nextQuestion(index)" v-else>
                        {{
                            unAnswerArr.length == 1
                                ? "Завершить тест"
                                : "Подтвердить"
                        }}
                    </div>
                    <div class="exam__btn exam__btn--light" @click="skip(index)" v-if="!resultTest">
                        Пропустить
                    </div>
                </div>



                <div class="exam__favorite-wrap">
                    <div class="exam__favorite" @click="addFavorite(item.id, item, index)" v-if="!item.favorite && userData">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2H8.00001C7.20436 2 6.4413 2.31607 5.87869 2.87868C5.31608 3.44129 5.00001 4.20435 5.00001 5V21C4.99931 21.1762 5.04518 21.3495 5.13299 21.5023C5.22079 21.655 5.3474 21.7819 5.50001 21.87C5.65203 21.9578 5.82447 22.004 6.00001 22.004C6.17554 22.004 6.34799 21.9578 6.50001 21.87L12 18.69L17.5 21.87C17.6524 21.9564 17.8248 22.0012 18 22C18.1752 22.0012 18.3476 21.9564 18.5 21.87C18.6526 21.7819 18.7792 21.655 18.867 21.5023C18.9548 21.3495 19.0007 21.1762 19 21V5C19 4.20435 18.6839 3.44129 18.1213 2.87868C17.5587 2.31607 16.7957 2 16 2ZM17 19.27L12.5 16.67C12.348 16.5822 12.1755 16.536 12 16.536C11.8245 16.536 11.652 16.5822 11.5 16.67L7.00001 19.27V5C7.00001 4.73478 7.10536 4.48043 7.2929 4.29289C7.48044 4.10536 7.73479 4 8.00001 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8947 4.48043 17 4.73478 17 5V19.27Z" fill="#3C6BE4"/>
                        </svg>
                        Добавить в избранное
                    </div>
                    <div class="exam__favorite" @click="delFavorite(item.id, index)" v-if="item.favorite && userData">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 2H8.00001C7.20436 2 6.4413 2.31607 5.87869 2.87868C5.31608 3.44129 5.00001 4.20435 5.00001 5V21C4.99931 21.1762 5.04518 21.3495 5.13299 21.5023C5.22079 21.655 5.3474 21.7819 5.50001 21.87C5.65203 21.9578 5.82447 22.004 6.00001 22.004C6.17554 22.004 6.34799 21.9578 6.50001 21.87L12 18.69L17.5 21.87C17.6524 21.9564 17.8248 22.0012 18 22C18.1752 22.0012 18.3476 21.9564 18.5 21.87C18.6526 21.7819 18.7792 21.655 18.867 21.5023C18.9548 21.3495 19.0007 21.1762 19 21V5C19 4.20435 18.6839 3.44129 18.1213 2.87868C17.5587 2.31607 16.7957 2 16 2ZM17 19.27L12.5 16.67C12.348 16.5822 12.1755 16.536 12 16.536C11.8245 16.536 11.652 16.5822 11.5 16.67L7.00001 19.27V5C7.00001 4.73478 7.10536 4.48043 7.2929 4.29289C7.48044 4.10536 7.73479 4 8.00001 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8947 4.48043 17 4.73478 17 5V19.27Z" fill="#3C6BE4"/>
                            <path d="M6 3H18V19.5L12 18L6 20V3Z" fill="#3C6BE4"/>
                        </svg>
                        Удалить из избранного
                    </div>

                    <div class="exam__showHideComments" @click="showHideComment" v-if="showHelpersButton == true || showHelpersButton === 'true'">
                        <svg v-if="!showComments" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003L17.94 17.94Z" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1 1L23 23" stroke="#3C6BE4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <span v-if="!showComments">Показать комментарии</span>
                        <span v-else>Скрыть комментарии</span>
                    </div>
                </div>

            </div>
			<div class="col">
				<div v-if="resultTest && !errorPage" class="exam__result" :class="{ 'success': AnswerFalseCount <= (errorsCount != '' ? errorsCount: 2) }">
					<div class="exam__result-info">
						<div class="exam__result-lesson">
							<span>Тест по уроку</span>
							<br>
							{{!pageTitle ? nameSect.name1 : pageTitle}}
							<br>
							{{nameSect.name2}}
						</div>
<!--						<div class="exam__result-time">-->
<!--							<p>Затраченое время:</p>-->
<!--							<span>0:09:16</span>-->
<!--						</div>-->
						<div class="exam__result-err">
							<p>Количество ошибок:</p>
							<span>{{AnswerFalseCount}}</span>
						</div>
					</div>
					<div class="exam__result-right">
<!--						<div class="exam__result-ava">-->
<!--							<img :src="userData.photo" class="user-card__img" v-if="userData.photo">-->
<!--							<div class="user-card__img-wrap" v-else>-->
<!--								{{userData.name && userData.name[0]}}{{userData.last_name && userData.last_name[0]}}-->
<!--							</div>-->
<!--						</div>-->
						<span v-if="AnswerFalseCount > (errorsCount != '' ? errorsCount: 2)">Не сдан</span>
						<span v-else>Сдан!</span>
					</div>

				</div>

				<div v-for="(item, index) in theory" :key="index" v-show="(index+1) == currentTicketNumber">
                <Comments @onHandler="closeModal" :modal="modal" :description1="item.description1" :description2="item.description2" :titleModal="titleModal" :imgModal="imgModal" :descModal="descModal" v-if="showComments"></Comments>
				</div>
			</div>
        </div>
    </div>

    <ModalExamTest v-if="stopTimer"></ModalExamTest>

</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
