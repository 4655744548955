<template>
    <button
        :class="['playpause', statusProps ? 'icon-pause' : 'icon-play']"
        @click="playPause"
    >
    <svg v-show="!statusProps" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"/></svg>
    <svg v-show="statusProps" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>
    </button>
</template>

<script>
import { ref, watch } from "vue";
export default {
    name: "VideoPlayPause",
    emits: ["onHandler"],
    props: {
        status: Boolean,
    },
    setup(props, { emit }) {
        const statusProps = ref(false);

        const playPause = () => {
            emit("onHandler");
        };

        watch(
            () => props.status,
            () => {
                statusProps.value = props.status;
            }
        );

        return {
            playPause,
            statusProps,
        };
    },
};
</script>

<style lang="scss">
.playpause {
    color: #fff;
    background: transparent;
    border: none;
    cursor: pointer;
}
</style>
