import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Preloader from './../Preloader/Preloader.vue'

export default {
    name: 'ExamWidget',
    components: { Preloader },
    setup() {
        const store = useStore()

        const hard_question_count = ref(0)
        const preloaderActive = ref(true)

        onMounted(async () => {
            await store.dispatch('GET_HARD_QUESTIONS')
            const res = await store.getters.HARD_TEST
            hard_question_count.value = await res.length
            preloaderActive.value = false
        })

        return {
            hard_question_count,
            preloaderActive,
        }
    },
}
