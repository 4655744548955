import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import ExamBlock from "../../components/ExamTest/ExamTest";
import {useStore} from "vuex";
import {ref} from "@vue/reactivity";
import {onMounted} from "@vue/runtime-core";
import router from "../../router";

export default {
	name: 'ExamCustom',
	components: {
		Aside,
		Header,
		DownloadPage,
		ExamBlock,
    },
	props : {
		hh : {
			type : String
		},
		question : {
			type : String,
		},
		errors : {
			type : String
		},
		mixedAnswered : {
			type : String
		},
		showHelpers : {
			type : String
		}
	},
	setup({hh}) {
		const params = hh ? ref(JSON.parse(hh)) : '';
		if (!params.value) {
			router.push({
				name: 'Home',
			})
			setTimeout(() => {
				location.reload();
			})
		}
		const store = useStore();
		const customTemp = ref("");
		const custom = ref("");
		const errors = params.value.errors;
		const mixedAnswered = params.value.mixedAnswered;
		const showHelpers = params.value.showHelpers;
		const timer = params.value.timer;

		onMounted(async () => {
			customTemp.value = await store.getters.HARD_TEST;
			custom.value = customTemp.value
				.sort(function() { return .5 - Math.random() }) // Shuffle array
				.slice(0, params.value.question); //


			 if (params.value.mixedAnswered) {
				custom.value.forEach(function(item, i, arr) {
					let newAnswers = item.answers.map(i=>[Math.random(), i]).sort().map(i=>i[1])
					custom.value[i].answers = newAnswers;
				});
			 }
		}
		);
		return {
			custom,
			errors,
			mixedAnswered,
			showHelpers,
			timer
		};
	},

  }