<template>
	<div class="modal">
		<div class="modal__content">
			<div class="modal__header">
				<div class="modal__title">Удаление профлия</div>
				<div class="modal__close" @click="toggleDeleteProfileModal"></div>
			</div>
			<div class="modal__body">
				<div class="modal__body--text">Вы&nbsp;действительно хотите удалить профиль?</div>
				<div>Весь сохраненный прогресс и&nbsp;данные пользователя будут удалены!</div>
			</div>
			<div class="modal__footer">
				<div class="modal__btn modal__btn--red modal__btn--big" @click="deleteProfile(userData.id)">Удалить</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss" scoped>
	@import './styles.scss';
</style>

<script src="./index.js"></script>
