import { ref, onMounted } from 'vue'

export default {
	name: 'DownloadPage',
	setup () {
		const device = ref('')

		onMounted (() => {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				device.value = 'ios'
			} else if (/Android/i.test(navigator.userAgent)) {
				device.value = 'android'
			}
		})

		return { device }
	}
}