<template>
    <div class="tickets page">
        <Aside></Aside>
        <div class="container">
            <Header></Header>
            <div class="content">
                <div class="col">
                    <Tab :exam="exam"></Tab>
                </div>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
