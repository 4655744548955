<template>
    <div class="video-timer">
        {{ durationCurrentVideo }} / {{ durationVideo }}
    </div>
</template>

<script>
export default {
    name: "VideoTimer",
    props: {
        durationVideo: {
            type: String,
        },
        durationCurrentVideo: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
.video-timer {
    color: #fff;
}
</style>
