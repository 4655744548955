<template>
    <ul class="theoryList-list">
        <li v-if="theoryList.length === 0">
            <Preloader></Preloader>
        </li>
        <li
          class="theoryList-list__item"
          :class="userId == null && index < 1 && 'active' || userId !== null && userData.free_access && 'active' || userId !== null && parseInt(userData.progress) + 1 >= index && 'active'"
          v-for="(item, index) in theoryList"
          :key="item.id"
          @click="userId == null && index < 1 && selectTheory({ index, ...item }) || userId !== null && userData.free_access && selectTheory({ index, ...item }) || parseInt(userData.progress) + 1 >= index && selectTheory({ index, ...item })"
          v-else
        >

        <!-- :class="[{nonReg: currentUserDate}, {}]" -->
			<!-- {{ userId !== null && accessLesson ? 'true' : index > 2 ? 'false' : 'true'}} -->
            <img
                class="theoryList-list__img-prev"
                :src="
                    require('@/assets/preview/preview' + (index + 1) + '.svg')
                "
                alt="preview"
            />
            <div class="theoryList-list__item-info">
                <div class="theoryList-list__smallText">{{ item.name2 }}</div>
                <div class="theoryList-list__boldText">
                    {{ index + 1 }}. {{ item.name1 }}
                </div>
            </div>
            <div class="theoryList-list__time">
                {{ tranformTime(item.video.duration) }}
            </div>
        </li>
    </ul>
</template>

<script>
import { onMounted, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import Preloader from "./Preloader/Preloader.vue";
export default {
  name: "TheoryLastWidget",

  components: {
    Preloader,
  },

  setup(props, { emit }) {
    const store = useStore();
		const userId = ref(false)
		const accessLesson = ref(false)
    const userData = reactive(computed(() => store.getters.userInfo))

    // Перевод милисекунд в читаемое время
    const tranformTime = (val) => {
      let seconds = val;
      let minutes = Math.floor(seconds / 60);
      let hours = "00";

      if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        hours = hours >= 10 ? hours : "00" + hours;
        minutes = minutes - hours * 60;
        minutes = minutes >= 10 ? minutes : "ss" + minutes;
      }

      seconds = Math.floor(seconds % 60);
      seconds = seconds >= 10 ? seconds : "0" + seconds;
      minutes = minutes >= 10 ? minutes : "0" + minutes;

      return minutes + ":" + seconds;
    };

    // Переходим в деталку
    const selectTheory = (val) => {
        emit("onHandler", val);
    };

		onMounted( async () => {
			userId.value = await localStorage.getItem("userData")
		})

    return {
      Preloader,
      tranformTime,
      selectTheory,
			accessLesson,
			userId,
      userData,
      theoryList: computed(() => store.getters.THEORY_LIST),
      currentUserDate : computed(() => store.getters.userInfo),
    };
  },
};
</script>

<style lang="scss">
.theoryList-list {
    max-height: 300px;
    min-height: 200px;
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    @media (max-width: 1500px) {
        height: calc(100vh - 450px);
        max-height: none;
    }
    & * {
        user-select: none;
    }
    &__item {
        width: 100%;
        min-height: 85px;
        border-radius: 16px;
        background: #fff;
        margin-bottom: 10px;
        border: 1px solid #3c6be4;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding: 10px 20px 10px 10px;
        transition: all 0.3s;
        opacity: 0.3;
        cursor: default;
        &:hover {
            background: rgba(60, 107, 228, 0.07%);
        }
        &.active {
            opacity: 1;
            cursor: pointer;
            &:hover {
                background: rgba(60, 107, 228, 0.07%) !important;
            }
        }
    }
    &__img-prev {
        width: 110px;
        max-height: 90px;
    }
    &__item-info {
        margin-left: 20px;
    }
    &__boldText {
        font-weight: 600;
        font-size: 16px;
        color: #000;
    }
    &__smallText {
        font-weight: 400;
        font-size: 14px;
        color: #2e3c50;
        opacity: 0.5;
        margin-bottom: 5px;
    }
    &__time {
        font-weight: 400;
        font-size: 14px;
        color: #2e3c50;
        opacity: 0.5;
        margin-left: auto;
        align-self: flex-start;
        margin-top: 26px;
    }
}
</style>
