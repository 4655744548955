<template>
  <div class="driving page">
	<Aside></Aside>
	<div class="container">
		<div class="content">
			<Preloader v-if="!MaraphoneTest.length"></Preloader>
			<ExamBlock :item="MaraphoneTest" v-if="MaraphoneTest" pageTitle="Марафон" :showHelpers="true" :infinitely="true"></ExamBlock>
		</div>
	</div>
  </div>
<DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>

