<template>
  <div class="custom-select" :class="{ active: active }">
    <div class="custom-select__label" @click="toggle">{{ selectedData ? selectedData.label : defaultData }}</div>
    <ul class="custom-select__list">
      <li class="custom-select__item" @click="select(item)" v-for="item in data" :key="item.id">{{ item.label }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  data() {
    return {
      active: false
    }
  },
  props: ['data', 'selectedData', 'defaultData'],
  emits: ['select'],
  methods: {
    toggle() {
      this.active = !this.active
    },
    select(val) {
      this.active = false
      this.$emit('select', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-select {
    position: relative;
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.14);
    padding: 0 15px 0 10px;
    width: 100%;
    height: 30px;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
    &.active {
      &::after {
        transform: translateY(-50%) rotate(-135deg);
      }
      .custom-select__list {
        display: block;
      }
    }
    &::after {
      content: '';
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 5px;
      height: 5px;
      border-bottom: 1px solid #3C6BE4;
      border-right: 1px solid #3C6BE4;
    }
    &__label {
      width: 100%;
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    &__list {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      background: #fff;
      width: 100%;
      z-index: 1;
      margin: 0;
      list-style: none;
      padding: 5px;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, .4);
    }
    &__item {
      padding: 8px 15px;
      cursor: pointer;

      &:hover {
        background: #3C6BE4;
        color: #fff;
      }
    }
  }
</style>
