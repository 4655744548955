import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { onMounted } from '@vue/runtime-core'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ExamBlock from '../../components/ExamTest/ExamTest.vue'

export default {
    name: 'TicketsExam',
    components: {
        Aside,
        Header,
        DownloadPage,
        ExamBlock,
    },
    props: {
        item: {
            type: String,
        },
    },
    setup({ item, ticketsNumber }) {
        const store = useStore()
        const getTicketsNumber = ref(1)
        const Tickets = ref('')
        const getTickets = ref([])

        onMounted(async () => {
          const obj = JSON.parse(item)
            const ticketsNumber = () =>
                store.dispatch('GET_TICKET_NUMBER', {number: obj.number.number, id_user: obj.id_user})
            await ticketsNumber()
            getTickets.value = await store.getters.TICKET_NUMBER
            Tickets.value = getTickets.value[0].concat(
                getTickets.value[1],
                getTickets.value[2],
                getTickets.value[3],
                getTickets.value[4]
            )
            Tickets.value.pop()
            const itemParse = JSON.parse(item)
            getTicketsNumber.value = itemParse.number.number
        })
        return {
            Tickets,
            getTickets,
            getTicketsNumber,
        }
    },
}
