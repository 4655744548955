import { ref, onMounted, computed,watch,reactive} from 'vue'
export default {
	name: 'TicketsWidget',
    props: {
        statistic: Object
    },
    setup(props, {attrs,emit}) {
        const statistic = reactive({});
        onMounted(() => {
            statistic.value = props.statistic;
        });



	    return {
        }
    }

  }