import Aside from '../../components/Aside/Aside.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { onMounted } from '@vue/runtime-core'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ExamBlock from '../../components/ExamTest/ExamTest.vue'

export default {
    name: 'ExamDifficult',
    components: {
        Aside,
        HeaderExam,
        DownloadPage,
        ExamBlock,
    },
    setup() {
        const store = useStore()
        const hardTest = ref('')

        onMounted(async () => {
            hardTest.value = await store.getters.HARD_TEST
        })
        return {
            hardTest,
        }
    },
}
