import axios from "axios";
import { base_URL } from "./../../constants";

export default {
    state: {
        lastTheory: {},
        theoryList: [],
    },
    getters: {
        LAST_THEORY: (state) => {
            return state.lastTheory;
        },
        THEORY_LIST: (state) => {
            return state.theoryList;
        },
    },
    mutations: {
        SET_LAST_THEORY: (state, payload) => {
            state.lastTheory = payload;
        },
        SET_THEORY_LIST: (state, payload) => {
            state.theoryList = payload;
        },
    },
    actions: {
        GET_LAST_THEORY: async ({ commit }) => {
            const lastVideo = await localStorage.getItem("lastTheory");
            await axios.get(`${base_URL}/theory/gettheorylist/`).then((res) => {
                if (lastVideo !== null) {
                    const lastVideoObject = JSON.parse(lastVideo)
                    commit("SET_LAST_THEORY", lastVideoObject);
                } else {
                    const query = {
                        index: 0,
                        ...res.data.result[0],
                    };
                    localStorage.setItem("lastTheory", JSON.stringify(query));
                    commit("SET_LAST_THEORY", query);
                }
            });
        },

        UPDATE_LAST_THEORY: async ({ commit }, payload) => {
            localStorage.setItem("lastTheory", JSON.stringify(payload));
            commit("SET_LAST_THEORY", payload);
        },

        GET_THEORY_LIST: async ({ commit }) => {
            await axios.get(`${base_URL}/theory/gettheorylist/`).then((res) => {
                commit("SET_THEORY_LIST", res.data.result);
            });
        },
    },
};
