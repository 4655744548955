<template>
    <div class="theory-prev">
        Предыдущий урок
    </div>
</template>

<script>
export default {
    name: "TheoryPrev",
    props: {
        data: {
            type: Object,
        },
    },
    setup({ data }) {},
};
</script>

<style lang="scss">
.theory-prev {
    width: 305px;
    height: 64px;
    background: #ffffff;
    box-shadow: 32px 32px 62px rgba(228, 216, 216, 0.6);
    border-radius: 8px;
    padding: 24px 40px;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    margin-right: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    span {
        display: inline-block;
        margin-top: 15px;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
    }
}
</style>
