<template>
	<div class="user-card" @click="hideListCities">
		<Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
			<div class="form__ava" >
				<img :src="userData.photo" class="user-card__img" v-if="userData.photo">
				<div class="form__ava-btn" @click="clickPencil">
					<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path   d="M13.1718 3.23582L10.889 0.941371C10.7382 0.791285 10.534 0.707031 10.3213 0.707031C10.1085 0.707031 9.90432 0.791285 9.75348 0.941371L1.6607 9.02248L0.921811 12.2114C0.896322 12.3279 0.897195 12.4487 0.924368 12.5649C0.951541 12.6811 1.00433 12.7898 1.07887 12.8829C1.15341 12.9761 1.24783 13.0515 1.35522 13.1035C1.46261 13.1555 1.58027 13.1829 1.69959 13.1836C1.75519 13.1892 1.81121 13.1892 1.86681 13.1836L5.0907 12.4447L13.1718 4.37137C13.3219 4.22052 13.4061 4.01639 13.4061 3.80359C13.4061 3.5908 13.3219 3.38666 13.1718 3.23582ZM4.70181 11.7447L1.68014 12.3786L2.36848 9.41526L8.42348 3.38359L10.7568 5.71693L4.70181 11.7447ZM11.2779 5.15304L8.94459 2.8197L10.2979 1.47415L12.5924 3.80748L11.2779 5.15304Z" fill="#3C6BE4"/>
					</svg>
				</div>
				<input type="file" v-show="false" id="fileUploadPicture" @change="activeModalEditPhoto" />
			</div>
			<div class="form__group">
				<label class="form__label">Имя</label>
				<Field name="name" type="text" class="form__input" v-model="nameUser" :class="{ 'form__err': errors.name }" />
				<div class="form__err-text">{{errors.name}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Фамилия </label>
				<Field name="last_name" type="text" class="form__input" v-model="lastNameUser" :class="{ 'form__err': errors.last_name }" />
				<div class="form__err-text">{{errors.last_name}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">E-mail </label>
				<Field name="email" type="email" class="form__input" v-model="email" :class="{ 'form__err': errors.email }" />
				<div class="form__err-text">{{errors.email}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Телефон</label>
				<Field name="phone" type="phone" class="form__input" pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}" v-model="phone"  placeholder="(555) 555-5555"   v-phone :class="{ 'form__err': errors.phone }" />
				<div class="form__err-text">{{errors.phone}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Город</label>
				<Field name="city" type="text" class="form__input" :class="{ 'form__err': errors.city }" v-model="city" @click="activeCityListToggle"/>
				<ul class="cityList" v-if="activeCityList">
					<li class="cityItem" v-for="item in filterCity.slice(0,5)" :key="item.city" @click="selectCity(item.city)">
						{{ item.city }}
					</li>
				</ul>
				<div class="form__err-text">{{errors.city}}</div>
			</div>
			<div class="form__group">
				<label class="form__label">Область</label>
				<Field name="region" type="text" class="form__input" :class="{ 'form__err': errors.region }" v-model="region" @click="activeRegionListToggle" />
				<ul class="cityList" v-if="activeRegionList">
					<li class="cityItem" v-for="item in filterRegion.slice(0,5)" :key="item" @click="selectRegion(item)">
						{{ item }}
					</li>
				</ul>
				<div class="form__err-text">{{errors.region}}</div>
			</div>
			<div class="social-edit">
				<div class="social-edit__title">Мессенджеры для связи</div>
				<div class="social-edit__item">
					Viber
					<div class="social-edit__checkbox">
						<input type="checkbox" v-model="viber">
						<div class="social-edit__custon-checkbox"></div>
					</div>
				</div>
				<div class="social-edit__item">
					Whatsapp
					<div class="social-edit__checkbox">
						<input type="checkbox" v-model="whatsapp">
						<div class="social-edit__custon-checkbox"></div>
					</div>
				</div>
			</div>
			<button class="form__button" type="submit">Сохранить</button>
		</Form>
	</div>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>