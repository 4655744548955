<template>
	<div class="shedule page">
		<Aside></Aside>
		<div class="container">
			<Header></Header>
			<div class="content">
				<div class="shedule__instructor">
					<div class="shedule__title">Вождение</div>
					<div class="shedule__description">Ваш инструктор</div>
					<div class="shedule__info">
						<div class="shedule__photo">
							<img src="https://api.avtoshkola.pw/api/upload/main/5c9/tmp.png" alt="">
						</div>
						<div class="shedule__info-instructor">
							<div class="shedule__name">{{instructor.name}}</div>
							<div class="shedule__name">{{instructor.second_name}}</div>
							<div class="shedule__car">{{instructor.car}}</div>
						</div>
						<div class="shedule__msg" @click="contactsActiveHandler">
							<svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5604 20.5413C9.86526 20.4739 9.45252 20.5383 8.74002 20.7587C7.88636 21.0227 6.56731 22.1591 4.76136 22.1591C4.65996 22.1591 6.46591 20.7033 6.46591 19.6023L6.46591 19.2109C2.88688 17.3869 0.5 14.1093 0.5 10.3693C0.5 4.6425 6.09644 0 13 0C19.9036 0 25.5 4.6425 25.5 10.3693C25.5 16.0961 19.9036 20.7386 13 20.7386C12.1652 20.7386 11.3494 20.6707 10.5604 20.5413H10.5604Z" fill="#ffffff"/>
							</svg>
						</div>
					</div>
					<div class="shedule-contacts" v-if="contactsActive">
						<div class="shedule-contacts__title">Связаться</div>

						<div class="shedule-contacts__description">
							Выберите способ связи <br /> с инструктором
						</div>
						<a :href="'tel:+' + instructor.phone" class="shedule-contacts__link phone">{{instructor.phone}}</a>
						<a :href="instructor.whatsapp" class="shedule-contacts__link" v-if="instructor.whatsapp">Whatsapp</a>
						<a :href="instructor.viber" class="shedule-contacts__link" v-if="instructor.viber">Viber</a>
					</div>
				</div>
				<ul class="shedule__list">
					<li class="shedule__item" v-if="schedule.length == 0">
						<div class="shedule__near">Ближайшее занятие не назначено</div>
					</li>
					<li class="shedule__item" v-for="(itemSchedule,key) in schedule">
						<div class="shedule__near" v-if="key == 0">Ближайшее занятие по вождению:</div>
						<div class="shedule__date">{{itemSchedule.date}} {{itemSchedule.time}}</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<DownloadPage></DownloadPage>
</template>

<style lang="scss">
	@import './styles.scss';
</style>
<script src="./index.js"></script>

