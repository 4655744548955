import axios from "axios";
import { base_URL } from "./../../constants";
import router from "./../../router/index.js";
import { useRouter } from "vue-router";

export default {
    state: {
        UserInfo: "",
    },
    mutations: {
        updatePosts(state, UserInfo) {
            state.UserInfo = UserInfo;
        },
        SET_USER_DATA: (state, payload) => {
            state.UserInfo = payload;
        },
        SET_USER_IMAGE: (state, photo) => {
            state.UserInfo["photo"] = photo;
        },
        SET_USER_INSTRUCTOR: (state, flag) => {
            state.UserInfo["selected_instructor"] = flag;
        },
    },
    actions: {
        SET_USER_PHOTO({ commit }, image) {
            commit("SET_USER_IMAGE", image);
        },
        UPDATE_USER_DATA({ commit }, data) {
            commit("SET_USER_DATA", data);
        },
        SET_USER_INSTRUCTOR({ commit }, flag) {
            commit("SET_USER_INSTRUCTOR", flag);
        },
        AUTH_CHECK({ commit, dispatch }, value) {
            let queryString = JSON.stringify(value);
            axios
                .post(`${base_URL}/users/authorizeemail/`, queryString)
                .then((response) => {
                    if (response["status"] == 200) {
                        //получаем данные пользователя
                        var serialObj = JSON.stringify(response.data["id"]);
                        localStorage.setItem("userData", serialObj);
                        dispatch("GET_PROFILE_ID", response.data["id"]);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        },
        GET_PROFILE_ID({ commit }, id) {
            let queryStringId = JSON.stringify({ id });
            axios
                .post(`${base_URL}/users/getprofileid/`, queryStringId)
                .then((response) => {
                    if (response["status"] == 200) {
                        commit("updatePosts", response.data);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        },
        CLEAR_USER_DATA: ({ commit }) => {
            commit("SET_USER_DATA", "");
            router.push({
                name: "Home",
            });
        },

        DELETE_PROFILE({ commit, dispatch }, id) {
          axios
            .post(`${base_URL}/users/delete-cadets/`, { delete_id: id })
            .then((response) => {
              if (response["data"][0]) {
                localStorage.removeItem('userData')
                dispatch("CLEAR_USER_DATA")
              }
            })
        }
    },
    getters: {
        userInfo(state) {
            return state.UserInfo;
        },
    },
};
