<template>
    <router-link
        class="theory-test"
        :to="{
            name: 'ExamTest',
            params: {
                item: JSON.stringify(theoryData),
                showHelpers : true,
                testTheory : true,
                idVideo : theoryData.id
            },
        }"
    >
        Пройти тест
    </router-link>

</template>

<script>
import { watch, ref } from "vue";
export default {
    name: "TheoryNext",
    props: {
        theoryData: {
            type: Object,
        },
        accessProgress: {
            type: Boolean,
        },
    },
    setup(props) {
        const propsAccess = ref(false);
        watch(
            () => props.accessProgress,
            () => {
                propsAccess.value = props.accessProgress;
            }
        );
        return { propsAccess };
    },
};
</script>

<style lang="scss">
.theory-test {
    color: #fbfbfd;
    font-weight: 500;
    font-size: 20px;
    background: #3c6be4;
    border-radius: 8px;
    height: 64px;
    width: 305px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    margin-left: auto;
    justify-self: flex-end;
    &--disabled {
        background: #c1c1c1;
        cursor: default;
        box-shadow: none;
        user-select: none;
    }
}
</style>
