import Aside from '../../components/Aside/Aside.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'

export default {
    name: 'ExamGIBDDList',
    components: {
        Aside,
        HeaderExam,
        DownloadPage,
    },
}
