<template>
  <div class="regDescription">
    <p class="regDescription__text">Первый урок программы доступен без регистрации</p>
    <p class="regDescription__text">После регистрации вам будет доступно решение билетов в&nbsp;вкладке Свой экзамен, Экзамен ГИБДД</p>
    <p class="regDescription__text">Второй урок откроется после регистрации на&nbsp;обучение в&nbsp;автошколе</p>
  </div>
</template>

<script>
export default {
  name: 'RegDescription'
}
</script>

<style lang="scss">
  .regDescription {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 560px;
    font-size: 24px;
    line-height: 26px;

    &__text {
      margin: 0;
      text-align: center;
    }
  }
</style>
