<template>
  <div class="driving page">
	<Aside></Aside>
	<div class="container">
		<div class="content">
			<ExamBlock :item="hardTest" v-if="hardTest" pageTitle="Самые трудные" :showHelpers="true" :infinitely="true"></ExamBlock>
		</div>
	</div>
  </div>
<DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>

