<template>
    <div class="driving page">
        <Aside></Aside>
        <div class="container">

            <div class="content">
                <Preloader v-if="!theory.length"></Preloader>
                <ExamBlock
                    :item="theory"
                    v-if="theory"
                    :nameSection="item"
                    :showHelpers="showHelpers"
                    :testTheory="testTheory"
                    :testExam="testExam"
                    :idVideo="idVideo"
                    :errors="errorCount"
                ></ExamBlock>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>
<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
