<template>
    <div class="home page">
        <Aside></Aside>
        <div class="container">
            <Header></Header>

            <div class="content">
                <div class="col">
					<TheoryList></TheoryList>
                </div>
                <div class="col">
                    <DriveWidget
                        :drivingRecord="drivingRecord"
                        :user="user"
                    ></DriveWidget>
                    <ExamWidget></ExamWidget>
                </div>
                <div class="col">
                    <TicketsWidget :statistic="statistic"></TicketsWidget>
                    <StatisticWidget :statistic="statistic"></StatisticWidget>
                </div>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<style lang="scss">
@import "./styles.scss";
</style>

<script src="./index.js"></script>
