<template>
    <div class="user-card">
        <div class="user-card__header">
            <img
                :src="userData.photo"
                class="user-card__img"
                v-if="userData.photo"
            />
            <div class="user-card__img-wrap" v-else>
                {{ userData.name && userData.name[0]
                }}{{ userData.last_name && userData.last_name[0] }}
            </div>
            <div class="user-card__name">
                {{ userData.name }} <br />
                {{ userData.last_name }}
            </div>
        </div>
        <div class="user-card__info">
            <span class="user-card__link">{{ userData.email }}</span>
            <span class="user-card__link">{{ userData.phone }}</span>
            <span href="#" class="user-card__link">
                {{ userData.region }}, г. {{ userData.city }}
            </span>
        </div>
        <div
            class="user-card__socials"
            v-if="userData.whatsapp !== null || userData.viber !== null"
        >
            Мессенджеры для связи
            {{ userData.viber }}
            <ul class="user-card__socials-list">
                <li
                    class="user-card__socials-item"
                    v-if="userData.whatsapp !== null"
                >
                    <img src="./../../assets/img/whatsapp.svg" />
                </li>
                <li
                    class="user-card__socials-item"
                    v-if="userData.viber !== null"
                >
                    <img src="./../../assets/img/viber.svg" />
                </li>
            </ul>
        </div>
        <div class="user-card__btn user-card__change-btn" @click="toggleEdit">Изменить</div>

        <div class="user-card__btn user-card__delete-btn" @click="showDeleteModal">Удалить профиль</div>
    </div>
</template>
<style lang="scss" scoped>
@import "./styles.scss";
</style>

<script src="./index.js"></script>
