<template>
  <div class="wait page">
	<Aside></Aside>
	<div class="container">
		<Header></Header>
		<div class="content">
			<div class="wait__title">Ожидайте подтверждения от<br>инструктора</div>
			<svg class="wait__img" width="47" height="79" viewBox="0 0 47 79" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M27.4163 16H19.583V19.9167C19.583 20.9554 19.9957 21.9517 20.7302 22.6862C21.4647 23.4207 22.4609 23.8333 23.4997 23.8333C24.5384 23.8333 25.5346 23.4207 26.2691 22.6862C27.0037 21.9517 27.4163 20.9554 27.4163 19.9167V16Z" fill="#3C6BE4"/>
				<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.333496V8.16683H3.91667V19.9168C3.91667 25.1107 5.9799 30.0918 9.65249 33.7643C13.3251 37.4369 18.3062 39.5002 23.5 39.5002C18.3062 39.5002 13.3251 41.5634 9.65249 45.236C5.9799 48.9086 3.91667 53.8897 3.91667 59.0835V70.8335H0V78.6669H47V70.8335H43.0833V59.0835C43.0833 53.8897 41.0201 48.9086 37.3475 45.236C33.6749 41.5634 28.6938 39.5002 23.5 39.5002C26.0717 39.5002 28.6183 38.9936 30.9942 38.0095C33.3702 37.0253 35.529 35.5828 37.3475 33.7643C39.166 31.9459 40.6085 29.787 41.5926 27.4111C42.5768 25.0351 43.0833 22.4886 43.0833 19.9168V8.16683H47V0.333496H0ZM11.75 8.16683H35.25V19.9168C35.25 23.0331 34.0121 26.0218 31.8085 28.2253C29.605 30.4289 26.6163 31.6668 23.5 31.6668C20.3837 31.6668 17.395 30.4289 15.1915 28.2253C12.9879 26.0218 11.75 23.0331 11.75 19.9168V8.16683ZM11.75 59.0835V70.8335H35.25V59.0835C35.25 55.9672 34.0121 52.9786 31.8085 50.775C29.605 48.5715 26.6163 47.3335 23.5 47.3335C20.3837 47.3335 17.395 48.5715 15.1915 50.775C12.9879 52.9786 11.75 55.9672 11.75 59.0835Z" fill="#3C6BE4"/>
			</svg>
			<div class="wait__description">
				В ближайшее время он свяжется <br>
				с вами или вы можете связаться с ним сами
			</div>
			<div class="wait__btn" @click="contact">Связаться</div>
			<div class="wait__btn wait__btn--transparent" @click="changeInstructor">Сменить инструктора</div>

			<div class="modal" v-if="modalContacts">
				<div class="modal__content wait__modal-content">
					<div class="modal__header">
						<div class="modal__title">Связаться с инструктором</div>
						<div class="modal__close" @click="contact"></div>
					</div>
					<div class="modal__body">
						<p>Выберите способ связи с инструктором</p>
						<div class="wait__contacts">
							<a :href="'tel:+' + userData.phone" v-if="userData.phone">{{userData.phone}}</a>
							<a :href="userData.whatsapp"  v-if="userData.whatsapp">Whatsapp</a>
							<a :href="userData.viber"  v-if="userData.viber">Viber</a>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
  </div>
  <DownloadPage></DownloadPage>
</template>

<style lang="scss">
	@import './styles.scss';
</style>
<script src="./index.js"></script>
