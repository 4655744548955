import { ref, onMounted, reactive, computed } from 'vue'
import TicketsTab from './../../components/TcketsTab/TcketsTab.vue'
import ThemeTab from './../../components/ThemeTab/ThemeTab.vue'
import ExamTab from './../../components/ExamTab/ExamTab.vue'
import { useStore } from "vuex";

export default {
	name: 'Tab',
	components: {
		TicketsTab,
		ThemeTab,
		ExamTab
    },
	props: {
		exam: {
			type: String,
		},
	},
	setup({exam}){
		const store = useStore()
		const examTab = exam ? JSON.parse(exam) : ''
		const activeTab = ref('1')
		const userData = reactive(computed(() => store.getters.userInfo))




		const activeTabHandler = val => {
			activeTab.value = val
		}
		onMounted (()=>{
			if (examTab) {
				activeTab.value = !userData.value ? 2 : 3;
			} else {
				activeTab.value = !userData.value ? 2 : 1;
			}
		})

		return {
			activeTab,
			 activeTabHandler
		}
	}
  }
