<template>
    <div class="theoryList-main__wrap">
        <Preloader v-if="preloader"></Preloader>
        <div class="theoryList-main">
            <img
                alt="theory"
                :src="videoPreview"
                class="theoryList-main__img"
            />
        </div>
        <div class="theoryList-main__info">
            <div class="theoryList-main__title">
                Занятие по теории
                <div class="theoryList-main__subtitle">
                   {{ lastTheoryName }}

                </div>
            </div>
            <span
                class="theoryList-main__play"
                @click="selectTheory(lastTheory.value)"
            >
                Пуск
                <svg
                    width="12"
                    height="14"
                    viewBox="0 0 12 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.9165 1.75L10.0832 7L1.9165 12.25V1.75Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </span>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from "vue";
import { useStore } from "vuex";
import Preloader from "./Preloader/Preloader.vue";

export default {
    name: "LastVideoWidget",
    components: {
        Preloader,
    },
    setup(props, { emit }) {
        const store = useStore();

        const preloader = ref(true);

        const lastTheory = reactive({});
        const videoPreview = ref("");
        const lastTheoryName = ref("");

        // Переходим в деталку
        const selectTheory = (val) => {
            emit("onHandler", val);
        };

        const getLastTheory = () => store.dispatch("GET_LAST_THEORY");

        onMounted(async () => {
            const userData = await localStorage.getItem("userData");

            if (userData) {
                // Сохраняем последний урок в стор
                await getLastTheory();

                lastTheory.value = await computed(() => store.getters.LAST_THEORY)

                // Получаем ссылку на превью
                videoPreview.value = require("@/assets/preview/preview" +
                    (lastTheory.value.index + 1) +
                    ".svg");

                //Название видео
                lastTheoryName.value = (lastTheory.value.index + 1) + ' ' + lastTheory.value.name1


             } else {
                 // Сохраняем последний урок в стор
                 await getLastTheory();
                 lastTheory.value = await { index: 0, ...store.getters.THEORY_LIST[0] }
                 videoPreview.value = require("@/assets/preview/preview1.svg");
                 lastTheoryName.value = '1 Общие положения'
             }

            preloader.value = false;
        });

        return {
            lastTheory,
            videoPreview,
            preloader,
            selectTheory,
            lastTheoryName
        };
    },
};
</script>

<style lang="scss">
.theoryList-main {
    margin-bottom: 11px;
    min-height: 330px;
    position: relative;
    @media (max-width: 1500px) {
        min-height: 230px;
    }
    &__wrap {
        position: relative;
    }
    &__img {
        width: 100%;
        max-height: 326px;
    }
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    &__title {
        color: #000;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
    }
    &__subtitle {
        margin-top: 3px;
        color: #000;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
    &__play {
        margin-left: auto;
        border-radius: 17px;
        border: 1px solid #3c6be4;
        padding: 9px 25px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        text-decoration: none;
        svg {
            margin-left: 12px;
            transition: all 0.3s;
            fill: #2e3c50;
            stroke: #2e3c50;
        }
        &:hover {
            background: #3c6be4;
            color: #fff;
            svg {
                fill: #fff;
                stroke: #fff;
            }
        }
    }
}
</style>
