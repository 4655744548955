export default {
	state: {
		asideResize: false
	},
	getters: {
		ASIDE_RESIZE: state => {
			return state.asideResize
		},
	},
	mutations: {
		SET_ASIDE_RESIZE: (state, payload) => {
			state.asideResize = payload
		}
	},
	actions: {
		GET_ASIDE_RESIZE: ({ commit }) => {
			const status = localStorage.getItem('asideResize')
			const convertStatus = String(status).toLowerCase() == "true"
			commit('SET_ASIDE_RESIZE', convertStatus)
		},
		TOGGLE_ASIDE_RESIZE: ({ commit, state }) => {
			const status = !state.asideResize
			localStorage.setItem('asideResize', status.toString())
			commit('SET_ASIDE_RESIZE', !state.asideResize)
		}
	}
}