<template>
  <div class="driving page">
	<Aside></Aside>
	<div class="container">
		<div class="content">
			<ExamBlock :item="custom" v-if="custom" :showHelpers="showHelpers" :errors="errors" :timerWidget="true" :timer="timer" pageTitle="Свой экзамен"></ExamBlock>
		</div>
	</div>
  </div>
	<DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>

