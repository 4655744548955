import { ref, onMounted, computed, watch, reactive } from "vue";
import { useStore } from 'vuex'
export default {
    name: "StatisticWidget",
    props: {
        statistic: Object,
    },
    setup(props, { attrs, emit }) {
        const store = useStore()
        const driverLiner = ref("");
        const theoryLiner = ref("");
        const questionsLiner = ref("");
        const total = ref(0);
        const totalStyles = ref("stroke-dasharray: 0, 100");
        const userData = reactive(computed(() => store.getters.userInfo))

        driverLiner.value = computed(() => {
            return (
                "width: " +
                Math.round(100 / parseInt(props.statistic.driverAll)) *
                    parseInt(props.statistic.currentDriver ? props.statistic.currentDriver : 0) +
                "%"
            );
        }).value;
        theoryLiner.value = computed(() => {
            return (
                "width: " +
                (Math.round(100 / 45) * parseInt(userData.value.progress ? userData.value.progress : 0)) +
                "%"
            );
        }).value;
        questionsLiner.value = computed(() => {
            return (
                "width: " +
                Math.round(100 / parseInt(props.statistic.questionsAll)) *
                    parseInt(props.statistic.questionsAnswered) +
                "%"
            );
        }).value;
        watch(
            () => props.statistic.driverAll,
            () => {
                total.value =
                    Math.round(100 / parseInt(props.statistic.driverAll)) *
                        parseInt(props.statistic.currentDriver ? props.statistic.currentDriver : 0) +
                    Math.round(100 / 45) * parseInt(props.statistic.theory) +
                    Math.round(100 / parseInt(props.statistic.questionsAll)) *
                        parseInt(props.statistic.questionsAnswered);

                total.value = Math.round(total.value / 3);
                totalStyles.value =
                    "stroke-dasharray:" + total.value + " " + "100";
                driverLiner.value = computed(() => {
                    return (
                        "width: " +
                        Math.round(100 / parseInt(props.statistic.driverAll)) *
                            parseInt(props.statistic.currentDriver ? props.statistic.currentDriver : 0) +
                        "%"
                    );
                }).value;
            },
            () => props.statistic.theory,
            () => {
                theoryLiner.value = computed(() => {
                    return (
                        "width: " +
                        Math.round(100 / 45) *
                            parseInt(props.statistic.theory) +
                        "%"
                    );
                }).value;
            },
            () => props.statistic.questionsAnswered,
            () => {
                questionsLiner.value = computed(() => {
                    return (
                        "width: " +
                        Math.round(
                            100 / parseInt(props.statistic.questionsAll)
                        ) *
                            parseInt(props.statistic.questionsAnswered) +
                        "%"
                    );
                }).value;
            }
        );

        return {
            driverLiner,
            theoryLiner,
            questionsLiner,
            total,
            totalStyles,
            userData
        };
    },
};
