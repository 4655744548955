<template>
	<div class="user-info" v-if="userData">
    <div class="user-info__common-wrap">
      <img class="user-info__img" :src="userData.photo" alt="user" v-if="userData.photo">
      <div class="user-info__img-wrap" v-else>
        {{userData.name && userData.name[0]}}{{userData.last_name && userData.last_name[0]}}
      </div>
      <div class="user-info__wrap" v-if="userData">
        <div class="user-info__name">
          {{ userData.name }}
        </div>
      </div>
      <div class="user-info__mail">
        {{ userData.email }}
      </div>
      </div>
	</div>
</template>

<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>
