import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import Tab from '../../components/Tab/Tab.vue'
import StatisticWidget from '../../components/StatisticWidget/StatisticWidget.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { useStore } from 'vuex'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import FormAuth from '../../components/FormAuth/FormAuth'
import router from '../../router/index'
export default {
    name: 'Tickets',
    components: {
        Aside,
        Header,
        Tab,
        StatisticWidget,
        DownloadPage,
        FormAuth,
    },
    props: {
        exam: {
            type: String,
        },
    },
    setup() {
        const store = useStore()
        const currentUserDate = computed(() => store.getters.userInfo)
        // !currentUserDate.value && router.push({ name: 'SignIn' })
    },
}
