import Aside from '../../components/Aside/Aside.vue'
import HeaderExam from '../../components/HeaderExam.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import { onMounted } from '@vue/runtime-core'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ExamBlock from '../../components/ExamTest/ExamTest.vue'

export default {
    name: 'TicketsExamTheme',
    components: {
        Aside,
        HeaderExam,
        DownloadPage,
        ExamBlock,
    },
    props: {
        item: {
            type: String,
        },
    },
    setup({ item }) {
        const store = useStore()
        const getTicketsTheme = ref('')
        const title = ref(false)

        const ticketsThemeAction = () => store.dispatch('GET_QUEST_THEME', item)
        onMounted(async () => {
            await ticketsThemeAction()
            getTicketsTheme.value = await store.getters.QUEST_THEME
            const parseItem = JSON.parse(item)
            title.value = parseItem.title
        })
        return {
            getTicketsTheme,
            title,
        }
    },
}
