import { ref, onMounted, computed,watch,reactive} from 'vue'
import router from "../../router";
export default {
    name: 'DriveWidget',
    props: {
        drivingRecord: Object,
        user: Object
    },
    setup(props, {attrs}) {
        const countDrivingRecords = ref(0);
        const minCurrentTime = ref({});

        onMounted(() => {
            if (typeof  props.drivingRecord.schedule != 'string') {
                nextLesson(props.drivingRecord.schedule);
            }
        });

        watch(
            () => props.drivingRecord.schedule.length, () => {
                nextLesson(props.drivingRecord.schedule)
                }
            )


        const nextLesson = (value) => {
            var date =new Date();
            let currentTime = date.getTime();
            let min = 10000000000000;
            countDrivingRecords.value = 0;
            value.forEach(item => {
                if (item['milliseconds'] > currentTime) {
                    countDrivingRecords.value++;
                }
                if ((item['milliseconds'] > currentTime) && (min > item['milliseconds'])) {
                    minCurrentTime.value = item;
                    min = item['milliseconds'];
                }
            })
        }
        const drivingRecord = () => {
            if (typeof props.user.id == "undefined" ) {
                router.push({
                    name: 'SignIn',
                })
            }
            else {
                 if (props.user.selectInstructor == false) {
                     router.push({
                         name: 'Driving',
                     })
                 }
                 if ((props.user.selectInstructor == true) && (props.user.active == 0 || props.user.active == null)){
                     router.push({
                         name: 'Wait',
                     })
                 }
                if ((props.user.selectInstructor == true) && (props.user.active == 1)){
                    router.push({
                        name: 'Shedule',
                    })
                }

            }
        }



        return {
            countDrivingRecords,
            minCurrentTime,
            drivingRecord


        }
    }
}