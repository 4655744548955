<template>
  <div class="examgibddpage page">
	<Aside></Aside>
	<div class="container">
		<HeaderExam :name="{name1 : 'Экзамен ГИБДД', description : '20 вопросов по 4 блока в одном блоке макс. 1 ошибка +5 вопросов за ошибку'}"></HeaderExam>
		<div class="content">
			<Preloader v-if="!theory.length"></Preloader>
			<div class="header-exam__timer">
				<svg width="24" height="24" class="header-exam__ico" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 2C9.02219 2 7.08879 2.58649 5.4443 3.6853C3.79981 4.78412 2.51809 6.3459 1.76121 8.17317C1.00433 10.0004 0.806299 12.0111 1.19215 13.9509C1.578 15.8907 2.53041 17.6725 3.92894 19.0711C5.32746 20.4696 7.10929 21.422 9.0491 21.8079C10.9889 22.1937 12.9996 21.9957 14.8268 21.2388C16.6541 20.4819 18.2159 19.2002 19.3147 17.5557C20.4135 15.9112 21 13.9778 21 12C21 10.6868 20.7413 9.38642 20.2388 8.17317C19.7363 6.95991 18.9997 5.85752 18.0711 4.92893C17.1425 4.00035 16.0401 3.26375 14.8268 2.7612C13.6136 2.25866 12.3132 2 11 2V2ZM11 20C9.41775 20 7.87104 19.5308 6.55544 18.6518C5.23985 17.7727 4.21447 16.5233 3.60897 15.0615C3.00347 13.5997 2.84504 11.9911 3.15372 10.4393C3.4624 8.88743 4.22433 7.46197 5.34315 6.34315C6.46197 5.22433 7.88743 4.4624 9.43928 4.15372C10.9911 3.84504 12.5997 4.00346 14.0615 4.60896C15.5233 5.21447 16.7727 6.23984 17.6518 7.55544C18.5308 8.87103 19 10.4177 19 12C19 14.1217 18.1572 16.1566 16.6569 17.6569C15.1566 19.1571 13.1217 20 11 20V20ZM14.1 12.63L12 11.42V7C12 6.73478 11.8946 6.48043 11.7071 6.29289C11.5196 6.10536 11.2652 6 11 6C10.7348 6 10.4804 6.10536 10.2929 6.29289C10.1054 6.48043 10 6.73478 10 7V12C10 12 10 12.08 10 12.12C10.0059 12.1889 10.0228 12.2564 10.05 12.32C10.0706 12.3793 10.0974 12.4363 10.13 12.49C10.1574 12.5468 10.1909 12.6005 10.23 12.65L10.39 12.78L10.48 12.87L13.08 14.37C13.2324 14.4564 13.4048 14.5012 13.58 14.5C13.8014 14.5015 14.0171 14.4296 14.1932 14.2953C14.3693 14.1611 14.4959 13.9722 14.5531 13.7583C14.6103 13.5444 14.5948 13.3176 14.5092 13.1134C14.4236 12.9092 14.2726 12.7392 14.08 12.63H14.1Z" fill="#3C6BE4"></path></svg>
				<TimerWidgetExamGIBDD v-if="!resultTest" :stopTimer="resultModalAll" :addTime="addTime"></TimerWidgetExamGIBDD>
			</div>
			<div class="examgibddlist" v-show="showCard">
				<ul class="examgibddlist__list">
					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" >
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 0" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>
					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" >
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 1" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>
					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" >
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 2" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>

					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" >
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 3" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>

					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" v-show="theory.length > 20">
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 4" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>

					<li class="examgibddlist__item" v-for="(item, index) in 1" :key="index" v-show="theory.length > 25">
						<ul class="examgibddlist__list-inner" v-for="(item, index) in theory" :key="index" v-show="item.count === 5" @click="selectCard(index)">
							<li class="examgibddlist__item-inner" :class="[{red : item.result == 0 && finishResult}, {green : item.result == 1 && finishResult}, {yellow : item.result != 0 && item.result != 1  && finishResult}, {resolved : item.result == 0 || item.result == 1}]" >
								<img :src="item.picture" v-if="item.picture" class="examgibddlist__img">
								<div class="examgibddlist__question">
									{{index +1}}.{{item.quest}}
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="exam" v-show="!showCard">
				<div class="exam__top">


					<div @click="prev()" class="exam__list-prev" v-if="controls">
						<svg style="transform: rotate(180deg);" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.4599 8.29002C8.2716 8.09641 8.01409 7.98554 7.74404 7.98179C7.47399 7.97804 7.21351 8.08172 7.0199 8.27002C6.82629 8.45833 6.71542 8.71583 6.71167 8.98588C6.70792 9.25593 6.8116 9.51641 6.9999 9.71002L9.3399 12L6.9999 14.29C6.90617 14.383 6.83178 14.4936 6.78101 14.6154C6.73024 14.7373 6.7041 14.868 6.7041 15C6.7041 15.132 6.73024 15.2627 6.78101 15.3846C6.83178 15.5065 6.90617 15.6171 6.9999 15.71C7.09286 15.8038 7.20346 15.8781 7.32532 15.9289C7.44718 15.9797 7.57789 16.0058 7.7099 16.0058C7.84191 16.0058 7.97262 15.9797 8.09448 15.9289C8.21634 15.8781 8.32694 15.8038 8.4199 15.71L11.4199 12.71C11.5136 12.6171 11.588 12.5065 11.6388 12.3846C11.6896 12.2627 11.7157 12.132 11.7157 12C11.7157 11.868 11.6896 11.7373 11.6388 11.6154C11.588 11.4936 11.5136 11.383 11.4199 11.29L8.4599 8.29002ZM16.9599 11.29L13.9599 8.29002C13.7716 8.10172 13.5162 7.99593 13.2499 7.99593C12.9836 7.99593 12.7282 8.10172 12.5399 8.29002C12.3516 8.47833 12.2458 8.73372 12.2458 9.00002C12.2458 9.26632 12.3516 9.52172 12.5399 9.71002L14.8399 12L12.5399 14.29C12.4462 14.383 12.3718 14.4936 12.321 14.6154C12.2702 14.7373 12.2441 14.868 12.2441 15C12.2441 15.132 12.2702 15.2627 12.321 15.3846C12.3718 15.5065 12.4462 15.6171 12.5399 15.71C12.6329 15.8038 12.7435 15.8781 12.8653 15.9289C12.9872 15.9797 13.1179 16.0058 13.2499 16.0058C13.3819 16.0058 13.5126 15.9797 13.6345 15.9289C13.7563 15.8781 13.8669 15.8038 13.9599 15.71L16.9599 12.71C17.0563 12.6197 17.1338 12.5112 17.1881 12.3908C17.2423 12.2704 17.2721 12.1404 17.2759 12.0084C17.2796 11.8763 17.2571 11.7449 17.2097 11.6216C17.1624 11.4983 17.0911 11.3856 16.9999 11.29H16.9599Z" fill="#3C6BE4"/>
						</svg>
					</div>
					<ul :class="['exam__list', {'controls': controls}]" ref="scrollContainer" v-dragscroll="true">
						<li class="exam__item" v-for="(item, index) in theory" :key="index" :class="[{ current: currentTicketNumber ===  (index +1)}, {success : item.result == 1 && finishResult}, {err : item.result == 0 && finishResult}, {currentGBDD : item.result == 1 || item.result == 0}]" @click="currentTicket(index +1)">{{index +1}}</li>
					</ul>
					<div @click="next()" class="exam__list-next" v-if="controls">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.4599 8.29002C8.2716 8.09641 8.01409 7.98554 7.74404 7.98179C7.47399 7.97804 7.21351 8.08172 7.0199 8.27002C6.82629 8.45833 6.71542 8.71583 6.71167 8.98588C6.70792 9.25593 6.8116 9.51641 6.9999 9.71002L9.3399 12L6.9999 14.29C6.90617 14.383 6.83178 14.4936 6.78101 14.6154C6.73024 14.7373 6.7041 14.868 6.7041 15C6.7041 15.132 6.73024 15.2627 6.78101 15.3846C6.83178 15.5065 6.90617 15.6171 6.9999 15.71C7.09286 15.8038 7.20346 15.8781 7.32532 15.9289C7.44718 15.9797 7.57789 16.0058 7.7099 16.0058C7.84191 16.0058 7.97262 15.9797 8.09448 15.9289C8.21634 15.8781 8.32694 15.8038 8.4199 15.71L11.4199 12.71C11.5136 12.6171 11.588 12.5065 11.6388 12.3846C11.6896 12.2627 11.7157 12.132 11.7157 12C11.7157 11.868 11.6896 11.7373 11.6388 11.6154C11.588 11.4936 11.5136 11.383 11.4199 11.29L8.4599 8.29002ZM16.9599 11.29L13.9599 8.29002C13.7716 8.10172 13.5162 7.99593 13.2499 7.99593C12.9836 7.99593 12.7282 8.10172 12.5399 8.29002C12.3516 8.47833 12.2458 8.73372 12.2458 9.00002C12.2458 9.26632 12.3516 9.52172 12.5399 9.71002L14.8399 12L12.5399 14.29C12.4462 14.383 12.3718 14.4936 12.321 14.6154C12.2702 14.7373 12.2441 14.868 12.2441 15C12.2441 15.132 12.2702 15.2627 12.321 15.3846C12.3718 15.5065 12.4462 15.6171 12.5399 15.71C12.6329 15.8038 12.7435 15.8781 12.8653 15.9289C12.9872 15.9797 13.1179 16.0058 13.2499 16.0058C13.3819 16.0058 13.5126 15.9797 13.6345 15.9289C13.7563 15.8781 13.8669 15.8038 13.9599 15.71L16.9599 12.71C17.0563 12.6197 17.1338 12.5112 17.1881 12.3908C17.2423 12.2704 17.2721 12.1404 17.2759 12.0084C17.2796 11.8763 17.2571 11.7449 17.2097 11.6216C17.1624 11.4983 17.0911 11.3856 16.9999 11.29H16.9599Z" fill="#3C6BE4"/>
						</svg>
					</div>

				</div>
				<div class="exam__body">
					<div class="exam__card" v-for="(item, index) in theory" v-show="(index+1) == currentTicketNumber" :key="index">
						<img :src="item.picture" v-if="item.picture">
							<div class="exam__answears-default-img" v-else>Вопрос без картинки</div>
						<div class="exam__question">{{item.quest}}</div>
						<ul class="exam__answears-list">
							<li class="exam__answears-item" v-for="(item2, index2) in item.answers" :key="index2" :class="[{ active: item.currentAnsewer ===  index2}, {success: item.disabled === 'Y' && item2.correct == 'Y' && item.currentAnsewer ===  index2 && finishResult}, {err: item.disabled === 'Y' && item2.correct == 'N' && item.currentAnsewer ===  index2 && finishResult}]"  @click="currentAnswer(index, index2, item.answers[index2])">
								<span class="exam__answears-item-check"></span>
								{{item2.answer}}
							</li>
						</ul>

						<div class="exam__btn-wrap">
							<div class="exam__btn" @click="nextQuestion(index, item.num)" v-if="!finishResult">
								{{ unAnswerArr.length == 1 ? 'Завершить тест' : 'Следующий'}}
							</div>
							<div class="exam__btn exam__btn--light" @click="selectCard()">
								К перечню
							</div>
						</div>

						<div class="exam__favorite-wrap">
							<div class="exam__favorite" @click="addFavorite(item.id, item, index)" v-if="!item.favorite">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16 2H8.00001C7.20436 2 6.4413 2.31607 5.87869 2.87868C5.31608 3.44129 5.00001 4.20435 5.00001 5V21C4.99931 21.1762 5.04518 21.3495 5.13299 21.5023C5.22079 21.655 5.3474 21.7819 5.50001 21.87C5.65203 21.9578 5.82447 22.004 6.00001 22.004C6.17554 22.004 6.34799 21.9578 6.50001 21.87L12 18.69L17.5 21.87C17.6524 21.9564 17.8248 22.0012 18 22C18.1752 22.0012 18.3476 21.9564 18.5 21.87C18.6526 21.7819 18.7792 21.655 18.867 21.5023C18.9548 21.3495 19.0007 21.1762 19 21V5C19 4.20435 18.6839 3.44129 18.1213 2.87868C17.5587 2.31607 16.7957 2 16 2ZM17 19.27L12.5 16.67C12.348 16.5822 12.1755 16.536 12 16.536C11.8245 16.536 11.652 16.5822 11.5 16.67L7.00001 19.27V5C7.00001 4.73478 7.10536 4.48043 7.2929 4.29289C7.48044 4.10536 7.73479 4 8.00001 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8947 4.48043 17 4.73478 17 5V19.27Z" fill="#3C6BE4"></path>
								</svg>
								Добавить в избранное
							</div>
							<div class="exam__favorite" @click="delFavorite(item.id, index)" v-if="item.favorite">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16 2H8.00001C7.20436 2 6.4413 2.31607 5.87869 2.87868C5.31608 3.44129 5.00001 4.20435 5.00001 5V21C4.99931 21.1762 5.04518 21.3495 5.13299 21.5023C5.22079 21.655 5.3474 21.7819 5.50001 21.87C5.65203 21.9578 5.82447 22.004 6.00001 22.004C6.17554 22.004 6.34799 21.9578 6.50001 21.87L12 18.69L17.5 21.87C17.6524 21.9564 17.8248 22.0012 18 22C18.1752 22.0012 18.3476 21.9564 18.5 21.87C18.6526 21.7819 18.7792 21.655 18.867 21.5023C18.9548 21.3495 19.0007 21.1762 19 21V5C19 4.20435 18.6839 3.44129 18.1213 2.87868C17.5587 2.31607 16.7957 2 16 2ZM17 19.27L12.5 16.67C12.348 16.5822 12.1755 16.536 12 16.536C11.8245 16.536 11.652 16.5822 11.5 16.67L7.00001 19.27V5C7.00001 4.73478 7.10536 4.48043 7.2929 4.29289C7.48044 4.10536 7.73479 4 8.00001 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8947 4.48043 17 4.73478 17 5V19.27Z" fill="#3C6BE4"></path>
									<path d="M6 3H18V19.5L12 18L6 20V3Z" fill="#3C6BE4"></path>
								</svg>
								Удалить из избранного
							</div>
						</div>







					</div>
				</div>
				<div v-for="(item, index) in theory" :key="index" v-show="(index+1) == currentTicketNumber">
					<Comments :description1="item.description1" :description2="item.description2" v-if="showComments"></Comments>
				</div>
			</div>
<!--			//модалка для результата-->
			<div class="alert-modal" v-if="resultModalAll">
				{{colorModal}}
				<div class="alert-modal__overlay"></div>
				<div class="alert-modal__content alert-modal__content" :class="[{ red: colorModal ===  'red'}, { green: colorModal ===  'green'}]">
					<div class="alert-modal__title">
						<span v-if="stopTimer && resultTestUnsuccessfully ">Тест  не сдан</span>
						<span v-if="stopTimer && !resultTestUnsuccessfully && !resultTestSuccess">Время закончилось</span>
						<span v-if="resultTestSuccess">Тест сдан</span>
						<span v-if="resultTestUnsuccessfully && !stopTimer ">Тест не сдан</span>
					</div>
					<div class="alert-modal__description">
						<span v-if="resultTestSuccess"></span>
						Отвечено на {{AnswerCount}}  вопроса<br>
						Верно: {{AnswerTrueCount}}<br>
						Не верно: {{AnswerFalseCount}}<br>
					</div>
					<div class="alert-modal__btn" @click="closeModal">
						Ок
					</div>
				</div>
			</div>

		</div>
	</div>
  </div>
<DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>
<style lang="scss">
	@import "./styles.scss";
</style>