import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import router from '../../router'
import axios from 'axios'
import { base_URL } from '../../constants'
import Preloader from './../Preloader/Preloader.vue'
import RegDescription from '../RegDescription.vue'

export default {
    name: 'TicketsTab',
    components: {
        Preloader,
        RegDescription
    },
    setup() {
        const store = useStore()
        const ticketsErrorUser = ref([])
        let id_user = { id_user: localStorage.getItem('userData') }
        let queryString = JSON.stringify(id_user)
        const answeredTicketsOk = ref([])
        // const tickets = () => store.dispatch('GET_TICKET_NUMBER', queryString);

        onMounted(async () => {
            await axios
                .post(`${base_URL}/users/geterrorticket/`, queryString)
                .then(response => {
                    ticketsErrorUser.value = response['data']
                    answeredTicketsOk.value = ticketsErrorUser.value.filter(
                        item => item.error < 3 && item.flag == true
                    )
                })
                .catch(err => {
                    console.warn(err)
                })
        })

        const startExam = val => {
            let value = {
                number: val,
                id_user: localStorage.getItem('userData'),
            }
            let queryString = JSON.stringify(value)
            router.push({
                name: 'TicketsExam',
                params: { item: queryString },
            })
        }
        return {
            startExam,
            ticketsErrorUser,
            answeredTicketsOk,
            userData: computed(() => store.getters.userInfo)
        }
    },
}
