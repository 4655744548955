<template>
    <div class="driving page">
        <Aside></Aside>
        <div class="container">
            <Header></Header>
            <div class="content">
                <div class="drive-widget">
                    <div class="drive-widget__title">Вождение</div>
                    <div class="drive-widget__subtitle">
                        Выберите инструктора:
                    </div>
                    <ul class="driving__list">
                        <li
                            class="driving__item"
                            v-for="itemInstructor in instructors"
                            @click="setInstructors(itemInstructor.id)"
                            :key="itemInstructor.id"
                        >
                            <div
                                class="driving__photo"
                                v-if="itemInstructor.photo"
                            >
                                <img :src="itemInstructor.photo" alt="" />
                            </div>
                            <div class="driving__photo" v-else>
                                {{ userData.name && userData.name[0]
                                }}{{
                                    userData.last_name && userData.last_name[0]
                                }}
                            </div>
                            <div class="driving__info">
                                <div class="driving__name">
                                    {{ itemInstructor.last_name }}
                                </div>
                                <div class="driving__name">
                                    {{ itemInstructor.name }}
                                </div>
                                <div
                                    class="driving__car"
                                    v-if="itemInstructor.car"
                                >
                                    {{ itemInstructor.car }}
                                </div>
                            </div>
                            <div
                                class="driving__msg"
                                @click.stop
                                @click="
                                    activeContactsHandler(itemInstructor.id)
                                "
                                v-if="
                                    itemInstructor.phone ||
                                        itemInstructor.viber ||
                                        itemInstructor.whatsapp
                                "
                            >
                                <svg
                                    width="26"
                                    height="23"
                                    viewBox="0 0 26 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.5604 20.5413C9.86526 20.4739 9.45252 20.5383 8.74002 20.7587C7.88636 21.0227 6.56731 22.1591 4.76136 22.1591C4.65996 22.1591 6.46591 20.7033 6.46591 19.6023L6.46591 19.2109C2.88688 17.3869 0.5 14.1093 0.5 10.3693C0.5 4.6425 6.09644 0 13 0C19.9036 0 25.5 4.6425 25.5 10.3693C25.5 16.0961 19.9036 20.7386 13 20.7386C12.1652 20.7386 11.3494 20.6707 10.5604 20.5413H10.5604Z"
                                        fill="#3C6BE4"
                                    />
                                </svg>
                            </div>
                            <div
                                class="driving-contacts"
                                v-if="activeContacts == itemInstructor.id"
                            >
                                <div class="driving-contacts__title">
                                    Связаться
                                </div>
                            </div>
                            <div
                                class="driving-contacts__description"
                                v-if="activeContacts == itemInstructor.id"
                            >
                                Выберите способ связи <br />
                                с инструктором
                            </div>
                            <a
                                :href="'tel:+' + itemInstructor.phone"
                                @click.stop
                                class="driving-contacts__link phone"
                                v-if="
                                    itemInstructor.phone &&
                                        activeContacts == itemInstructor.id
                                "
                                >{{ itemInstructor.phone }}</a
                            >
                            <a
                                :href="itemInstructor.viber"
                                @click.stop
                                class="driving-contacts__link"
                                v-if="
                                    itemInstructor.viber &&
                                        activeContacts == itemInstructor.id
                                "
                                >Viber</a
                            >
                            <a
                                :href="itemInstructor.whatsapp"
                                @click.stop
                                class="driving-contacts__link"
                                v-if="
                                    itemInstructor.whatsapp &&
                                        activeContacts == itemInstructor.id
                                "
                                >Whatsapp</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <DownloadPage></DownloadPage>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
