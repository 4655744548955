<template>
	<div class="authPage page">
		<Aside></Aside>
		<div class="container">
			<Header></Header>
			<div class="content">
        <RegDescription />
				<FormAuth></FormAuth>
			</div>
		</div>
	</div>
	<DownloadPage></DownloadPage>
</template>

<style scoped>
  .content {
    align-items: center;
    gap: 15px;
  }</style>

<script src="./index.js"></script>
