<template>
  <div class="settings page">
	<Aside></Aside>
	<div class="container">
		<Header></Header>
		<div class="content">
			<UserCard v-if="!edit" :toggleEdit="toggleEdit" :toggleDeleteProfileModal="toggleDeleteProfileModal"></UserCard>
			<UserCardEdit v-else :toggleEdit="toggleEdit" v-on:toggleEditPhoto="toggleEditPhoto"></UserCardEdit>
			<PhotoModal v-if="editPhoto" :img-src="imgSrc" v-on:applyPhoto="applyPhoto" ></PhotoModal>
      <DeleteProfileModal v-if="deleteProfileModal" :toggleDeleteProfileModal="toggleDeleteProfileModal"></DeleteProfileModal>
		</div>
	</div>
  </div>
  <DownloadPage></DownloadPage>
</template>

<script src="./index.js"></script>

