import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'Header',
	setup () {
		const store = useStore()

		return {
			userData: computed(() => store.getters.userInfo),
      allUsers: computed(() => store.getters.GET_ALL_USERS)
		}
	}
  }
