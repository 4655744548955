import { ref, onMounted, watch, computed, reactive } from "vue";
import Aside from "../../components/Aside/Aside.vue";
import Header from "../../components/Header/Header.vue";
import DownloadPage from "../../components/DownloadPage/DownloadPage.vue";
import { useStore } from "vuex";
import axios from "axios";
import { base_URL } from "../../constants";
import router from "../../router";

export default {
    name: "Wait",
    components: {
        Aside,
        Header,
        DownloadPage,
    },
    setup() {
        const store = useStore();
        const modalContacts = ref(false);
        const currentUserDate = computed(() => store.getters.userInfo);
        const user = reactive({
            id: "0",
            selectInstructor: 0,
            region: "",
            city: "",
        });
        const instructor = ref({});
        onMounted(() => {
            user.id = currentUserDate.value.id;
            user.selectInstructor = currentUserDate.value.selected_instructor;

            if (typeof user.id != "undefined") {
                getInstructors();
            }
        });
        watch(
            () => currentUserDate.value,
            () => {
                user.id = currentUserDate.value.id;
                user.selectInstructor =
                    currentUserDate.value.selected_instructor;
                if (typeof user.id != "undefined") {
                    getInstructors();
                }
            }
        );
        const contact = () => {
            modalContacts.value = !modalContacts.value;
        };
        const changeInstructor = () => {
            let requestArray = {
                id: user.id,
                idInstructor: "",
                student: true,
            };
            axios
                .post(
                    `${base_URL}/users/setinstructor/`,
                    JSON.stringify(requestArray)
                )
                .then((response) => {
                    if (response["data"] == true) {
                        store.dispatch("SET_USER_INSTRUCTOR", false);
                        router.push({
                            name: "Driving",
                        });
                    }
                })
                .catch((error) => {});
        };
        const getInstructors = () => {
            let requestArray = {
                id: user.id,
            };
            axios
                .post(
                    `${base_URL}/users/getselectinstructor/`,
                    JSON.stringify(requestArray)
                )
                .then((response) => {
                    instructor.value = response["data"];
                })
                .catch((error) => {});
        };
        return {
            changeInstructor,
            modalContacts,
            contact,
            userData: computed(() => store.getters.userInfo),
        };
    },
};
