import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'

export default {
	name: 'Statistics',
	components: {
		Aside,
		Header,
		DownloadPage
    },

  }