import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import router from '../../router'
import Preloader from './../Preloader/Preloader.vue'

export default {
    name: 'ThemeTab',
    components: { Preloader },
    setup() {
        const store = useStore()
        const listTheme = ref([])

        const getListTheme = () => store.dispatch('GET_LIST_THEME', true)
        onMounted(async () => {
            await getListTheme()
            listTheme.value = await store.getters.LIST_THEME
        })

        const startTicket = (val, name) => {
            let value = {
                id: val,
                id_user: localStorage.getItem('userData'),
                title: name,
            }
            let queryString = JSON.stringify(value)
            router.push({
                name: 'TicketsExamTheme',
                params: { item: queryString },
            })
        }
        return {
            listTheme,
            startTicket,
        }
    },
}
