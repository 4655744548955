<template>
  <div class="vSelectCity">
    <div class="vSelectCity__field" :class="{ err: err }" @click="toggleDropDown(true)" ref="field">{{ selectedCity ? selectedCity : placeholder }}</div>
    <div class="vSelectCity__dropdown" v-if="isActive && variant == 'city'">
      <input type="text" placeholder="Поиск" class="vSelectCity__search" v-model="city">
      <ul class="vSelectCity__list">
        <li class="vSelectCity__item" v-for="item in filterCity.slice(0,5)" :key="item.city" @click="selectCity(item.city)">{{ item.city }}</li>
      </ul>
    </div>
    <div class="vSelectCity__dropdown" v-if="isActive && variant == 'region'">
      <input type="text" placeholder="Поиск" class="vSelectCity__search" v-model="region">
      <ul class="vSelectCity__list">
        <li class="vSelectCity__item" v-for="item in filterRegion.slice(0,5)" :key="item" @click="selectCity(item)">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectCity',
  props: {
    placeholder: {
      type: Text,
      require: true
    },
    variant: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      isActive: false,
      cityArray: [],
      regionArray: [],
      city: "",
      region: "",
      selectedCity: null,
      selectedRegion: null
    }
  },
  methods: {
    toggleDropDown (val) {
      this.isActive = val
    },

    // Фильтруем массив от одинаковых значений
    unique (arr) {
      let result = [];
      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str)
        }
      }
      return result
    },

    // Получаем данные городов из json
    async getCityLocalData () {
      const result = await require("./../../assets/cities.json")
      this.cityArray = result
      let regionTemp = []
      result.forEach((i) => {
        regionTemp.push(i.region)
      })
      this.regionArray = this.unique(regionTemp)
    },

    // Сохраняем выбранный город
    selectCity (data) {
      this.selectedCity = data
      this.$emit('selectCity', {val: data, el: this.variant})
      this.isActive = false
    },

    // Сохраняем выбранный регион
    selectRegion (data) {
      this.selectedRegion = data
      this.$emit('selectCity', {val: data, el: this.variant})
      this.isActive = false
    }
  },
  mounted () {
    this.getCityLocalData()
  },
  computed: {
    filterCity () {
      let word = this.city.toLowerCase()
      return this.cityArray.filter(i => {
        if (word === "") {
          return true
        } else {
          return i.city.toLowerCase().indexOf(word.toLowerCase()) > -1
        }
      })
    },
    filterRegion () {
      let word = this.region.toLowerCase()
      return this.regionArray.filter(i => {
        if (word === "") {
          return true
        } else {
          return i.toLowerCase().indexOf(word.toLowerCase()) > -1
        }
      })
    }
  }
}
</script>

<style lang="scss">
.vSelectCity {
  position: relative;
  &__field {
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.14);
    padding: 0 10px;
    width: 100%;
    height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.48);
    display: flex;
    align-items: center;
    cursor: pointer;
    &.err {
      border-color: #FE625D;
    }
  }
  &__dropdown {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    width: 100%;
    z-index: 1;
    top: 50px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.14);
  }
  &__search {
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.14);
    padding: 0 10px;
    width: 90%;
    height: 40px;
    font-size: 16px;
    margin-top: 15px;
    color: rgba(0, 0, 0, 0.48);
  }
  &__list {
    list-style: none;
    padding: 0;
  }
  &__item {
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.48);
    &:hover {
      background: #dbdbdb;
    }
  }
}
</style>