import axios from 'axios'
import { base_URL } from './../../constants'

export default {
    state: {
        allUsers: []
    },
    getters: {
        GET_ALL_USERS: state => {
            return state.allUsers
        },
    },
    mutations: {
        SET_ALL_USERS: (state, users) => {
            state.allUsers = users
        },
    },
    actions: {
        async ACTIVE_USER ({ commit, state }, payload) {
          await axios.post(`${base_URL}/users/setprofileid/`, JSON.stringify({id: payload.id, active: payload.active}) )
          const copyArrayUsers = state.allUsers
          copyArrayUsers.forEach(i => {
            if (i.ID == payload.id) {
              i.UF_ACTIVE = payload.active
            }
          })
          commit('SET_ALL_USERS', copyArrayUsers)
        },
        UPDATE_USERS_DATA ({ commit, state }, payload) {
          const { userId, flag } = payload
          const copyArrayUsers = state.allUsers
          copyArrayUsers.forEach(i => {
            if (i.ID == userId) {
              i.UF_FREE_ACCESS = flag ? '1' : '0'
            }
          })
          commit('SET_ALL_USERS', copyArrayUsers)
          console.log('copyArrayUsers', copyArrayUsers)
        },
        FETCH_ALL_USERS: async ({ commit }, id) => {
            await axios
                .post(`${base_URL}/users/get-cadets-region/`, id)
                .then(response => {
                    const data = []
                    response.data[1].forEach(i => {
                        data.push({
                            ...i,
                            NAME: i.NAME ?? '',
                            LAST_NAME: i.LAST_NAME ?? '',
                            PERSONAL_PHONE: i.PERSONAL_PHONE ? i.PERSONAL_PHONE : '',
                            PERSONAL_CITY: i.PERSONAL_CITY ? i.PERSONAL_CITY : '',
                            PERSONAL_STATE: i.PERSONAL_STATE ? i.PERSONAL_STATE : '',
                            fullDate: Date.parse(new Date(i.DATE_REGISTER_DATE))
                        })
                    })


                    data.sort((a, b) => b.fullDate - a.fullDate)

                    commit('SET_ALL_USERS', data)
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        DELETE_USER: async ( { commit, state } , id) => {
            await axios
                .post(`${base_URL}/users/delete-cadets/`, {
                    delete_id: id
                })
                .then(() => {
                    const copyUsersArray = state.allUsers
                    const filteredUsers = copyUsersArray.filter(i => i.ID != id)
                    commit('SET_ALL_USERS', filteredUsers)
                })
        },
        OPEN_ALL_LESSONS: async ( _ , payload) => {
            const { userId, groupId, flag } = payload
            await axios
                .post(`${base_URL}/users/setflagfree/`, {
                    user_id: parseInt(userId),
                    flag,
                    id: groupId
                })
                .then(response => {
                    console.log('OPEN_ALL_LESSONS res', response)
                })
        }
    },
}
