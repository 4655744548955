<template>
    <div class="theory-title">
        {{ index + 1 }} {{ name1 }}
        <br />
        {{ name2 }}
    </div>
</template>

<script>
export default {
    name: "TheoryTitle",
    props: {
        index: {
            type: Number,
        },
        name1: {
            type: String,
        },
        name2: {
            type: String,
        },
    },
    setup(props) {},
};
</script>

<style lang="scss">
.theory-title {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 22px;
    width: 100%;
    padding-right: 125px;
}
</style>
