<template>
	<Preloader v-if="preloaderActive"></Preloader>
  <div v-if="adBlock" class="adblock">
    <h1 class="adblock__text">
      Для корректной работы сайта <br>
      отключите "AdBlock"
    </h1>
    <div class="adblock__bg"></div>
  </div>
  <component v-else :is="layout">
    <router-view />
  </component>
</template>

<style lang="scss">
.adblock {
  background: #1741AF;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__bg {
    position: absolute;
    left: 50%;
    top: calc(50% - 40px);
    transform: translate(-50%, -50%);
    background: #2955C5;
    width: 896px;
    height: 896px;
    border-radius: 50%;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #3C6BE4;
      width: 310px;
      height: 310px;
      border-radius: 50%;
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #2D5EDC;
      width: 616px;
      height: 616px;
      border-radius: 50%;
      z-index: 1;
    }
  }
  &__text {
    font-size: 36px;
    color: #fff;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
    margin-bottom: 65px;
    position: relative;
    z-index: 2;
    margin-top: 0;
  }
}
</style>


<script src="./index.js"></script>
