<template>
	<div class="modal">
		<div class="modal__content">
			<div class="modal__header">
				<div class="modal__title">Редактирование изображения</div>
				<div class="modal__close" @click="cancel"></div>
			</div>
			<div class="modal__body">
				<cropper
						:src="imgCrop"
						:stencil-component="$options.components.CircleStencil"
						id = "cropImage"
						@change="onChange"
				/>
			</div>
			<div class="modal__footer">
				<div class="modal__btn" @click="crop">Применить</div>
				<div class="modal__btn modal__btn--red" @click="cancel">Отмена</div>
			</div>
		</div>
	</div>
</template>


<style lang="scss">
	@import './styles.scss';
</style>

<script src="./index.js"></script>