import { computed } from "vue";
import { useStore } from "vuex";
import router from "./../../router/index";

export default {
    name: "Menu",
    setup() {
        const store = useStore();

        return {
            userData: computed(() => store.getters.userInfo),

            // Выйти из профиля
            exitProfile: () => {
                localStorage.removeItem("userData");
                store.dispatch("CLEAR_USER_DATA");

                router.push({
                    name: "Home",
                });
                setTimeout(() => {
                    location.reload();
                }, 500);
            },
        };
    },
};
