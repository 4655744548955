import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Menu from '../../components/Menu/Menu.vue'
import UserInfo from '../../components/UserInfo/UserInfo.vue'
import UserStatus from '../../components/UserStatus.vue'
export default {
	name: 'Aside',
	components: {
		UserInfo,
		Menu,
    UserStatus
    },
	setup(){
		const store = useStore()

		const getAsideResize = () => store.dispatch('GET_ASIDE_RESIZE')

		onMounted(() => {
			getAsideResize()
		})

		return{
			asideResize: computed(() => store.getters.ASIDE_RESIZE),
      userData: computed(() => store.getters.userInfo),

			toggleAsideResize: () => store.dispatch('TOGGLE_ASIDE_RESIZE')

		}
	}
  }
