import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
	name: 'ExamTab',

    setup () {
        const store = useStore()
	    const timer = ref('10000');
	    const question = ref('20');
	    const errors = ref('0');
	    const mixedAnswered = ref(false);
	    const showHelpers = ref(false);

        return {
            timer,
            question,
            errors,
            mixedAnswered,
            showHelpers,
            userData: computed(() => store.getters.userInfo)
        }
    }

  }

