import axios from 'axios'
import { base_URL } from './../../constants'

export default {
    state: {
        allPravila: [],
        pravilo: {},
    },
    getters: {
        PRAVILA: state => {
            return state.allPravila
        },
        PRAVILO: state => {
            return state.pravilo
        },
    },
    mutations: {
        SET_PRAVILA: (state, payload) => {
            state.allPravila = payload
        },
        SET_PRAVILO: (state, payload) => {
            state.pravilo = payload
        },
    },
    actions: {
        GET_PRAVILA: async ({ commit }) => {
            await axios
                .post(`${base_URL}/pdd/getpddlist/`)
                .then(res => {
                    commit('SET_PRAVILA', res.data.pdd)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        GET_PRAVILO: ({ commit, state }, payload) => {
            let praviloVal = ''
            state.allPravila.forEach(i => {
                i.text.forEach(j => {
                    if (j.code == payload) {
                        praviloVal = { name: i.name, ...j }
                    }
                })
            })
            commit('SET_PRAVILO', praviloVal)
        },
    },
}
