<template>
    <div class="statistic-widget">
        <div class="statistic-widget__title">
            Статистика
        </div>
        <div class="statistic-widget__wrapper">
            <div class="statistic-widget__circle">
                <div class="statistic-widget__percent">{{ total }}%</div>
                <svg
                    class="statistic-widget__diagramm"
                    width="246"
                    height="246"
                    viewBox="0 0 50 50"
                >
                    <circle class="unit" r="15.9" cx="50%" cy="50%"></circle>
                    <circle
                        class="unit"
                        r="15.9"
                        cx="50%"
                        cy="50%"
                        :style="totalStyles"
                    ></circle>
                </svg>
            </div>
            <div class="statistic-widget__subtitle">
                Готовность к сдаче <br />
                экзамена
            </div>
            <ul class="statistic-widget__list">
                <li class="statistic-widget__item">
                    <div class="statistic-widget__item-top">
                        Пройдено уроков теории
                        <span
                            class="statistic-widget__item-num"
                            >{{ !userData || userData.progress == null ? 0 : userData.progress }}/45</span
                        >
                    </div>
                    <div class="statistic-widget__line">
                        <div
                            class="statistic-widget__progress"
                            :style="theoryLiner"
                        ></div>
                    </div>
                </li>
                <li class="statistic-widget__item">
                    <div class="statistic-widget__item-top">
                        Прогресс вождения
                        <span class="statistic-widget__item-num"
                            >{{ statistic.currentDriver ? statistic.currentDriver : 0 }}/{{
                                statistic.driverAll
                            }}</span
                        >

                    </div>
                    <div class="statistic-widget__line">
                        <div
                            class="statistic-widget__progress"
                            :style="driverLiner"
                        ></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
