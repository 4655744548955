import axios from 'axios'
import { base_URL } from './../../constants'

export default {
    state: {
        allZnaki: [],
        znak: {},
    },
    getters: {
        ZNAKI: state => {
            return state.allZnaki
        },
        ZNAK: state => {
            return state.znak
        },
    },
    mutations: {
        SET_ZNAKI: (state, payload) => {
            state.allZnaki = payload
        },
        SET_ZNAK: (state, payload) => {
            state.znak = payload
        },
    },
    actions: {
        GET_ZNAKI: async ({ commit }) => {
            await axios
                .post(`${base_URL}/pdd/getsinglist/`)
                .then(res => {
                    commit('SET_ZNAKI', res.data.sing)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        GET_ZNAK: ({ commit, state }, payload) => {
            let znkVal = ''
            state.allZnaki.forEach(i => {
                i.text.forEach(j => {
                    if (j.code_znak == payload) {
                        znkVal = j
                    }
                })
            })
            commit('SET_ZNAK', znkVal)
        },
    },
}
