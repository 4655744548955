import axios from 'axios'
import { base_URL } from './../../constants'

export default {
    state: {
        theoryTest: [],
        modalExam: false,
        modalExamGIBDD: false,
        hardTest: [],
        answeredTickets: [],
        ticketsNumber: [],
        themeList: [],
        questTheme: [],
        favorList: [],
    },
    getters: {
        THEORY_TEST: state => {
            return state.theoryTest
        },
        MODAL_EXAM: state => {
            return state.modalExam
        },

        MODAL_EXAM_GIBDD: state => {
            return state.modalExamGIBDD
        },
        HARD_TEST: state => {
            return state.hardTest
        },
        ANSWERED_TICKETS: state => {
            return state.answeredTickets
        },
        TICKET_NUMBER: state => {
            return state.ticketsNumber
        },
        LIST_THEME: state => {
            return state.themeList
        },
        QUEST_THEME: state => {
            return state.questTheme
        },
        FAVOR_LIST: state => {
            return state.favorList
        },
    },
    mutations: {
        SET_THEORY_TEST: (state, theoryTest) => {
            state.theoryTest = theoryTest
        },
        SET_ACTIVE_MODAL_EXAM: (state, modal) => {
            state.modalExam = modal
        },
        SET_ACTIVE_MODAL_EXAM_GIBDD: (state, modal) => {
            console.log('modal', modal)
            state.modalExamGIBDD = modal
        },
        SET_HARD_TEST: (state, test) => {
            state.hardTest = test
        },
        SET_ANSWERED_TICKETS: (state, tickets) => {
            state.answeredTickets = tickets
        },
        SET_TICKET_NUMBER: (state, tickets) => {
            state.ticketsNumber = tickets
        },
        SET_LIST_THEME: (state, theme) => {
            state.themeList = theme
        },
        SET_QUEST_THEME: (state, quest) => {
            state.questTheme = quest
        },
        SET_UPDATE_FAVOR: (state, favor) => {
            state.favorList = favor
        },
    },
    actions: {
        GET_RULE_QUESTIONS: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/questions/getrulequestions/`, value)
                .then(response => {
                    commit('SET_THEORY_TEST', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        ACTIVE_MODAL_EXAM: ({ commit }, value) => {
            commit('SET_ACTIVE_MODAL_EXAM', value)
        },
        ACTIVE_MODAL_EXAM_GIBDD: ({ commit }, value) => {
            commit('SET_ACTIVE_MODAL_EXAM_GIBDD', value)
        },
        GET_HARD_QUESTIONS: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/questions/gethardquestions/`, value)
                .then(response => {
                    // console.log('GET_HARD_QUESTIONS', response)
                    commit('SET_HARD_TEST', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        GET_ANSWERED_TICKETS: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/users/getansweredtickets/`, value)
                .then(response => {
                    // console.log('GET_HARD_QUESTIONS', response)
                    commit('SET_ANSWERED_TICKETS', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        GET_TICKET_NUMBER: async ({ commit }, value) => {
            // console.log('SET_TICKET_NUMBER___value', value)
            await axios
                .post(`${base_URL}/questions/gettiketnumber/`, value)
                .then(response => {
                    commit('SET_TICKET_NUMBER', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        GET_LIST_THEME: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/questions/getthemeslist/`, value)
                .then(response => {
                    commit('SET_LIST_THEME', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        GET_QUEST_THEME: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/questions/getquestions/`, value)
                .then(response => {
                    commit('SET_QUEST_THEME', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        UPDATE_FAVOR_LIST: async ({ commit }, value) => {
            await axios
                .post(`${base_URL}/users/getfavoriteslist/`, value)
                .then(response => {
                    console.log('SET_UPDATE_FAVOR', response)
                    commit('SET_UPDATE_FAVOR', response['data'])
                })
                .catch(err => {
                    console.warn(err)
                })
        },
    },
}
