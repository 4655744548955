import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'UserCard',
	setup(props, {attrs}) {
		const store = useStore()

		// Активировать режим редактирования
		const toggleEdit = () => {
			attrs.toggleEdit()
		}

    // показать модалку удаления профиля
    const showDeleteModal = () => {
      attrs.toggleDeleteProfileModal()
    }
		return {
			toggleEdit,
			userData: computed(() => store.getters.userInfo),
      showDeleteModal
		}
	}
  }
