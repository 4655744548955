<template>
	<div class="drive-widget">
		<div class="drive-widget__title">Вождение</div>
		<div class="drive-widget__subtitle" v-if="countDrivingRecords == 0 ">Ближайшее занятие не назначено</div>
		<div class="drive-widget__subtitle" v-if="countDrivingRecords > 0 ">Ближайшее занятие по вождению:</div>
		<div class="drive-widget__date" v-if="countDrivingRecords > 0 ">{{minCurrentTime.date}} {{minCurrentTime.time}}</div>
		<div class="drive-widget__btn" @click="drivingRecord" v-if="(user.selectInstructor == true) && (user.active == 1)">План занятий</div>
		<div class="drive-widget__btn" @click="drivingRecord" v-else>Записаться на вождение</div>

	</div>
</template>


<style lang="scss">
	@import './styles.scss';
</style>
<script src="./index.js"></script>