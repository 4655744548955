import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import { detectAnyAdblocker } from 'vue-adblock-detector'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Preloader from './../../components/Preloader/Preloader.vue'

// const WrapperData = defineAsyncComponent(async () => {
//   console.log('WrapperData !!!')
//     const store = useStore()
//     // Сохраняем в стор Данные пользователя
//     const getProfileId = userId => store.dispatch('GET_PROFILE_ID', userId)

//     // Получаем ID пользователя ID
//     const userId = await localStorage.getItem('userData')

//     // Если в localeStorage есть ID
//     if (userId !== null) {
//         await getProfileId(userId)
//     }

//     return import('./../../components/WrapperData.vue')
// })

export default {
    name: 'App',
    components: { Preloader },
    setup() {
        const route = useRoute()
        const store = useStore()
        const adBlock = ref(false)
        const preloaderActive = ref(true)

        const layout = computed(() => route.meta.layout)

        // Сохраняем в стор Данные пользователя
        const getProfileId = userId => store.dispatch('GET_PROFILE_ID', userId)

        // Сохраняем в стор сложные вопросы
        const getHardQuestions = userId => {
            const value = { id_user: userId }
            const queryString = JSON.stringify(value)
            store.dispatch('GET_HARD_QUESTIONS', queryString)
        }

        // Сохраняем в стор избранное
        const getFavorites = userId => store.dispatch('GET_FAVORITES', userId)

        // Сохраняем в стор билеты с ошибками
        const getErrors = userId => store.dispatch('GET_ERRORS', userId)

        // Сохраняем в стор список теории
        const getTheory = () => store.dispatch('GET_THEORY_LIST')

        // Сохраняем в стор таймер массив
        const getTimerTheory = userId => store.dispatch('GET_TIMER', userId)

        // Сохраняем в стор все знаки
        const getZnaki = () => store.dispatch('GET_ZNAKI')

        // Сохраняем в стор все правила
        const getPravila = () => store.dispatch('GET_PRAVILA')

        const start = async () => {
          detectAnyAdblocker().then((detected) => {
            if(detected){
              adBlock.value = true
              preloaderActive.value = false
              return
            }
          })


            // Получаем ID пользователя ID
            const userId = await localStorage.getItem('userData')

            await getTheory()
            await getZnaki()
            await getPravila()

            // Если в localeStorage есть ID
            if (userId !== null) {
                await getTimerTheory(userId)
                await getProfileId(userId)
                await getHardQuestions(userId)
                await getFavorites(userId)
                await getErrors(userId)
            }

            preloaderActive.value = false
        }
        start()

        return {
          preloaderActive,
          layout,
          adBlock
        }
    },
}
