<template>
    <div
        class="player"
        ref="videoContainer"
        tabindex="0"
        v-on:fullscreenchange="FullScreenWatch"
    >
        <!-- Video -->
        <video
            class="player__video"
            @loadeddata="loadVideo"
            @timeupdate="timeupdateHandler"
            @ended="endVideo"
            ref="player"
        >
            <source
                :src="'https://api.avtoshkola.pw' + data.path"
                type="video/mp4;"
                v-if="data && data.path"
            />
        </video>

        <div class="player__controls">
            <!-- Play/Pause -->
            <VideoPlayPause
                :status="status"
                @onHandler="playHandler"
            ></VideoPlayPause>

            <!-- Timer -->
            <VideoTimer
                :durationVideo="durationVideo"
                :durationCurrentVideo="durationCurrentVideo"
            ></VideoTimer>


            <div class="progress-block" v-if="
                    (userId != 0 && accessProgress) ||
                        (userId == 0 && !accessProgress)">

						<!-- <div class="progress-block"> -->

                <progress
                    id="progressbar"
                    class="progressbar"
                    max="100"
                    :value="progressbarVal"
                ></progress>
                <input
                    type="range"
                    id="seekbar"
                    :value="seekbar"
                    class="seekbar"
                    ref="seekbarEl"
                    @change="progressChange"
                />
            </div>

            <!-- Volume -->
            <VideoVolume></VideoVolume>

            <!-- FullScreen -->
            <VideoFullScreen
                :isFullscreen="isFullscreen"
                @onHandler="fullscreenbuttonHandler"
            ></VideoFullScreen>
        </div>
    </div>
</template>

<script>
// import { useStore } from "vuex";

import { onMounted, ref, watch, reactive, computed } from "vue";

import VideoPlayPause from "./VideoPlayPause";
import VideoVolume from "./VideoVolume";
import VideoTimer from "./VideoTimer";
import VideoProgress from "./VideoProgress";
import VideoFullScreen from "./VideoFullScreen";
import { useStore } from "vuex";

export default {
    name: "VideoPlayer",
    components: {
        VideoPlayPause,
        VideoTimer,
        VideoVolume,
        VideoProgress,
        VideoFullScreen,
    },
    props: {
        data: {
            type: Object,
        },
        id: {
            type: String,
        },
        accessProgress: {
            type: Boolean,
        },
    },

    setup(props, {emit}) {
        const store = useStore();
        const player = ref(null);
        const lastTheory = reactive(computed(() => store.getters.LAST_THEORY));

        const propsAccess = ref(false);

        const userId = ref(0);

        const durationVideo = ref("00:00");
        const durationCurrentVideo = ref("00:00");

        const videoContainer = ref(null);

        const isFullscreen = ref(false);
        const counterFullScreen = ref(0);

        const seekbarEl = ref(null);
        const seekbar = ref(0);
        const progressbarVal = ref(0);

        const status = ref(false);

        const fullscreenbuttonHandler = () => {
            if (!isFullscreen.value) {
                if (videoContainer.value.requestFullscreen) {
                    videoContainer.value.requestFullscreen();
                } else if (videoContainer.value.mozRequestFullScreen) {
                    container.mozRequestFullScreen(); // Firefox
                } else if (videoContainer.value.webkitRequestFullscreen) {
                    videoContainer.webkitRequestFullscreen(); // Chrome and Safari
                }
                isFullscreen.value = true;
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
                isFullscreen.value = false;
            }
        };

        const FullScreenWatch = () => {
            counterFullScreen.value++;
            if (counterFullScreen.value > 1) {
                isFullscreen.value = false;
                counterFullScreen.value = 0;
            }
        };

        // Перевод милисекунд в читаемое время
        const transformTime = (val) => {
            let seconds = val;
            let minutes = Math.floor(seconds / 60);
            let hours = "00";
            if (minutes > 59) {
                hours = Math.floor(minutes / 60);
                hours = hours >= 10 ? hours : "00" + hours;
                minutes = minutes - hours * 60;
                minutes = minutes >= 10 ? minutes : "00" + minutes;
            }
            seconds = Math.floor(seconds % 60);
            seconds = seconds >= 10 ? seconds : "0" + seconds;
            minutes = minutes >= 10 ? minutes : "0" + minutes;
            return minutes + ":" + seconds;
        };

        // Загрузка видео (первого кадра)
        const loadVideo = () => {
            console.log('loadVideo')
            durationVideo.value = transformTime(player.value.duration);
            getLastTimeVideo();
            emit('onHandlerPreloader')
        };

        // Обновление времени
        const timeupdateHandler = () => {
            let value =
                (100 / player.value.duration) * player.value.currentTime;
            seekbar.value = value;
            durationCurrentVideo.value = transformTime(
                player.value.currentTime
            );

            let percent = Math.floor(
                (100 / player.value.duration) * player.value.currentTime + 0.8
            );

            progressbarVal.value = percent;

            const lastTime = {
                id: props.id,
                time: player.value.currentTime,
            };

            localStorage.setItem("timeVideo", JSON.stringify(lastTime));
        };

        // Progress
        const progressChange = () => {
            let time = player.value.duration * (seekbarEl.value.value / 100);
            player.value.currentTime = time;
        };

        // Востановление времени последнего просмотра
        const getLastTimeVideo = async () => {
            const res = await localStorage.getItem("timeVideo");
            if (res !== null) {
                const dataString = JSON.parse(res);
                if (props.id == dataString.id) {
                    player.value.currentTime = dataString.time;
                    durationCurrentVideo.value = transformTime(dataString.time);
                }
            }
        };

        //Play/Pause
        const playHandler = () => {
            status.value ? player.value.pause() : player.value.play();
            status.value = !status.value;
        };

        // Конец видео
        const endVideo = async () => {
            playHandler();
            player.value.currentTime = 0;
            durationCurrentVideo.value = "00:00";
            progressbarVal.value = 0;
            const res = await localStorage.getItem("userData");
            if (res && !propsAccess.value) {

                emit('onHandlerPropgress')
				store.dispatch("UPDATE_VIDEOS", { idUser: res, idVIdeo: props.id });
            }
        };

        onMounted(async () => {
            const res = await localStorage.getItem("userData");
            if (res) {
                userId.value = Object.keys(res).length;
            }
        });

        watch(
            () => props.accessProgress,
            () => {
                propsAccess.value = props.accessProgress;
            }
        );

        return {
            player,
            durationVideo,
            loadVideo,
            timeupdateHandler,
            durationCurrentVideo,
            isFullscreen,
            fullscreenbuttonHandler,
            videoContainer,
            FullScreenWatch,
            seekbar,
            progressbarVal,
            progressChange,
            seekbarEl,
            userId,
            propsAccess,
            playHandler,
            status,
            endVideo,
        };
    },
};
</script>
<style lang="scss">

.player {
    position: relative;
    height: 100%;
    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__controls {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: white;
        font-size: 0.8em;
        width: 100%;
        min-height: 40px;
        padding: 10px 5px;
        background-color: black;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 10;
        display: flex;
        align-items: center;
    }
}

.progress-block {
    flex: 1;
    position: relative;
    margin: 0 10px;
}
</style>
