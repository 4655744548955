<template>
    <div class="exam-tab" v-if="userData">
        <div class="exam-tab__top">
            Здесь вопросы разбиты по темам ПДД. <br />
            Решайте темы целиком или частями по 20 вопросв.
        </div>
        <div class="exam-tab__row">
            <router-link class="exam-tab__btn-red"  :to="{name: 'ExamGIBDD'}">
                Экзамен ГИБДД
            </router-link>
            20 вопросов по 4 блока <br />
            в одном блоке макс. 1 ошибка <br />
            +5 вопросов за ошибку
        </div>
        <div class="exam-tab__row">
            <router-link class="exam-tab__btn" :to="{name: 'ExamMaraphon'}">
                Марафон
                <span>
                    800 вопросов подряд
                </span>
            </router-link>
            <router-link class="exam-tab__btn" :to="{name: 'ExamDifficult'}">
                Самые трудные
                <span>
                    101 самых сложных вопросов
                </span>
            </router-link>
        </div>
        <div class="exam-tab__custom">
            <div class="exam-tab__title">
                Свой экзамен
                <span>Настройки экзамена под себя</span>
            </div>
            <div class="exam-tab__list">
                <div class="exam-tab__item">
                    <div class="exam-tab__radio-list">
                        <span>Количество <br />вопросов</span>
                        <div class="exam-tab__radio">
                            20
                            <input type="radio" name="question" checked value="20" v-model="question"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            40
                            <input type="radio" name="question" value="40" v-model="question"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            60
                            <input type="radio" name="question" value="60" v-model="question"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            80
                            <input type="radio" name="question" value="80" v-model="question" />
                            <div class="exam-tab__radio-border"></div>
                        </div>
                    </div>
                </div>
                <div class="exam-tab__item">
                    <span
                        >Количество <br />
                        ошибок</span
                    >
                    <div class="exam-tab__radio">
                        1
                        <input type="radio" name="errors" checked value = "0" v-model="errors"/>
                        <div class="exam-tab__radio-border"></div>
                    </div>
                    <div class="exam-tab__radio">
                        2
                        <input type="radio" name="errors" value="1" v-model="errors"/>
                        <div class="exam-tab__radio-border"></div>
                    </div>
                    <div class="exam-tab__radio">
                        3
                        <input type="radio" name="errors" value="2" v-model="errors"/>
                        <div class="exam-tab__radio-border"></div>
                    </div>
                </div>
                <div class="exam-tab__item">
                    <div class="exam-tab__radio-list">
                        <span
                            >Время, <br />
                            минут</span
                        >
                        <div class="exam-tab__radio">
                            10
                            <input type="radio" name="timer" value="10000" checked v-model="timer"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            15
                            <input type="radio" name="timer" value="15000" v-model="timer"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            20
                            <input type="radio" name="timer" value="20000" v-model="timer"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                        <div class="exam-tab__radio">
                            30
                            <input type="radio" name="timer" value="30000" v-model="timer"/>
                            <div class="exam-tab__radio-border"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="exam-tab__bottom">
                <div class="exam-tab__bottom-left">
                    <div class="exam-tab__check">
                        Перемешать ответы
                        <input type="checkbox" v-model="mixedAnswered"/>
                        <div class="exam-tab__check-input"></div>
                    </div>
                    <div class="exam-tab__check">
                        Показывать подсказки
                        <input type="checkbox" v-model="showHelpers"/>
                        <div class="exam-tab__check-input"></div>
                    </div>
                </div>
                <div class="exam-tab__bottom-right">
                    <router-link class="exam-tab__bottom-btn"
                         :to="{
                            name: 'ExamCustom',
                            params: {
                                hh : JSON.stringify({timer,
                                question,
                                errors,
                                mixedAnswered,
                                showHelpers})
                            },
                        }">
                        Начать экзамен
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="exam-tab" v-else>
        <h4 class="exam-tab__err">
            Этот блок доступен только зарегистрированным пользователям.
        </h4>
        <span>
            <router-link
                class="exam-tab__err-link"
                to="/signin"
            >
                Войдите
            </router-link>
             или
             <router-link
                class="exam-tab__err-link"
                to="/signup"
            >
                зарегистрируйтесь
            </router-link> в системе</span>
    </div>
</template>

<style lang="scss">
@import "./styles.scss";
</style>
<script src="./index.js"></script>
