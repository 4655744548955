<template>
	<div class="tickets-tab" v-if="userData">
		<Preloader v-if="!ticketsErrorUser.length"></Preloader>
		<div class="tickets-tab__top">
			сдано билетов
			<div class="tickets-tab__count">
				{{answeredTicketsOk.length}}/{{ticketsErrorUser.length}}
			</div>
		</div>
		<ul class="tickets-tab__list">
			<li v-for="(item, index) in ticketsErrorUser" :key="index" class="tickets-tab__item" @click="startExam(item)"
			:class="[{'green star-two' : item.error == 0 && item.flag},
			{'green star-two' : item.error == 1 && item.flag},
			{'green' : item.error == 2 && item.flag},
			{'red' : item.error >= 3 && item.flag}]"
			>
				{{item.number}}
			</li>
		</ul>
	</div>
	<div class="exam-tab" v-else>
		<h4 class="exam-tab__err">
			Этот блок доступен только зарегистрированным пользователям.
		</h4>
		<span>
            <router-link
					class="exam-tab__err-link"
					to="/signin"
			>
                Войдите
            </router-link>
             или
             <router-link
					 class="exam-tab__err-link"
					 to="/signup"
			 >
                зарегистрируйтесь
            </router-link> в системе</span>

      <RegDescription />
	</div>
</template>

<style lang="scss" scoped>
	@import './styles.scss';

  .regDescription {
    margin: 0 auto;
  }
</style>
<script src="./index.js"></script>
