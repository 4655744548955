import FormAuth from '../../components/FormAuth/FormAuth.vue'
import Aside from '../../components/Aside/Aside.vue'
import Header from '../../components/Header/Header.vue'
import DownloadPage from '../../components/DownloadPage/DownloadPage.vue'
import RegDescription from '../../components/RegDescription.vue'

export default {

	name: 'SignIn',
	components: {
		FormAuth,
		Aside,
		Header,
		DownloadPage,
    RegDescription
    },

  }
